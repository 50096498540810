import React, { useState, useEffect } from 'react'
import { Box, Card, CardContent, Typography, Button, Grid, Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material'

// Mock function to simulate fetching model calls
const fetchModelCalls = async (modelName = '') => {
  // This should be replaced with actual API call
  // Use modelName to filter or fetch specific model calls
  console.log(modelName) // Example usage of modelName to avoid the error
  return [
    { id: '1', modelName: 'Model One', input: 'Input One', output: 'Output One', createdAt: '2021-09-01' },
    { id: '2', modelName: 'Model Two', input: 'Input Two', output: 'Output Two', createdAt: '2021-09-02' },
  ]
}

interface ModelCall {
  id: string
  modelName: string
  input: string
  output: string
  createdAt: string
}

const Logs = () => {
  const [modelCalls, setModelCalls] = useState<ModelCall[]>([])
  const [selectedModel, setSelectedModel] = useState('')

  useEffect(() => {
    const loadModelCalls = async () => {
      const modelCalls = await fetchModelCalls(selectedModel)
      setModelCalls(modelCalls)
    }
    loadModelCalls()
  }, [selectedModel])

  const handleModelChange = (event: SelectChangeEvent) => {
    setSelectedModel(event.target.value)
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Model Calls Monitoring
      </Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Model Name</InputLabel>
            <Select
              value={selectedModel}
              label="Model Name"
              onChange={handleModelChange}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="Model One">Model One</MenuItem>
              <MenuItem value="Model Two">Model Two</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {modelCalls.map((modelCall) => (
          <Grid item xs={12} sm={6} md={4} key={modelCall.id}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  {modelCall.modelName}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Input: {modelCall.input}
                </Typography>
                <Typography variant="body2">
                  Output: {modelCall.output}
                </Typography>
                <Typography variant="body2">
                  Created At: {modelCall.createdAt}
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Button size="small">View Details</Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Logs
