import { useState } from 'react';
import { useApi } from '../contexts/ApiContext';

interface BraveSearchParams {
  query: string;
  searchType: 'web' | 'news';
  params?: Record<string, string | number>;
}

interface UseBraveResult {
  search: (params: BraveSearchParams) => Promise<any>;
  results: any;
  isLoading: boolean;
  error: string | null;
}

export const useBrave = (): UseBraveResult => {
  const [results, setResults] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const api = useApi();

  const search = async ({ query, searchType, params = {} }: BraveSearchParams) => {
    setIsLoading(true);
    setError(null);
    setResults(null);

    try {
      const response = await api.post('/api/brave-search', { query, searchType, params });
      setResults(response);
      return response;
    } catch (err) {
      console.error('Error in useBrave:', err);
      setError('An error occurred while searching');
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  return { search, results, isLoading, error };
};