import React, { createContext, ReactNode, useContext, useCallback } from 'react'
import { createClient, SupabaseClient, Session } from '@supabase/supabase-js'
import { Database } from '../types'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || ''
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY || ''

// Create a single instance of the Supabase client
const supabaseClient = createClient<Database>(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true
  }
})

interface SupabaseContextType {
  supabase: SupabaseClient<Database>
  signIn: (email: string, password: string) => Promise<any>
  signOut: () => Promise<any>
  signUp: (email: string, password: string) => Promise<any>
  getLatestSession: () => Promise<Session | null>
}

const SupabaseContext = createContext<SupabaseContextType | undefined>(undefined)

export const SupabaseProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const signIn = async (email: string, password: string) => {
    return retryOperation(() => supabaseClient.auth.signInWithPassword({ email, password }))
  }

  const signOut = async () => {
    return retryOperation(() => supabaseClient.auth.signOut())
  }

  const signUp = async (email: string, password: string) => {
    return retryOperation(() => supabaseClient.auth.signUp({ email, password }))
  }

  const getLatestSession = useCallback(async () => {
    const { data, error } = await supabaseClient.auth.getSession();
    if (error) {
      console.error('Error getting latest session:', error);
      return null;
    }
    return data.session;
  }, []);

  return (
    <SupabaseContext.Provider value={{ supabase: supabaseClient, signIn, signOut, signUp, getLatestSession }}>
      {children}
    </SupabaseContext.Provider>
  )
}

export const useSupabase = () => {
  const context = useContext(SupabaseContext)
  if (context === undefined) {
    throw new Error('useSupabase must be used within a SupabaseProvider')
  }
  return context
}

const MAX_RETRIES = 3
const RETRY_DELAY = 1000 // 1 second

async function retryOperation<T>(operation: () => Promise<T>, retries = MAX_RETRIES): Promise<T> {
  try {
    const result = await operation()
    // Check if the result has a data property and it's an object
    if (result && typeof result === 'object' && 'data' in result) {
      // @ts-ignore
      if (result.error && result.error.message === "No API key found in request") {
        throw new Error("No API key found in request")
      }
    }
    return result
  } catch (error) {
    if (retries > 0 && (error instanceof Error && error.message === "No API key found in request" || error instanceof Error && error.name === 'AuthRetryableFetchError')) {
      console.log(`Retrying operation. Attempts left: ${retries - 1}`)
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY))
      return retryOperation(operation, retries - 1)
    }
    throw error
  }
}