import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Paper } from '@mui/material';
import { Link } from 'react-router-dom';

interface BraveSearchConfig {
  country: string;
  search_lang: string;
  ui_lang: string;
}

const BraveSearchToolSettings = () => {
  const [config, setConfig] = useState<BraveSearchConfig>({
    country: 'us',
    search_lang: 'en',
    ui_lang: 'en-US',
  });

  useEffect(() => {
    // Load config from localStorage on component mount
    const savedConfig = localStorage.getItem('braveSearchConfig');
    if (savedConfig) {
      setConfig(JSON.parse(savedConfig));
    }
  }, []);

  const saveConfig = () => {
    // Save config to localStorage
    localStorage.setItem('braveSearchConfig', JSON.stringify(config));
    alert('Configuration saved successfully!');
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>Brave Search Tool Settings</Typography>
      <Paper elevation={3} sx={{ p: 3 }}>
        <TextField
          label="Country"
          value={config.country}
          onChange={(e) => setConfig({ ...config, country: e.target.value })}
          fullWidth
          margin="normal"
          helperText="Two-letter country code (e.g., 'us')"
        />
        <TextField
          label="Search Language"
          value={config.search_lang}
          onChange={(e) => setConfig({ ...config, search_lang: e.target.value })}
          fullWidth
          margin="normal"
          helperText="Two-letter language code (e.g., 'en')"
        />
        <TextField
          label="UI Language"
          value={config.ui_lang}
          onChange={(e) => setConfig({ ...config, ui_lang: e.target.value })}
          fullWidth
          margin="normal"
          helperText="Language-country code (e.g., 'en-US')"
        />
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" onClick={saveConfig}>
            Save Configuration
          </Button>
          <Button component={Link} to="/" variant="outlined">
            Back to Tool Management
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default BraveSearchToolSettings;