import { useState, useCallback } from 'react';
import { useApi } from '../contexts/ApiContext';

interface ChatMessage {
  role: 'user' | 'assistant' | 'system';
  content: string;
}

interface UseChatReturn {
  sendMessage: (provider: string, messages: ChatMessage[], model: string, maxTokens: number) => Promise<string>;
  listModels: (provider: string) => Promise<string[]>;
  isLoading: boolean;
  error: string | null;
}

export function useChat(): UseChatReturn {
  const api = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const sendMessage = useCallback(async (provider: string, messages: ChatMessage[], model: string, maxTokens: number): Promise<string> => {
    setIsLoading(true);
    setError(null);

    console.log('Sending to API:', { provider, messages, model, maxTokens });

    try {
      const response = await api.post<{ content: string }>(`/api/${provider}/chat`, { messages, model, maxTokens });
      console.log('Received from API:', response);
      if (!response || !response.content) {
        throw new Error('Invalid response from server');
      }
      return response.content;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An error occurred';
      console.error('Error in sendMessage:', errorMessage);
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [api]);

  const listModels = useCallback(async (provider: string): Promise<string[]> => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await api.get<string[] | { models: string[] }>(`/api/${provider}/models`);
      if (Array.isArray(response)) {
        return response;
      } else if (response && Array.isArray(response.models)) {
        return response.models;
      } else if (response === null) {
        // Handle case where api.get returns null (e.g., due to network error)
        throw new Error('Failed to fetch models');
      } else {
        console.error('Unexpected response format:', response);
        throw new Error('Invalid response format');
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An error occurred';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [api]);

  return { sendMessage, listModels, isLoading, error };
}

export default useChat;