import React, { useState } from 'react';
import { TextField, Button, Box, Snackbar, LinearProgress } from '@mui/material';
import { useVoice } from '../hooks/useVoice';

export function Voice() {
  const [text, setText] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState(0);

  const { generateSpeech, isGenerating } = useVoice({
    onError: (error) => setError(error),
    onProgress: (progress) => setProgress(progress),
  });

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);
    setProgress(0);
    await generateSpeech(text);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ '& > *': { m: 1 } }}>
      <TextField
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        value={text}
        onChange={handleTextChange}
        placeholder="Enter text to convert to speech"
      />
      <Button type="submit" variant="contained" color="primary" disabled={isGenerating}>
        {isGenerating ? 'Generating...' : 'Generate Speech'}
      </Button>
      {isGenerating && (
        <LinearProgress 
          variant="determinate" 
          value={progress} 
          sx={{ mt: 2 }} 
        />
      )}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </Box>
  );
}

export default Voice;