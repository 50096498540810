import React, { FC } from 'react'
import { ThemeProvider as OriginalThemeProvider } from '@mui/material/styles'
import theme from '../theme'
import { SnackbarContextProvider } from './snackbar'
import { LoadingContextProvider } from './loading'
import { SupabaseProvider } from './supabasedb'
import { ApiProvider } from './ApiContext'
import { AuthProvider } from './AuthContext'

// we do this because of a strange typescript error whilst trying to render the theme
const ThemeProvider = OriginalThemeProvider as any

const AllContextProvider: FC<{children?: React.ReactNode}> = ({ children }) => {
  return (
    <SupabaseProvider>
      <AuthProvider>
        <ApiProvider>
          <SnackbarContextProvider>
            <LoadingContextProvider>
              <ThemeProvider theme={theme}>
                {children}
              </ThemeProvider>
            </LoadingContextProvider>
          </SnackbarContextProvider>
        </ApiProvider>
      </AuthProvider>
    </SupabaseProvider>
  )
}

export default AllContextProvider