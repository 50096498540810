import { useState, useEffect, useCallback, useRef } from 'react'
import { useSupabase } from '../contexts/supabasedb'

export const useSupabaseStorage = (bucket: string, path: string) => {
  const { supabase } = useSupabase()
  const [files, setFiles] = useState<any[]>([])
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const isMounted = useRef(true)

  const fetchFiles = useCallback(async () => {
    setIsLoading(true)
    setError(null)
    try {
      const { data, error } = await supabase.storage
        .from(bucket)
        .list(path)

      if (error) throw error

      if (isMounted.current) {
        setFiles(data || [])
      }
      return data || []
    } catch (error) {
      console.error('Error fetching files:', error)
      if (isMounted.current) {
        setError('Failed to fetch files')
      }
      return []
    } finally {
      if (isMounted.current) {
        setIsLoading(false)
      }
    }
  }, [supabase, bucket, path])

  useEffect(() => {
    fetchFiles()

    return () => {
      isMounted.current = false
    }
  }, [fetchFiles])

  const uploadFile = async (fileOrPath: File | string, customFileName?: string) => {
    try {
      let file: File;
      let fileName: string;

      if (fileOrPath instanceof File) {
        file = fileOrPath;
        fileName = customFileName || file.name;
      } else {
        throw new Error('Invalid file input');
      }

      const { data, error } = await supabase.storage
        .from(bucket)
        .upload(`${path}/${fileName}`, file)

      if (error) throw error

      console.log('File uploaded successfully:', fileName)
      await fetchFiles()
      return data
    } catch (err) {
      console.error('Error uploading file:', err)
      setError(err instanceof Error ? err.message : 'Failed to upload file')
      return null
    }
  }

  const deleteFile = async (fileName: string) => {
    try {
      const { data, error } = await supabase.storage
        .from(bucket)
        .remove([`${path}/${fileName}`])

      if (error) throw error

      console.log('File deleted successfully:', fileName)
      await fetchFiles()
      return data
    } catch (err) {
      console.error('Error deleting file:', err)
      setError(err instanceof Error ? err.message : 'Failed to delete file')
    }
  }

  return { files, fetchFiles, uploadFile, deleteFile, isLoading, error }
}