import React, { createContext, useContext, ReactNode } from 'react';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useSnackbar } from '../hooks/useSnackbar';
import { useLoading } from '../hooks/useLoading';
import { extractErrorMessage } from '../hooks/useErrorCallback';

interface ApiContextType {
  get: <T = any>(url: string, config?: AxiosRequestConfig) => Promise<T | null>;
  post: <T = any>(url: string, data?: any, config?: AxiosRequestConfig) => Promise<T | null>;
  put: <T = any>(url: string, data?: any, config?: AxiosRequestConfig) => Promise<T | null>;
  delete: <T = any>(url: string, config?: AxiosRequestConfig) => Promise<T | null>;
}

const ApiContext = createContext<ApiContextType | undefined>(undefined);

export const ApiProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const snackbar = useSnackbar();
  const loading = useLoading();

  const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3001',
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      console.log('API Request:', config.method, config.url, config.data);
      return config;
    },
    (error) => {
      console.error('API Request Error:', error);
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      console.log('API Response:', response.status, response.config.url, response.data);
      return response;
    },
    (error) => {
      console.error('API Response Error:', error.response?.status, error.response?.data, error.config?.url);
      return Promise.reject(error);
    }
  );

  const handleRequest = async <T,>(
    method: 'get' | 'post' | 'put' | 'delete',
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T | null> => {
    loading.setLoading(true);
    try {
      const response = await axiosInstance[method]<T>(url, data, config);
      return response.data;
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      console.error('API Error:', errorMessage);
      snackbar.error(errorMessage);
      return null;
    } finally {
      loading.setLoading(false);
    }
  };

  const apiContext: ApiContextType = {
    get: <T,>(url: string, config?: AxiosRequestConfig) => handleRequest<T>('get', url, undefined, config),
    post: <T,>(url: string, data?: any, config?: AxiosRequestConfig) => handleRequest<T>('post', url, data, config),
    put: <T,>(url: string, data?: any, config?: AxiosRequestConfig) => handleRequest<T>('put', url, data, config),
    delete: <T,>(url: string, config?: AxiosRequestConfig) => handleRequest<T>('delete', url, undefined, config),
  };

  return <ApiContext.Provider value={apiContext}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error('useApi must be used within an ApiProvider');
  }
  return context;
};