import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Box, Link, Button, Grid, Paper, Typography, TextField, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, Chip, OutlinedInput, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, CircularProgress } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useSupabase } from '../contexts/supabasedb'
import { useNavigate } from 'react-router-dom'
import { SelectChangeEvent } from '@mui/material/Select'
import { Theme, useTheme } from '@mui/material/styles'
import FileUpload from '../components/FileUpload'
import CancelIcon from '@mui/icons-material/Cancel'
import Badge from '@mui/material/Badge';
import FolderIcon from '@mui/icons-material/Folder'
import { useSupabaseStorage } from '../hooks/useSupabaseStorage'
import { arrayBufferToBase64, getMimeTypeFromDataUrl } from '../utils/fileUtils'
import DeleteIcon from '@mui/icons-material/Delete'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { styled } from '@mui/material/styles'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import { useGenerateEmbedding } from '../hooks/useGenerateEmbedding';
import { useSnackbar } from '../hooks/useSnackbar';
import useChat from '../hooks/useChat';

// Define interfaces for Message and Conversation objects
interface ContentBlock {
  type: string
  text?: string
}

interface Message {
  id: string
  conversation_id: string
  content: string | {
    type: string;
    text?: string;
    image?: {
      source: {
        type: string;
        media_type: string;
        data: string;
      }
    }
  }[]
  created_at: string
  role: string
  model: string
  tool_responses?: string[]
  embedding?: number[] | null
}

interface Conversation {
  id: string
  model_name: string
  created_at: string
  messages: Message[]
}

interface Tool {
  id: string
  name: string
  function_name: string
  description: string
  input_schema: any
  webhook_url: string
  is_active: boolean
  required: string[]
}

// Add these type definitions at the top of the file, after the existing imports and interfaces

interface AnthropicTextContent {
  type: 'text';
  text: string;
}

interface AnthropicFunctionCallContent {
  type: 'function_call';
  function_call: {
    name: string;
    arguments: string;
  };
}

type AnthropicContent = AnthropicTextContent | AnthropicFunctionCallContent;

interface AnthropicResponse {
  content: AnthropicContent[];
  // Add other properties from the Anthropic API response if needed
}

interface GroqChoice {
  message: {
    content: string;
    // Add other properties if needed
  };
  // Add other properties if needed
}

interface GroqResponse {
  choices: GroqChoice[];
  // Add other properties from the Groq API response if needed
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(name: string, selectedTools: readonly string[], theme: Theme) {
  return {
    fontWeight:
      selectedTools.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightBold,
  }
}

// Styled components for Markdown elements
const MarkdownBox = styled(Box)(({ theme }) => ({
  '& p': {
    marginBottom: theme.spacing(2),
  },
  '& h1, & h2, & h3, & h4, & h5, & h6': {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  '& ul, & ol': {
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  '& li': {
    marginBottom: theme.spacing(1),
  },
  '& pre': {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  '& code': {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
  },
  '& blockquote': {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    paddingLeft: theme.spacing(2),
    marginLeft: 0,
    marginBottom: theme.spacing(2),
    fontStyle: 'italic',
  },
  '& table': {
    borderCollapse: 'collapse',
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  '& th, & td': {
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
  },
  '& th': {
    backgroundColor: theme.palette.grey[100],
    fontWeight: 'bold',
  },
}))

// Add this constant at the top of the file, after the imports
const API_BASE_URL = 'https://requests.brainsteam.io';

const ConversationWithLLM = () => {
  const { supabase, getLatestSession } = useSupabase()
  const [messages, setMessages] = useState<Message[]>([])
  const [userMessage, setUserMessage] = useState('')
  const [systemMessage, setSystemMessage] = useState('You are a helpful language model called Claude.')
  const [maxTokens, setMaxTokens] = useState(1500)
  const [model, setModel] = useState('')
  const [provider, setProvider] = useState<string>(() => localStorage.getItem('lastUsedProvider') || 'groq');
  const [models, setModels] = useState<string[]>([]);
  const [isModelsFetched, setIsModelsFetched] = useState(false)
  const { id } = useParams<{ id: string }>()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const navigate = useNavigate()
  const [isNewConversation, setIsNewConversation] = useState(true)
  const [modelFetchError, setModelFetchError] = useState<string | null>(null)
  const [currentModel, setCurrentModel] = useState<string>(() => localStorage.getItem('lastUsedModel') || '');
  const [tools, setTools] = useState<Tool[]>([])
  const [selectedTools, setSelectedTools] = useState<string[]>([])
  const [useTools, setUseTools] = useState<boolean>(false)
  const theme = useTheme()
  const [isFileUploadOpen, setIsFileUploadOpen] = useState(false)
  const [contextDialogOpen, setContextDialogOpen] = useState(false)
  const [contextBucketName, setContextBucketName] = useState('')
  const [contextFilesCount, setContextFilesCount] = useState(0)
  const [selectedFile, setSelectedFile] = useState<string | null>(null)
  const [fileContent, setFileContent] = useState<string | null>(null)
  const { files: storageFiles, fetchFiles, uploadFile, deleteFile, isLoading: isLoadingFiles, error: fileError } = useSupabaseStorage('conversation-context', id || '')
  const [processedDocuments, setProcessedDocuments] = useState<any[]>([])
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([])
  const [cachedContent, setCachedContent] = useState<string>('');
  const [edgeFunctionTested, setEdgeFunctionTested] = useState(false);
  const [isLoadingModels, setIsLoadingModels] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoadingContextFiles, setIsLoadingContextFiles] = useState(true);
  const { generateEmbedding } = useGenerateEmbedding();
  const [files, setFiles] = useState<any[]>([])
  const { error: snackbarError, info, success } = useSnackbar(); // Renamed 'error' to 'snackbarError'
  const { sendMessage, listModels, isLoading: isChatLoading, error: chatError } = useChat();

  // Function to navigate back to the conversations list
  const goBackToConversations = () => {
    navigate('/conversations')
  }

  // Effect hook to fetch or create a conversation when component mounts or conversationId changes
  useEffect(() => {
    const fetchConversation = async () => {
      if (!id) {
        // Create a new conversation if no conversationId is present in URL
        const { data: newConversation, error } = await supabase
          .from('conversations')
          .insert([{ model_name: 'Claude', created_at: new Date().toISOString() }])
          .single() as { data: Conversation | null, error: any }

        if (error) {
          console.error('Error creating new conversation: ', error)
        } else if (newConversation) {
          // Redirect to the new conversation page
          window.location.href = `/conversation/${newConversation.id}`
        }
      }
    }

    fetchConversation()
  }, [id, supabase])

  // Effect hook to fetch messages for the current conversation
  useEffect(() => {
    setMessages([]) // Clear messages when conversation changes
  
    const fetchMessages = async () => {
      if (id) {
        const { data: messages, error } = await supabase
          .from('messages')
          .select('*')
          .eq('conversation_id', id)
          .order('created_at', { ascending: true })

        if (error) {
          console.error('Error fetching messages: ', error)
        } else {
          setMessages(messages || [])
          setIsNewConversation(messages?.length === 0)
          if (messages && messages.length > 0) {
            const lastMessage = messages[messages.length - 1]
            setCurrentModel(lastMessage.model)
            
            // Determine the provider based on the model
            if (lastMessage.model.startsWith('claude')) {
              setProvider('anthropic')
            } else if (lastMessage.model.startsWith('llama') || lastMessage.model.startsWith('mixtral')) {
              setProvider('groq')
            } else {
              console.warn('Unknown model provider:', lastMessage.model)
              setProvider('anthropic') // Default to Anthropic if unknown
            }
          }
        }
      }
    }
  
    fetchMessages()
  }, [id, supabase])

  // Effect hook to fetch models when currentProvider changes
  useEffect(() => {
    const fetchModels = async () => {
      if (!provider) {
        console.warn('Provider not set, skipping model fetch');
        return;
      }
      console.log(`Fetching models for ${provider}`)
      setModelFetchError(null)
      setIsModelsFetched(false)
      setModels([])
      setIsLoadingModels(true)
      
      try {
        const fetchedModels = await listModels(provider);
        console.log(`Received models for ${provider}:`, fetchedModels);
        setModels(fetchedModels);
        
        if (!fetchedModels.includes(currentModel)) {
          const newModel = fetchedModels[0] || '';
          setCurrentModel(newModel);
          localStorage.setItem('lastUsedModel', newModel);
        }
      } catch (error) {
        console.error('Error fetching models:', error);
        setModelFetchError(`Error fetching models: ${error instanceof Error ? error.message : String(error)}`);
        setModels([]);
      } finally {
        setIsLoadingModels(false);
        setIsModelsFetched(true);
      }
    }

    if (provider) {
      fetchModels();
    }
  }, [provider, currentModel, listModels]);

  // New useEffect to fetch tools
  useEffect(() => {
    const fetchTools = async () => {
      if (!id) {
        console.error('No conversation ID provided');
        return;
      }

      try {
        const { data, error } = await supabase
          .from('tools')
          .select('*')
          .eq('is_active', true)

        if (error) {
          console.error(`Error fetching tools:`, error);
        } else {
          console.log(`Received tools:`, data);
          setTools(data || []);
        }
      } catch (error) {
        console.error('Error fetching tools:', error);
      }
    }

    fetchTools()
  }, [id, supabase])

  // Update this useEffect to fetch files and set the count
  useEffect(() => {
    const fetchContextFiles = async () => {
      if (id) {
        setIsLoadingContextFiles(true)
        try {
          const fetchedFiles = await fetchFiles()
          console.log('Fetched files:', fetchedFiles)
          setContextFilesCount(fetchedFiles.length)
          console.log('Set context files count:', fetchedFiles.length)
          // Update the files state
          setFiles(fetchedFiles)
        } catch (error) {
          console.error('Error fetching context files:', error)
        } finally {
          setIsLoadingContextFiles(false)
        }
      }
    }

    fetchContextFiles()
  }, [id, fetchFiles])

  // Remove or simplify the fetchProcessedDocuments function
  const fetchProcessedDocuments = useCallback(async () => {
    // This function can be removed if you're no longer processing documents server-side
    console.log('Document processing removed')
  }, [])

  // Update the handleFileChange function
  const handleFileChange = async (event: SelectChangeEvent<string>) => {
    const fileName = event.target.value
    setSelectedFile(fileName)
    
    if (fileName) {
      try {
        const { data, error } = await supabase.storage
          .from('conversation-context')
          .download(`${id}/${fileName}`)
        
        if (error) {
          console.error('Error downloading file:', error)
          setFileContent(null)
          return
        }

        const arrayBuffer = await data.arrayBuffer()
        
        if (fileName.match(/\.(jpeg|jpg|png|gif|webp)$/i)) {
          // Handle image
          const base64 = arrayBufferToBase64(arrayBuffer)
          const fileExtension = fileName.split('.').pop()?.toLowerCase()
          const mimeType = `image/${fileExtension === 'jpg' ? 'jpeg' : fileExtension}`
          setFileContent(`data:${mimeType};base64,${base64}`)
        } else {
          // Handle other file types as plain text
          const textDecoder = new TextDecoder('utf-8')
          setFileContent(textDecoder.decode(arrayBuffer))
        }
      } catch (error) {
        console.error('Error processing file:', error)
        setFileContent(null)
      }
    } else {
      setFileContent(null)
    }
  }

  const sendMessages = async (newMessage: string, maxTokens: number, systemMessage: string) => {
    console.log("sendMessages function called")
    setIsLoading(true)
    setError(null)
    
    try {
      // Generate embedding for the new message
      const embedding = await generateEmbedding(newMessage);

      // Create a temporary user message object
      const tempUserMessage: Message = {
        id: 'temp-' + Date.now(),
        conversation_id: id || '',
        content: newMessage,
        role: 'user',
        model: currentModel,
        created_at: new Date().toISOString(),
        embedding: embedding,
      }

      // Update the local messages state immediately with the temporary user message
      setMessages(prevMessages => [...prevMessages, tempUserMessage])

      // Save the user message to the database
      const { data: savedUserMessage, error: userMessageError } = await supabase
        .from('messages')
        .insert({
          conversation_id: id,
          content: newMessage,
          role: 'user',
          model: currentModel,
          created_at: new Date().toISOString(),
          embedding: embedding,
        })
        .single()

      if (userMessageError) {
        console.error('Error saving user message:', userMessageError)
        throw new Error('Failed to save user message')
      }

      // Update the local messages state with the saved user message (replacing the temporary one)
      setMessages(prevMessages => prevMessages.map(msg => 
        msg.id === tempUserMessage.id ? savedUserMessage : msg
      ))

      // Prepare messages for the API call
      const apiMessages = messages.map(msg => ({
        role: msg.role as 'user' | 'assistant' | 'system',
        content: msg.content as string
      }));

      // Add system message if it's the first message
      if (messages.length === 0) {
        apiMessages.unshift({ role: 'system', content: systemMessage });
      }

      // Add the new user message
      apiMessages.push({ role: 'user', content: newMessage });

      // Send the message to the API
      const assistantMessage = await sendMessage(provider, apiMessages, currentModel, maxTokens);

      // Save the assistant message to the database
      const savedMessage = await saveAssistantMessage(assistantMessage);
      if (savedMessage) {
        updateMessages(savedMessage);
      } else {
        console.error('Failed to save assistant message');
        setError('Failed to save assistant message');
      }

    } catch (error) {
      console.error("Error in sendMessages:", error)
      handleError(error)
    } finally {
      console.log("sendMessages function completed")
      setIsLoading(false)
      setUserMessage('')
      setSelectedFile(null)
      setFileContent(null)
    }
  }

  const continueConversationWithToolResponse = async (toolResponse: any, messageId: string) => {
    const continuationMessage = `Tool execution result: ${JSON.stringify(toolResponse, null, 2)}`
    
    const updatedMessage = await updateAssistantMessage(messageId, [continuationMessage])
    
    if (updatedMessage) {
      setMessages(prevMessages => prevMessages.map(msg => 
        msg.id === messageId ? updatedMessage : msg
      ))
    } else {
      console.error('Unable to update assistant message with tool response')
    }
  }

  const executeToolWebhook = async (tool: Tool, args: any) => {
    try {
      const response = await fetch(tool.webhook_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*', // Add this header
        },
        body: JSON.stringify(args),
      })

      if (!response.ok) {
        throw new Error(`Webhook returned ${response.status}: ${await response.text()}`)
      }

      return await response.json()
    } catch (error) {
      console.error(`Error executing tool webhook:`, error)
      throw error
    }
  }

  // Update the updateMessages function
  const updateMessages = (message: Message | null) => {
    if (message === null) {
      console.error('Attempted to update messages with a null message');
      return;
    }
    setMessages(prevMessages => [...prevMessages.filter(msg => msg !== null), message]);
  }

  const saveAssistantMessage = async (content: string | ContentBlock[]): Promise<Message | null> => {
    if (!content) {
      console.error('Attempted to save null or empty assistant message');
      return null;
    }

    const textContent = typeof content === 'string' ? content : JSON.stringify(content);
    const embedding = await generateEmbedding(textContent);

    const { data: assistantMessageData, error: assistantError } = await supabase
      .from('messages')
      .insert([{
        conversation_id: id,
        content: textContent,
        role: 'assistant',
        model: currentModel,
        embedding: embedding,
      }])
      .select()
      .single();

    if (assistantError) {
      console.error('Error saving assistant message: ', assistantError);
      return null;
    } else if (assistantMessageData) {
      console.log('Saved assistant message:', assistantMessageData);
      return assistantMessageData;
    } else {
      console.error('Invalid response structure from database');
      return null;
    }
  }

  const updateAssistantMessage = async (messageId: string, toolResponses: string[]): Promise<Message | null> => {
    const { data: updatedMessage, error } = await supabase
      .from('messages')
      .update({ tool_responses: toolResponses })
      .eq('id', messageId)
      .select()
      .single()

    if (error) {
      console.error('Error updating assistant message with tool responses: ', error)
      return null
    }

    return updatedMessage
  }

  const handleError = (error: unknown) => {
    console.error(`Error processing ${provider} response:`, error)
    if (error instanceof Error) {
      setError(`An error occurred while processing your message: ${error.message}`)
    } else {
      setError(`An error occurred while processing your message.`)
    }
  }

  // Update the message rendering to handle both string and array content
  const MessageComponent = ({ message }: { message: Message }) => {
    const [isHovered, setIsHovered] = useState(false);

    if (!message || !message.id) {
      console.error('Attempted to render invalid message:', message);
      return null;
    }

    const content = typeof message.content === 'string' ? message.content : JSON.stringify(message.content, null, 2);
    
    const handleCopy = () => {
      navigator.clipboard.writeText(content).then(() => {
        setSnackbarOpen(true);
      }, (err) => {
        console.error('Could not copy text: ', err);
      });
    };

    return (
      <Paper 
        elevation={0} 
        sx={{ 
          p: 2, 
          mb: 1, 
          backgroundColor: message.role === 'assistant' ? '#f5f5f5' : '#ffffff',
          borderRadius: '12px',
          transition: 'background-color 0.2s',
          '&:hover': {
            backgroundColor: message.role === 'assistant' ? '#f5f5f5' : '#ffffff',
          },
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
          <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold' }}>
            {message.role === 'assistant' ? `AI (${message.model})` : 'You'}
          </Typography>
          <Box>
            {message.role === 'assistant' && (
              <Tooltip title="Copy message">
                <IconButton 
                  onClick={handleCopy}
                  size="small"
                  sx={{ 
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'action.hover',
                    },
                  }}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
        <MarkdownBox>
          <ReactMarkdown 
            remarkPlugins={[remarkGfm]}
            components={{
              p: ({children}) => <Typography variant="body2">{children}</Typography>,
              h1: ({children}) => <Typography variant="h6">{children}</Typography>,
              h2: ({children}) => <Typography variant="subtitle1">{children}</Typography>,
              h3: ({children}) => <Typography variant="subtitle2">{children}</Typography>,
              h4: ({children}) => <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{children}</Typography>,
              h5: ({children}) => <Typography variant="body2" sx={{ fontStyle: 'italic' }}>{children}</Typography>,
              h6: ({children}) => <Typography variant="body2" sx={{ fontWeight: 'bold', fontStyle: 'italic' }}>{children}</Typography>,
              a: ({href, children}) => <Link href={href} target="_blank" rel="noopener">{children}</Link>,
              img: ({src, alt}) => <Box component="img" src={src} alt={alt} sx={{ maxWidth: '100%', height: 'auto' }} />,
              pre: ({children}) => <Box component="pre" sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', fontSize: '0.875rem' }}>{children}</Box>,
              code: ({children}) => <Box component="code" sx={{ display: 'inline-block', whiteSpace: 'pre-wrap', wordBreak: 'break-word', fontFamily: 'monospace', bgcolor: 'grey.100', p: 0.5, borderRadius: 1, fontSize: '0.875rem' }}>{children}</Box>,
            }}
          >
            {content}
          </ReactMarkdown>
        </MarkdownBox>
        {message.role === 'assistant' && message.tool_responses && message.tool_responses.length > 0 && (
          <Box sx={{ mt: 1, pl: 1, borderLeft: '2px solid #ccc' }}>
            <Typography variant="caption" sx={{ mb: 0.5, fontWeight: 'bold' }}>Tool Responses:</Typography>
            {message.tool_responses.map((response, index) => (
              <Box key={index} sx={{ mb: 0.5, backgroundColor: '#e0e0e0', p: 0.5, borderRadius: 1 }}>
                <Typography variant="caption" sx={{ fontFamily: 'monospace', whiteSpace: 'pre-wrap' }}>
                  {response}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
      </Paper>
    )
  }

  // Add a function to handle multi-select changes
  const handleToolChange = (event: SelectChangeEvent<typeof selectedTools>) => {
    const {
      target: { value },
    } = event
    setSelectedTools(
      typeof value === 'string' ? value.split(',') : value,
    )
  }

  const handleDeleteTool = (toolToDelete: string) => () => {
    setSelectedTools((tools) => tools.filter((tool) => tool !== toolToDelete))
  }

  // Update the handleContextClick function
  const handleContextClick = async () => {
    if (id) {
      const session = await getLatestSession()
      if (session) {
        setContextBucketName(`conversation-context-${id}`)
        setContextDialogOpen(true)
        // Fetch files again when opening the dialog
        const fetchedFiles = await fetchFiles()
        setFiles(fetchedFiles)
        logFiles() // Log the current state of files
      } else {
        console.error('User not authenticated')
        // Show an error message to the user
      }
    } else {
      console.error('Conversation ID is not available')
    }
  }

  // Update the handleFileUploaded function
  const handleFileUploaded = async (file: File) => {
    console.log(`handleFileUploaded called with file:`, file);
    try {
      info('Uploading file...');
      const result = await uploadFile(file);
      console.log('Upload result:', result);
      
      if (result) {
        const fetchedFiles = await fetchFiles();
        console.log('Fetched files after upload:', fetchedFiles);
        setFiles(fetchedFiles);
        setContextFilesCount(fetchedFiles.length);
        success('File uploaded successfully');
      } else {
        throw new Error('File upload failed');
      }
    } catch (err) {
      console.error('Error uploading file:', err);
      snackbarError(`Error uploading file: ${err instanceof Error ? err.message : 'Unknown error'}`);
    }
  };

  // Update the logFiles function
  const logFiles = () => {
    console.log('Current files:', files)
  }

  // Update the handleDeleteFile function
  const handleDeleteFile = async (fileName: string) => {
    await deleteFile(fileName)
    const fetchedFiles = await fetchFiles()
    setContextFilesCount(fetchedFiles.length)
  }

  const handleProviderChange = (event: SelectChangeEvent) => {
    const newProvider = event.target.value;
    setProvider(newProvider);
    localStorage.setItem('lastUsedProvider', newProvider);
    setCurrentModel(''); // Reset to empty string instead of undefined
    localStorage.removeItem('lastUsedModel');
  };

  const handleModelChange = (event: SelectChangeEvent<string>) => {
    const newModel = event.target.value;
    setCurrentModel(newModel);
    localStorage.setItem('lastUsedModel', newModel);
  };

  // Render the conversation UI
  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={goBackToConversations}
            sx={{ mb: 2 }}
          >
            Back to Conversations
          </Button>
          <Badge 
            badgeContent={isLoadingContextFiles ? <CircularProgress size={14} /> : contextFilesCount} 
            color="primary" 
            max={99}
            showZero
            sx={{ '& .MuiBadge-badge': { fontSize: '1rem', height: '22px', minWidth: '22px' } }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleContextClick}
              sx={{ mb: 2 }}
              startIcon={<FolderIcon />}
            >
              Context
            </Button>
          </Badge>
        </Grid>

        {/* Messages display */}
        <Grid item xs={12}>
          <Box sx={{ maxHeight: '60vh', overflowY: 'auto', pr: 2 }}>
            {messages.filter(message => message !== null && message.id).map((message) => (
              <MessageComponent key={message.id} message={message} />
            ))}
          </Box>
        </Grid>

        {/* Input section with Provider and Model selection */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                  <InputLabel id="provider-label">Provider</InputLabel>
                  <Select
                    labelId="provider-label"
                    value={provider}
                    onChange={handleProviderChange}
                    label="Provider"
                  >
                    <MenuItem value="groq">Groq</MenuItem>
                    <MenuItem value="anthropic">Anthropic</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {isModelsFetched && (
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                    <InputLabel id="model-label">Model</InputLabel>
                    <Select
                      labelId="model-label"
                      value={currentModel}
                      onChange={handleModelChange}
                      label="Model"
                    >
                      {models.map((modelOption) => (
                        <MenuItem key={modelOption} value={modelOption}>
                          {modelOption}
                        </MenuItem>
                      ))}
                      {models.length === 0 && (
                        <MenuItem value="" disabled>
                          No models available
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
            {modelFetchError && (
              <Typography color="error" sx={{ mb: 2 }}>
                {modelFetchError}
              </Typography>
            )}
            {messages.length === 0 && (
              <>
                <TextField
                  label="System Message"
                  variant="outlined"
                  fullWidth
                  value={systemMessage}
                  onChange={(e) => setSystemMessage(e.target.value)}
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Max Tokens"
                  variant="outlined"
                  fullWidth
                  value={maxTokens}
                  onChange={(e) => setMaxTokens(Number(e.target.value))}
                  type="number"
                  sx={{ mb: 2 }}
                />
              </>
            )}
            <TextField
              label="User Message"
              variant="outlined"
              fullWidth
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              disabled={isLoading}
              sx={{ mb: 2 }}
            />
            
            {/* Tool selection */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={useTools}
                    onChange={(e) => setUseTools(e.target.checked)}
                    name="useTools"
                  />
                }
                label="Use Tools"
              />
              
              {useTools && (
                <FormControl sx={{ ml: 2, width: 300 }}>
                  <InputLabel id="tool-select-label">Tools</InputLabel>
                  <Select
                    labelId="tool-select-label"
                    id="tool-select"
                    multiple
                    value={selectedTools}
                    onChange={handleToolChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Tools" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => {
                          const tool = tools.find(t => t.function_name === value)
                          return tool ? (
                            <Chip
                              key={value}
                              label={
                                <Typography
                                  variant="body2"
                                  sx={{ fontWeight: 'bold' }}
                                >
                                  {tool.name}
                                </Typography>
                              }
                              onDelete={handleDeleteTool(value)}
                              deleteIcon={
                                <CancelIcon
                                  onMouseDown={(event) => event.stopPropagation()}
                                />
                              }
                            />
                          ) : null
                        })}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {tools.map((tool) => (
                      <MenuItem
                        key={tool.id}
                        value={tool.function_name}
                        style={getStyles(tool.function_name, selectedTools, theme)}
                      >
                        <Typography
                          sx={{
                            fontWeight: selectedTools.includes(tool.function_name) ? 'bold' : 'normal'
                          }}
                        >
                          {tool.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
            
            {/* File selection */}
            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
              <InputLabel id="file-select-label">Context File</InputLabel>
              <Select
                labelId="file-select-label"
                value={selectedFile || ''}
                onChange={handleFileChange}
                label="Context File"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {files.map((file) => (
                  <MenuItem key={file.name} value={file.name}>
                    {file.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => sendMessages(userMessage, maxTokens, systemMessage)}
              disabled={isLoading || !userMessage.trim()}
              fullWidth
            >
              {isLoading ? 'Sending...' : 'Send Message'}
            </Button>
          </Paper>
        </Grid>
        
        {error && (
          <Grid item xs={12}>
            <Typography color="error">{error}</Typography>
          </Grid>
        )}
        {chatError && (
          <Grid item xs={12}>
            <Typography color="error">{chatError}</Typography>
          </Grid>
        )}
      </Grid>

      {/* Context Dialog */}
      <Dialog open={contextDialogOpen} onClose={() => setContextDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Conversation Context</DialogTitle>
        <DialogContent>
          <FileUpload 
            bucket="conversation-context"
            folder={id || ''}
            onFileUploaded={handleFileUploaded} 
          />
          {isLoadingContextFiles && <CircularProgress />}
          {fileError && <Typography color="error">{fileError}</Typography>}
          {files.length > 0 ? (
            <Box mt={2}>
              <Typography variant="h6">Uploaded Files:</Typography>
              <List>
                {files.map((file, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={file.name} />
                    <ListItemSecondaryAction>
                      <IconButton 
                        edge="end" 
                        aria-label="delete"
                        onClick={() => handleDeleteFile(file.name)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Box>
          ) : (
            <Typography mt={2}>No files uploaded yet.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setContextDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        message="Message copied to clipboard"
      />
    </Box>
  )
}

export default ConversationWithLLM