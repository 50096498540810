import { useState, useCallback } from 'react';

interface FalAIInput {
  prompt: string;
  model: string;
  image_size: string;
  num_inference_steps: number;
  seed?: number;
  guidance_scale: number;
  num_images: number;
  enable_safety_checker: boolean;
  strength: number;
  output_format: string;
}

interface UseFalAIReturn {
  generateImage: (input: FalAIInput) => Promise<string[]>;
  isLoading: boolean;
  error: string | null;
}

export function useFalAI(): UseFalAIReturn {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const generateImage = useCallback(async (input: FalAIInput): Promise<string[]> => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/image-gen/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(input),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.images;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An error occurred';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { generateImage, isLoading, error };
}