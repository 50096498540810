import React, { useState, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { useSupabase } from '../contexts/supabasedb';
import { useAuth } from '../contexts/AuthContext';
import {
  ImageList,
  ImageListItem,
  IconButton,
  Modal,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';

interface GeneratedImage {
  id: string;
  file_name: string;
  storage_path: string;
  prompt: string;
  negative_prompt: string;
  model: string;
  width: number;
  height: number;
  created_at: string;
}

export interface ImageLibraryRef {
  fetchImages: () => void;
}

const ImageLibrary = forwardRef<ImageLibraryRef>((props, ref) => {
  const { supabase } = useSupabase();
  const { user } = useAuth();
  const [images, setImages] = useState<GeneratedImage[]>([]);
  const [imageUrls, setImageUrls] = useState<{ [key: string]: string }>({});
  const [selectedImage, setSelectedImage] = useState<GeneratedImage | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const fetchImages = useCallback(async () => {
    if (!user) return;
    setIsLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .from('generated_images')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;

      console.log('Fetched images:', data);
      setImages(data || []);
    } catch (err) {
      console.error('Error fetching images:', err);
      setError('Failed to fetch images');
    } finally {
      setIsLoading(false);
    }
  }, [user, supabase]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  const getSignedUrls = useCallback(async (imagesToFetch: GeneratedImage[]) => {
    const newUrls: { [key: string]: string } = {};
    const promises = imagesToFetch.map(async (img) => {
      if (!imageUrls[img.id]) {
        const { data, error } = await supabase.storage
          .from('generated-images')
          .createSignedUrl(img.storage_path, 3600);

        if (error) {
          console.error('Error generating signed URL:', error);
          return;
        }

        newUrls[img.id] = data.signedUrl;
      }
    });

    await Promise.all(promises);
    setImageUrls(prevUrls => ({ ...prevUrls, ...newUrls }));
  }, [supabase.storage, imageUrls]);

  useEffect(() => {
    const imagesToFetch = images.filter(img => !imageUrls[img.id]);
    if (imagesToFetch.length > 0) {
      getSignedUrls(imagesToFetch);
    }
  }, [images, getSignedUrls]);

  const deleteImage = async (id: string, storagePath: string) => {
    try {
      await supabase.storage.from('generated-images').remove([storagePath]);
      await supabase.from('generated_images').delete().match({ id });
      await fetchImages();
    } catch (err) {
      console.error('Error deleting image:', err);
      setError('Failed to delete image');
    }
  };

  const downloadImage = async (storagePath: string, fileName: string) => {
    try {
      const { data, error } = await supabase.storage
        .from('generated-images')
        .download(storagePath);

      if (error) throw error;

      const url = URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (err) {
      console.error('Error downloading image:', err);
      setError('Failed to download image');
    }
  };

  useImperativeHandle(ref, () => ({
    fetchImages
  }));

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <ImageList sx={{ width: '100%' }} cols={3} rowHeight="auto">
          {images.map((img) => (
            <ImageListItem key={img.id}>
              {imageUrls[img.id] ? (
                <img
                  src={imageUrls[img.id]}
                  alt={img.prompt}
                  loading="lazy"
                  style={{
                    width: '100%',
                    height: 'auto',
                    aspectRatio: `${img.width} / ${img.height}`,
                    objectFit: 'contain'
                  }}
                  onClick={() => {
                    setSelectedImage(img);
                    setModalOpen(true);
                  }}
                />
              ) : (
                <CircularProgress />
              )}
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <IconButton onClick={() => deleteImage(img.id, img.storage_path)}>
                  <DeleteIcon />
                </IconButton>
                <IconButton onClick={() => downloadImage(img.storage_path, img.file_name)}>
                  <DownloadIcon />
                </IconButton>
              </Box>
            </ImageListItem>
          ))}
        </ImageList>
      )}

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="image-modal"
        aria-describedby="image-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          maxWidth: '90%',
          maxHeight: '90%',
          overflow: 'auto',
        }}>
          <IconButton
            sx={{ position: 'absolute', right: 8, top: 8 }}
            onClick={() => setModalOpen(false)}
          >
            <CloseIcon />
          </IconButton>
          {selectedImage && imageUrls[selectedImage.id] && (
            <>
              <img
                src={imageUrls[selectedImage.id]}
                alt={selectedImage.prompt}
                style={{ maxWidth: '100%', maxHeight: '70vh', objectFit: 'contain' }}
              />
              <Typography variant="body2" sx={{ mt: 2 }}>
                Prompt: {selectedImage.prompt}
              </Typography>
              {selectedImage.negative_prompt && (
                <Typography variant="body2">
                  Negative Prompt: {selectedImage.negative_prompt}
                </Typography>
              )}
              <Typography variant="body2">
                Model: {selectedImage.model}
              </Typography>
              <Typography variant="body2">
                Dimensions: {selectedImage.width}x{selectedImage.height}
              </Typography>
              <Typography variant="body2">
                Created: {new Date(selectedImage.created_at).toLocaleString()}
              </Typography>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
});

export default ImageLibrary;