import React, { useState, useEffect } from 'react'
import { Box, Paper, Typography, Button, TextField, Modal, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useSupabase } from '../contexts/supabasedb'
import ConfirmationModal from '../components/ConfirmationModal'
import DeleteIcon from '@mui/icons-material/Delete'

interface Prompt {
  id: string
  prompt: string
  prompt_title: string
  created_at: string
}

const Prompts = () => {
  const [prompts, setPrompts] = useState<Prompt[]>([])
  const [openDialog, setOpenDialog] = useState(false)
  const [editingPrompt, setEditingPrompt] = useState<Prompt | null>(null)
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const [promptToDelete, setPromptToDelete] = useState<string | null>(null)
  const { supabase } = useSupabase()

  useEffect(() => {
    fetchPrompts()
  }, [])

  const fetchPrompts = async () => {
    const { data, error } = await supabase
      .from('prompts')
      .select('*')
      .order('created_at', { ascending: false })
    
    if (error) {
      console.error('Error fetching prompts:', error)
    } else {
      setPrompts(data || [])
    }
  }

  const handleOpenDialog = (prompt?: Prompt) => {
    setEditingPrompt(prompt || { id: '', prompt: '', prompt_title: '', created_at: '' })
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setEditingPrompt(null)
  }

  const handleSavePrompt = async () => {
    if (!editingPrompt) return

    if (editingPrompt.id) {
      // Update existing prompt
      const { error } = await supabase
        .from('prompts')
        .update({ prompt: editingPrompt.prompt, prompt_title: editingPrompt.prompt_title })
        .eq('id', editingPrompt.id)
      
      if (error) {
        console.error('Error updating prompt:', error)
      }
    } else {
      // Insert new prompt
      const { error } = await supabase
        .from('prompts')
        .insert({ prompt: editingPrompt.prompt, prompt_title: editingPrompt.prompt_title })
      
      if (error) {
        console.error('Error inserting prompt:', error)
      }
    }

    handleCloseDialog()
    fetchPrompts()
  }

  const handleDeletePrompt = async (id: string) => {
    setPromptToDelete(id)
    setDeleteConfirmOpen(true)
  }

  const confirmDelete = async () => {
    if (promptToDelete) {
      const { error } = await supabase
        .from('prompts')
        .delete()
        .eq('id', promptToDelete)
      
      if (error) {
        console.error('Error deleting prompt:', error)
      } else {
        fetchPrompts()
      }
    }
    setDeleteConfirmOpen(false)
    setPromptToDelete(null)
  }

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: 600,
    maxWidth: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    overflow: 'auto',
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Prompts
      </Typography>
      <Button variant="contained" onClick={() => handleOpenDialog()} sx={{ mb: 2 }}>
        Add New Prompt
      </Button>
      <Box sx={{ width: '100%' }}>
        {prompts.map((prompt) => (
          <Paper key={prompt.id} sx={{ display: 'flex', alignItems: 'center', mb: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
            <Typography variant="body1" sx={{ flexGrow: 1 }}>
              {prompt.prompt_title}
            </Typography>
            <Button variant="outlined" size="small" onClick={() => handleOpenDialog(prompt)} sx={{ mr: 1 }}>Edit</Button>
            <Button variant="outlined" size="small" onClick={() => handleDeletePrompt(prompt.id)} color="error">
              <DeleteIcon />
            </Button>
          </Paper>
        ))}
      </Box>
      <ConfirmationModal
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        onConfirm={confirmDelete}
        title="Delete Prompt"
        description="Are you sure you want to delete this prompt? This action cannot be undone."
      />
      <Modal
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
            {editingPrompt?.id ? 'Edit Prompt' : 'Add New Prompt'}
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Prompt Title"
            fullWidth
            value={editingPrompt?.prompt_title || ''}
            onChange={(e) => setEditingPrompt(prev => ({ ...prev!, prompt_title: e.target.value }))}
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            label="Prompt"
            fullWidth
            multiline
            rows={15}
            value={editingPrompt?.prompt || ''}
            onChange={(e) => setEditingPrompt(prev => ({ ...prev!, prompt: e.target.value }))}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="outlined" onClick={handleCloseDialog} sx={{ mr: 1 }}>Cancel</Button>
            <Button variant="contained" onClick={handleSavePrompt}>Save</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default Prompts