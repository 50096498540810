import { SupabaseClient } from '@supabase/supabase-js'

// Remove these lines
// const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL!
// const SUPABASE_ANON_KEY = process.env.REACT_APP_SUPABASE_ANON_KEY!
// const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY)

// Instead, declare a variable to hold the Supabase client
let supabase: SupabaseClient

// Add this function to set the Supabase client
export function setSupabaseClient(client: SupabaseClient) {
  supabase = client
}

/**
 * Converts a base64 string to an ArrayBuffer.
 * @param base64 The base64 string to convert.
 * @returns An ArrayBuffer containing the decoded data.
 */
export function base64ToArrayBuffer(base64: string): ArrayBuffer {
  console.log(`base64ToArrayBuffer: Converting base64 string to ArrayBuffer`)
  const binaryString = atob(base64)
  const bytes = new Uint8Array(binaryString.length)
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes.buffer
}

/**
 * Converts an ArrayBuffer to a base64 string.
 * @param buffer The ArrayBuffer to convert.
 * @returns A base64 encoded string.
 */
export function arrayBufferToBase64(buffer: ArrayBuffer): string {
  console.log(`arrayBufferToBase64: Converting ArrayBuffer to base64 string`)
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return btoa(binary)
}

/**
 * Extracts the MIME type from a data URL.
 * @param dataUrl The data URL to extract the MIME type from.
 * @returns The MIME type string or null if not found.
 */
export function getMimeTypeFromDataUrl(dataUrl: string): string | null {
  console.log(`getMimeTypeFromDataUrl: Extracting MIME type from data URL`)
  const match = dataUrl.match(/^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,/)
  return match ? match[1] : null
}

/**
 * Checks if a file is an image based on its MIME type.
 * @param mimeType The MIME type to check.
 * @returns True if the MIME type represents an image, false otherwise.
 */
export function isImageMimeType(mimeType: string): boolean {
  console.log(`isImageMimeType: Checking if MIME type is an image: ${mimeType}`)
  return mimeType.startsWith('image/')
}

/**
 * Resizes an image to fit within maxWidth and maxHeight while maintaining aspect ratio.
 * @param dataUrl The data URL of the image to resize.
 * @param maxWidth The maximum width of the resized image.
 * @param maxHeight The maximum height of the resized image.
 * @returns A Promise that resolves with the resized image as a data URL.
 */
export function resizeImage(dataUrl: string, maxWidth: number, maxHeight: number): Promise<string> {
  console.log(`resizeImage: Resizing image to max dimensions ${maxWidth}x${maxHeight}`)
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      const canvas = document.createElement('canvas')
      let width = img.width
      let height = img.height

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width
          width = maxWidth
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height
          height = maxHeight
        }
      }

      canvas.width = width
      canvas.height = height

      const ctx = canvas.getContext('2d')
      ctx?.drawImage(img, 0, 0, width, height)

      resolve(canvas.toDataURL())
    }
    img.onerror = reject
    img.src = dataUrl
  })
}

// Modify any functions that use supabase to check if it's initialized
export async function someFunction() {
  if (!supabase) {
    throw new Error('Supabase client is not initialized')
  }
  // Use supabase here
}