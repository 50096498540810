import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { SupabaseProvider } from './contexts/supabasedb'
import { AuthProvider } from './contexts/AuthContext'
import AllContextProvider from './contexts/all'
import Layout from './pages/Layout'

const App: React.FC = () => {
  return (
    <Router>
      <SupabaseProvider>
        <AuthProvider>
          <AllContextProvider>
            <Layout />
          </AllContextProvider>
        </AuthProvider>
      </SupabaseProvider>
    </Router>
  )
}

export default App