import { useState, useRef, useCallback } from 'react';
import { useApi } from '../contexts/ApiContext';

interface UseVoiceOptions {
  onError?: (error: string) => void;
  onProgress?: (progress: number) => void;
}

export function useVoice({ onError, onProgress }: UseVoiceOptions = {}) {
  const [isGenerating, setIsGenerating] = useState(false);
  const audioContextRef = useRef<AudioContext | null>(null);
  const audioQueueRef = useRef<AudioBuffer[]>([]);
  const isPlayingRef = useRef(false);
  const api = useApi();

  const splitTextIntoChunks = (text: string, chunkSize = 100): string[] => {
    const words = text.split(' ');
    const chunks: string[] = [];
    let currentChunk = '';

    for (const word of words) {
      if (currentChunk.length + word.length + 1 > chunkSize && currentChunk.length > 0) {
        chunks.push(currentChunk.trim());
        currentChunk = '';
      }
      currentChunk += (currentChunk ? ' ' : '') + word;
    }
    if (currentChunk) {
      chunks.push(currentChunk.trim());
    }
    return chunks;
  };

  const playNextInQueue = useCallback(() => {
    if (audioQueueRef.current.length > 0 && audioContextRef.current) {
      isPlayingRef.current = true;
      const audioBuffer = audioQueueRef.current.shift()!;
      const source = audioContextRef.current.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContextRef.current.destination);
      source.onended = () => {
        isPlayingRef.current = false;
        playNextInQueue();
      };
      source.start();
    } else {
      isPlayingRef.current = false;
    }
  }, []);

  const generateSpeech = useCallback(async (text: string) => {
    setIsGenerating(true);
    audioQueueRef.current = [];

    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
    }

    const textChunks = splitTextIntoChunks(text);
    let totalLength = 0;

    try {
      for (let i = 0; i < textChunks.length; i++) {
        const chunk = textChunks[i];
        const response = await api.post('/api/generate-speech', { text: chunk }, { responseType: 'arraybuffer' });

        if (!response) {
          throw new Error('Failed to generate speech');
        }

        const arrayBuffer = response as ArrayBuffer;
        const audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);
        audioQueueRef.current.push(audioBuffer);

        totalLength += arrayBuffer.byteLength;
        onProgress?.((i + 1) / textChunks.length * 100);

        if (i === 0 || !isPlayingRef.current) {
          playNextInQueue();
        }
      }
    } catch (error) {
      console.error('Error:', error);
      onError?.(error instanceof Error ? error.message : 'Unknown error');
    } finally {
      setIsGenerating(false);
    }
  }, [api, onError, onProgress]);

  return {
    generateSpeech,
    isGenerating,
  };
}