import React, { useState, useEffect } from 'react'
import { Box, Typography, Grid, Select, MenuItem, FormControl, InputLabel, Paper, useTheme, Chip, SelectChangeEvent, List, ListItem, ListItemText, Card, CardContent, ToggleButtonGroup, ToggleButton, Tooltip, Pagination, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, InputAdornment, OutlinedInput, Stack, Checkbox, FormControlLabel } from '@mui/material'
import ViewListIcon from '@mui/icons-material/ViewList'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import SearchIcon from '@mui/icons-material/Search'
import CheckIcon from '@mui/icons-material/Check'
import IconButton from '@mui/material/IconButton'
import { useSupabase } from '../contexts/supabasedb'
import { useAuth } from '../contexts/AuthContext'

interface Task {
  id: string
  project_id: string
  description: string
  status: string
  created_at: string
  updated_at: string
  project?: { name: string }
  name: string
  due_date: string | null
  recurring: boolean
}

interface Project {
  id: string
  name: string
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const Tasks = () => {
  const theme = useTheme()
  const [tasks, setTasks] = useState<Task[]>([])
  const [projects, setProjects] = useState<Project[]>([])
  const [selectedProjects, setSelectedProjects] = useState<string[]>([])
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>(['All Active'])
  const [sortOrder, setSortOrder] = useState<string>('created_desc')
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)
  const [openNewTaskDialog, setOpenNewTaskDialog] = useState(false)
  const [newTask, setNewTask] = useState<Partial<Task>>({
    description: '',
    status: 'Not Started',
    project_id: '',
    name: '',
    due_date: null,
    recurring: false,
  })
  const [openEditTaskDialog, setOpenEditTaskDialog] = useState(false)
  const [editingTask, setEditingTask] = useState<Task | null>(null)
  const [searchQuery, setSearchQuery] = useState('')
  const { supabase } = useSupabase()
  const { user } = useAuth()

  const statusOptions = ['All Active', 'Not Started', 'In Progress', 'Completed']
  const tasksPerPage = 10 // Adjust this value as needed

  useEffect(() => {
    fetchProjects()
  }, [])

  useEffect(() => {
    fetchTasks()
  }, [selectedProjects, selectedStatuses, sortOrder, page, searchQuery])

  const fetchTasks = async () => {
    setLoading(true)
    let query = supabase
      .from('tasks')
      .select(`
        *,
        project:projects(name)
      `, { count: 'exact' })

    if (selectedProjects.length > 0) {
      query = query.in('project_id', selectedProjects)
    }

    // Handle 'All Active' status
    if (selectedStatuses.includes('All Active')) {
      query = query.not('status', 'eq', 'Completed')
    } else if (selectedStatuses.length > 0) {
      query = query.in('status', selectedStatuses)
    }

    // Add search filter
    if (searchQuery) {
      query = query.or(`name.ilike.%${searchQuery}%,description.ilike.%${searchQuery}%`)
    }

    // Add sorting
    switch (sortOrder) {
      case 'created_desc':
        query = query.order('created_at', { ascending: false })
        break
      case 'created_asc':
        query = query.order('created_at', { ascending: true })
        break
      case 'due_desc':
        query = query.order('due_date', { ascending: false })
          .order('due_date', { ascending: false })
        break
      case 'due_asc':
        query = query.order('due_date', { ascending: true })
          .order('due_date', { ascending: true })
        break
    }

    const { data, error, count } = await query

    if (error) {
      console.error('Error fetching tasks:', error)
    } else {
      const startIndex = (page - 1) * tasksPerPage
      const endIndex = startIndex + tasksPerPage
      setTasks(data?.slice(startIndex, endIndex) || [])
      if (count) {
        setTotalPages(Math.ceil(count / tasksPerPage))
      }
    }
    setLoading(false)
  }

  const fetchProjects = async () => {
    const { data, error } = await supabase
      .from('projects')
      .select('id, name')

    if (error) {
      console.error('Error fetching projects:', error)
    } else {
      setProjects(data || [])
    }
  }

  const handleProjectChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[];
    setSelectedProjects(value);
    setPage(1);
  };

  const handleStatusChange = (event: SelectChangeEvent<typeof selectedStatuses>) => {
    const {
      target: { value },
    } = event
    setSelectedStatuses(typeof value === 'string' ? value.split(',') : value)
    setPage(1)
  }

  const handleSortChange = (event: SelectChangeEvent) => {
    setSortOrder(event.target.value as string)
    setPage(1)
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const handleOpenNewTaskDialog = () => {
    setOpenNewTaskDialog(true)
  }

  const handleCloseNewTaskDialog = () => {
    setOpenNewTaskDialog(false)
    setNewTask({
      description: '',
      status: 'Not Started',
      project_id: '',
      name: '',
      due_date: null,
      recurring: false,
    })
  }

  const handleNewTaskChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target
    setNewTask(prevTask => ({ ...prevTask, [name]: value }))
  }

  const handleNewTaskSubmit = async () => {
    if (!user) {
      console.error('No user logged in');
      return;
    }

    try {
      const { data, error } = await supabase
        .from('tasks')
        .insert([{
          ...newTask,
          user_id: user.id
        }])
        .single()

      if (error) throw error

      fetchTasks()
      handleCloseNewTaskDialog()
    } catch (error) {
      console.error('Error creating new task:', error)
      // You might want to show an error message to the user here
    }
  }

  const handleOpenEditTaskDialog = (task: Task) => {
    setEditingTask(task)
    setOpenEditTaskDialog(true)
  }

  const handleCloseEditTaskDialog = () => {
    setOpenEditTaskDialog(false)
    setEditingTask(null)
  }

  const handleEditTaskChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target
    setEditingTask(prevTask => {
      if (!prevTask) return null
      const updatedTask = { ...prevTask, [name]: value }
      return updatedTask
    })
  }

  const handleEditTaskSubmit = async () => {
    if (!editingTask) return

    try {
      const { data, error } = await supabase
        .from('tasks')
        .update({
          description: editingTask.description,
          status: editingTask.status,
          project_id: editingTask.project_id,
          name: editingTask.name,
          due_date: editingTask.due_date,
          recurring: editingTask.recurring,
        })
        .eq('id', editingTask.id)
        .single()

      if (error) throw error

      fetchTasks()
      handleCloseEditTaskDialog()
    } catch (error) {
      console.error('Error updating task:', error)
      // You might want to show an error message to the user here
    }
  }

  const handleCompleteTask = async (task: Task) => {
    if (task.status === 'Completed') {
      console.log('Task already completed')
      return
    }

    try {
      const { data, error } = await supabase
        .from('tasks')
        .update({ status: 'Completed' })
        .eq('id', task.id)
        .single()

      if (error) throw error

      if (selectedStatuses.includes('Completed')) {
        // If we're viewing completed tasks, refresh the list
        fetchTasks()
      } else {
        // Remove the completed task from the list
        setTasks(prevTasks => prevTasks.filter(t => t.id !== task.id))
      }
    } catch (error) {
      console.error('Error completing task:', error)
      // You might want to show an error message to the user here
    }
  }

  const renderTask = (task: Task) => (
    <ListItem key={task.id}>
      <ListItemText
        primary={
          <Box>
            <Typography variant="subtitle2" color="text.secondary">
              {task.project?.name || `No Project (ID: ${task.project_id})`}
            </Typography>
            <Typography variant="body1">{task.name}</Typography>
            <Typography variant="body2">{task.description}</Typography>
          </Box>
        }
        secondary={
          <Box sx={{ mt: 1 }}>
            <Box component="span" sx={{ display: 'inline-block', mr: 1 }}>
              <Chip label={task.status} size="small" />
            </Box>
            <Typography component="span" variant="body2" sx={{ mr: 1 }}>
              Created: {new Date(task.created_at).toLocaleDateString()}
            </Typography>
            {task.due_date && (
              <Typography component="span" variant="body2">
                Due: {new Date(task.due_date).toLocaleDateString()}
              </Typography>
            )}
          </Box>
        }
      />
      <Tooltip title="Edit Task">
        <IconButton
          color="primary"
          onClick={() => handleOpenEditTaskDialog(task)}
          sx={{ mr: 1 }}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      {task.status !== 'Completed' && (
        <Tooltip title="Complete Task">
          <IconButton
            color="success"
            onClick={() => handleCompleteTask(task)}
          >
            <CheckIcon />
          </IconButton>
        </Tooltip>
      )}
    </ListItem>
  )

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
    setPage(1)
  }

  // Add this function to sort projects alphabetically
  const sortedProjects = [...projects].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Tasks
      </Typography>
      <Grid container spacing={3}>
        {/* Task List */}
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
            <Box sx={{ 
              height: 'calc(100vh - 200px)', // Adjust this value as needed
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: theme.palette.background.paper,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: '4px',
              },
            }}>
              {loading ? (
                <Typography>Loading tasks...</Typography>
              ) : (
                <List>
                  {tasks.map(renderTask)}
                </List>
              )}
            </Box>
            {totalPages > 1 && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Pagination 
                  count={totalPages} 
                  page={page} 
                  onChange={handlePageChange} 
                  color="primary" 
                />
              </Box>
            )}
          </Paper>
        </Grid>

        {/* Filters and Controls */}
        <Grid item xs={12} md={4}>
          <Stack spacing={2}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Button
                fullWidth
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleOpenNewTaskDialog}
              >
                New Task
              </Button>
            </Paper>

            <Paper elevation={3} sx={{ p: 2 }}>
              <TextField
                fullWidth
                label="Search"
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>

            <Paper elevation={0} sx={{ p: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="projects-chip-label">Projects</InputLabel>
                <Select
                  labelId="projects-chip-label"
                  id="projects-chip"
                  multiple
                  value={selectedProjects}
                  onChange={handleProjectChange}
                  input={<OutlinedInput id="select-chip-project" label="Projects" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={projects.find(p => p.id === value)?.name || value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {sortedProjects.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Paper>

            <Paper elevation={0} sx={{ p: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="status-multiple-chip-label">Status</InputLabel>
                <Select
                  labelId="status-multiple-chip-label"
                  id="status-multiple-chip"
                  multiple
                  value={selectedStatuses}
                  onChange={handleStatusChange}
                  input={<OutlinedInput id="select-multiple-chip-status" label="Status" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {statusOptions.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Paper>

            <Paper elevation={3} sx={{ p: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="sort-select-label">Sort By</InputLabel>
                <Select
                  labelId="sort-select-label"
                  id="sort-select"
                  value={sortOrder}
                  label="Sort By"
                  onChange={handleSortChange}
                >
                  <MenuItem value="created_desc">Created Date (Newest First)</MenuItem>
                  <MenuItem value="created_asc">Created Date (Oldest First)</MenuItem>
                  <MenuItem value="due_desc">Due Date (Latest First)</MenuItem>
                  <MenuItem value="due_asc">Due Date (Earliest First)</MenuItem>
                </Select>
              </FormControl>
            </Paper>
          </Stack>
        </Grid>
      </Grid>

      <Dialog open={openNewTaskDialog} onClose={handleCloseNewTaskDialog}>
        <DialogTitle>Create New Task</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="name"
            label="Name"
            type="text"
            fullWidth
            value={newTask.name}
            onChange={handleNewTaskChange}
          />
          <TextField
            margin="dense"
            name="description"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={newTask.description}
            onChange={handleNewTaskChange}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="new-task-status-label">Status</InputLabel>
            <Select
              labelId="new-task-status-label"
              name="status"
              value={newTask.status}
              onChange={handleNewTaskChange}
            >
              {statusOptions.map(status => (
                <MenuItem key={status} value={status}>{status}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel id="new-task-project-label">Project</InputLabel>
            <Select
              labelId="new-task-project-label"
              name="project_id"
              value={newTask.project_id}
              onChange={handleNewTaskChange}
            >
              {sortedProjects.map(project => (
                <MenuItem key={project.id} value={project.id}>{project.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            name="due_date"
            label="Due Date"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={newTask.due_date || ''}
            onChange={handleNewTaskChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={newTask.recurring}
                onChange={(e) => handleNewTaskChange({
                  target: { name: 'recurring', value: e.target.checked }
                } as any)}
                name="recurring"
              />
            }
            label="Recurring"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewTaskDialog}>Cancel</Button>
          <Button onClick={handleNewTaskSubmit} variant="contained" color="primary">
            Create Task
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openEditTaskDialog} onClose={handleCloseEditTaskDialog}>
        <DialogTitle>Edit Task</DialogTitle>
        <DialogContent>
          {editingTask && (
            <>
              <TextField
                margin="dense"
                name="name"
                label="Name"
                type="text"
                fullWidth
                value={editingTask.name}
                onChange={handleEditTaskChange}
              />
              <TextField
                margin="dense"
                name="description"
                label="Description"
                type="text"
                fullWidth
                multiline
                rows={4}
                value={editingTask.description}
                onChange={handleEditTaskChange}
              />
              <FormControl fullWidth margin="dense">
                <InputLabel id="edit-task-status-label">Status</InputLabel>
                <Select
                  labelId="edit-task-status-label"
                  name="status"
                  value={editingTask.status}
                  onChange={handleEditTaskChange}
                >
                  {statusOptions.map(status => (
                    <MenuItem key={status} value={status}>{status}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="dense">
                <InputLabel id="edit-task-project-label">Project</InputLabel>
                <Select
                  labelId="edit-task-project-label"
                  name="project_id"
                  value={editingTask.project_id}
                  onChange={handleEditTaskChange}
                >
                  {sortedProjects.map(project => (
                    <MenuItem key={project.id} value={project.id}>{project.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                margin="dense"
                name="due_date"
                label="Due Date"
                type="date"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={editingTask.due_date || ''}
                onChange={handleEditTaskChange}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editingTask.recurring}
                    onChange={(e) => handleEditTaskChange({
                      target: { name: 'recurring', value: e.target.checked }
                    } as any)}
                    name="recurring"
                  />
                }
                label="Recurring"
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditTaskDialog}>Cancel</Button>
          <Button onClick={handleEditTaskSubmit} variant="contained" color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default Tasks