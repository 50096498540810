import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Paper, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useBrave } from '../hooks/useBrave';
import BraveSearchResults from './BraveSearchResults';

const BraveSearchTest = () => {
  const [query, setQuery] = useState('');
  const [searchType, setSearchType] = useState<'web' | 'news'>('web');
  const { search, results, isLoading, error } = useBrave();

  const handleSearch = async () => {
    try {
      const params: Record<string, string | number> = {};
      if (searchType === 'news') {
        params.count = 10;
        params.country = 'us';
        params.search_lang = 'en';
        params.text_only = 'false';
        params.format = 'json';
      }

      console.log('Sending search request:', { query, searchType, params });
      await search({ query, searchType, params });
    } catch (err) {
      console.error('Error in handleSearch:', err);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>Brave Search Test</Typography>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <TextField
          label="Search Query"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          fullWidth
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Search Type</InputLabel>
          <Select
            value={searchType}
            onChange={(e) => setSearchType(e.target.value as 'web' | 'news')}
          >
            <MenuItem value="web">Web Search</MenuItem>
            <MenuItem value="news">News Search</MenuItem>
          </Select>
        </FormControl>
        <Button 
          variant="contained" 
          onClick={handleSearch}
          disabled={isLoading || !query}
        >
          {isLoading ? 'Searching...' : 'Search'}
        </Button>
      </Paper>
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>
      )}
      {results && (
        <Paper elevation={3} sx={{ p: 3 }}>
          <BraveSearchResults results={results} searchType={searchType} />
        </Paper>
      )}
    </Box>
  );
};

export default BraveSearchTest;