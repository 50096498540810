import { useState } from 'react';
import { processDocument } from '../utils/textExtractor';
import { useSupabase } from '../contexts/supabasedb';
import { useGenerateEmbedding } from './useGenerateEmbedding';
import { Document, Element } from '../types';

export function useDocumentProcessor() {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { supabase } = useSupabase();
  const { generateEmbedding } = useGenerateEmbedding();

  async function processAndUploadDocument(file: File, userId: string): Promise<Document | null> {
    setProcessing(true);
    setError(null);

    try {
      // Extract text from the document
      const extractedText = await processDocument(file);

      // Fetch the existing document record
      const { data: documents, error: documentError } = await supabase
        .from('documents')
        .select()
        .eq('file_name', file.name)
        .eq('user_id', userId)
        .order('processed_at', { ascending: false })
        .limit(1);

      if (documentError) {
        throw new Error(`Failed to fetch document: ${documentError.message}`);
      }

      if (!documents || documents.length === 0) {
        throw new Error('Document not found');
      }

      const document = documents[0];

      // Update the document with extracted text
      const { data: updatedDocument, error: updateError } = await supabase
        .from('documents')
        .update({ 
          extracted_text: extractedText,
        })
        .eq('id', document.id)
        .select()
        .single();

      if (updateError) {
        throw new Error(`Failed to update document: ${updateError.message}`);
      }

      // Store extracted text as elements with embeddings
      const elements = extractedText.split('\n').filter(line => line.trim() !== '');
      const elementPromises = elements.map(async (content) => {
        const embedding = await generateEmbedding(content);
        return {
          document_id: document.id,
          content,
          element_type: 'text',
          embedding
        };
      });
      
      const elementsWithEmbeddings = await Promise.all(elementPromises);

      const { data: insertedElements, error: elementsError } = await supabase
        .from('elements')
        .insert(elementsWithEmbeddings)
        .select();

      if (elementsError) {
        throw new Error(`Failed to save elements: ${elementsError.message}`);
      }

      return {
        ...updatedDocument,
        elements: insertedElements as Element[]
      } as Document;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An unknown error occurred';
      setError(errorMessage);
      console.error('Error in processAndUploadDocument:', errorMessage);
      return null;
    } finally {
      setProcessing(false);
    }
  }

  return { processAndUploadDocument, processing, error };
}