import React, { useState, useEffect } from 'react'
import { Box, Card, CardContent, Typography, Button, Grid } from '@mui/material'
import EchartGraph from '../components/widgets/EchartGraph'

// Mock function to simulate fetching entities and relations
const fetchEntitiesAndRelations = async () => {
  // This should be replaced with actual API call
  return {
    entities: [
      { id: '1', name: 'Entity One', type: 'Type A', relations: ['2', '3'] },
      { id: '2', name: 'Entity Two', type: 'Type B', relations: ['1'] },
      { id: '3', name: 'Entity Three', type: 'Type C', relations: ['1'] },
    ],
    relations: [
      { id: '1', source: '1', target: '2', type: 'Relation Type 1' },
      { id: '2', source: '1', target: '3', type: 'Relation Type 2' },
    ],
  }
}

interface Entity {
  id: string;
  name: string;
  type: string;
  relations: string[];
}

interface Relation {
  id: string;
  source: string;
  target: string;
  type: string;
}

const Entities = () => {
  const [entities, setEntities] = useState<Entity[]>([])
  const [relations, setRelations] = useState<Relation[]>([])

  useEffect(() => {
    const loadData = async () => {
      const { entities, relations } = await fetchEntitiesAndRelations()
      setEntities(entities)
      setRelations(relations)
    }
    loadData()
  }, [])

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Entities and Relations Management
      </Typography>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Button variant="contained">Add Entity</Button>
        </Grid>
        {entities.map((entity) => (
          <Grid item xs={12} sm={6} md={4} key={entity.id}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  {entity.name}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Type: {entity.type}
                </Typography>
                <Typography variant="body2">
                  Relations: {entity.relations.join(', ')}
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Button size="small">View Details</Button>
                  <Button size="small">Edit</Button>
                  <Button size="small">Delete</Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Typography variant="h5" sx={{ mt: 4 }}>
        Relations
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button variant="contained">Create Relation</Button>
        </Grid>
        {relations.map((relation) => (
          <Grid item xs={12} sm={6} md={4} key={relation.id}>
            <Card>
              <CardContent>
                <Typography variant="body1">
                  Source: {relation.source} - Target: {relation.target}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Type: {relation.type}
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Button size="small">Delete</Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <EchartGraph
        data={{
            nodes: entities.map(entity => ({
            id: entity.id,
            name: entity.name,
            category: entity.type,
            symbolSize: 50, // Adjust the symbolSize as needed
            })),
            links: relations.map(relation => ({
            source: relation.source,
            target: relation.target,
            })),
            categories: Array.from(new Set(entities.map(entity => entity.type))).map(type => ({
            name: type,
            })),
        }}
        />
    </Box>
  )
}

export default Entities
