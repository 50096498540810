import React, { useState, useEffect } from 'react'
import { Box, Card, CardContent, Typography, Button, TextField, Grid, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material'
import { useSupabase } from '../contexts/supabasedb'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { useAuth } from '../contexts/AuthContext'
import { Project, Task } from '../types'
import TaskEditDialog from '../components/TaskEditDialog'

const Projects = () => {
  const [projects, setProjects] = useState<Project[]>([])
  const [filter, setFilter] = useState('')
  const [sortOrder, setSortOrder] = useState<string>('created_desc')
  const { supabase } = useSupabase()
  const [selectedProject, setSelectedProject] = useState<Project | null>(null)
  const [tasks, setTasks] = useState<Task[]>([])
  const [openDialog, setOpenDialog] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [editingProject, setEditingProject] = useState<Project | null>(null)
  const [openNewProjectDialog, setOpenNewProjectDialog] = useState(false)
  const [newProject, setNewProject] = useState<Partial<Project>>({ name: '', description: '', project_status: 'Not Started' })
  const { user } = useAuth()
  const [openTaskEditDialog, setOpenTaskEditDialog] = useState(false)
  const [editingTask, setEditingTask] = useState<Task | null>(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [projectToDelete, setProjectToDelete] = useState<Project | null>(null)
  const [taskRelocationProject, setTaskRelocationProject] = useState<string>('')

  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      fetchProjects()
    }
  }, [supabase, filter, sortOrder, user])

  const fetchProjects = async () => {
    if (!user) {
      console.error('No user logged in')
      return
    }

    let query = supabase
      .from('projects')
      .select('*')
      .eq('user_id', user.id)
      .ilike('name', `%${filter}%`)

    // Add sorting
    switch (sortOrder) {
      case 'created_desc':
        query = query.order('created_at', { ascending: false })
        break
      case 'created_asc':
        query = query.order('created_at', { ascending: true })
        break
      case 'alpha_asc':
        query = query.order('name', { ascending: true })
        break
      case 'alpha_desc':
        query = query.order('name', { ascending: false })
        break
      case 'status_asc':
        query = query.order('project_status', { ascending: true })
        break
      case 'status_desc':
        query = query.order('project_status', { ascending: false })
        break
    }

    const { data, error } = await query

    if (error) {
      console.error('Error fetching projects:', error)
    } else {
      setProjects(data || [])
    }
  }

  const handleViewDetails = async (project: Project) => {
    setSelectedProject(project)
    const { data: projectTasks, error: tasksError } = await supabase
      .from('tasks')
      .select('*')
      .eq('project_id', project.id)

    if (tasksError) {
      console.error('Error fetching tasks:', tasksError)
      return
    }

    setTasks(projectTasks || [])
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setSelectedProject(null)
    setTasks([])
  }

  const handleTaskClick = (task: Task) => {
    setEditingTask(task)
    setOpenTaskEditDialog(true)
  }

  const handleCloseTaskEditDialog = () => {
    setOpenTaskEditDialog(false)
    setEditingTask(null)
  }

  const handleTaskChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target
    setEditingTask(prev => {
      if (!prev) return null;
      let newValue = value;
      if (name === 'status' && value === 'Completed') {
        newValue = 'Complete';
      }
      return { ...prev, [name]: newValue };
    });
  }

  const handleTaskSubmit = async () => {
    if (!editingTask) return;

    try {
      const updateObject: Partial<Task> = {
        name: editingTask.name,
        description: editingTask.description,
        status: editingTask.status,
        project_id: editingTask.project_id,
        due_date: editingTask.due_date,
        recurring: editingTask.recurring,
      };

      console.log('Updating task with:', updateObject);

      const { data, error } = await supabase
        .from('tasks')
        .update(updateObject)
        .eq('id', editingTask.id)
        .single();

      if (error) throw error;

      console.log('Task updated successfully:', data);

      if (selectedProject) {
        handleViewDetails(selectedProject);
      }
      handleCloseTaskEditDialog();
    } catch (error) {
      console.error('Error updating task:', error);
      // You might want to show an error message to the user here
    }
  }

  const handleEditClick = (project: Project) => {
    setEditingProject(project)
    setOpenEditDialog(true)
  }

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false)
    setEditingProject(null)
  }

  const handleEditChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target
    setEditingProject(prev => prev ? { ...prev, [name]: value } : null)
  }

  const handleEditSubmit = async () => {
    if (!editingProject) return

    const { data, error } = await supabase
      .from('projects')
      .update({
        name: editingProject.name,
        description: editingProject.description,
        project_status: editingProject.project_status,
      })
      .eq('id', editingProject.id)

    if (error) {
      console.error('Error updating project:', error)
    } else {
      fetchProjects()
      handleCloseEditDialog()
    }
  }

  const handleSortChange = (event: SelectChangeEvent) => {
    setSortOrder(event.target.value as string)
  }

  const handleOpenNewProjectDialog = () => {
    setOpenNewProjectDialog(true)
  }

  const handleCloseNewProjectDialog = () => {
    setOpenNewProjectDialog(false)
    setNewProject({ name: '', description: '', project_status: 'Not Started' })
  }

  const handleNewProjectChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target
    setNewProject(prev => ({ ...prev, [name]: value }))
  }

  const handleNewProjectSubmit = async () => {
    if (!user) {
      console.error('No user logged in')
      return
    }

    const { data, error } = await supabase
      .from('projects')
      .insert({
        name: newProject.name,
        description: newProject.description,
        project_status: newProject.project_status,
        user_id: user.id
      })

    if (error) {
      console.error('Error creating project:', error)
    } else {
      fetchProjects()
      handleCloseNewProjectDialog()
    }
  }

  const handleDeleteClick = (project: Project) => {
    setProjectToDelete(project)
    setOpenDeleteDialog(true)
  }

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false)
    setProjectToDelete(null)
    setTaskRelocationProject('')
  }

  const handleDeleteProject = async () => {
    if (!projectToDelete) return

    try {
      // Move tasks to the selected project if one is chosen
      if (taskRelocationProject) {
        const { error: updateError } = await supabase
          .from('tasks')
          .update({ project_id: taskRelocationProject })
          .eq('project_id', projectToDelete.id)

        if (updateError) throw updateError
      }

      // Delete the project
      const { error: deleteError } = await supabase
        .from('projects')
        .delete()
        .eq('id', projectToDelete.id)

      if (deleteError) throw deleteError

      fetchProjects()
      handleCloseDeleteDialog()
    } catch (error) {
      console.error('Error deleting project:', error)
      // You might want to show an error message to the user here
    }
  }

  const projectStatusOptions = ["Not Started", "In Progress", "Completed"];

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Projects
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <TextField
          label="Filter Projects"
          variant="outlined"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          fullWidth
        />
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="sort-select-label">Sort By</InputLabel>
          <Select
            labelId="sort-select-label"
            id="sort-select"
            value={sortOrder}
            label="Sort By"
            onChange={handleSortChange}
          >
            <MenuItem value="created_desc">Newest First</MenuItem>
            <MenuItem value="created_asc">Oldest First</MenuItem>
            <MenuItem value="alpha_asc">A to Z</MenuItem>
            <MenuItem value="alpha_desc">Z to A</MenuItem>
            <MenuItem value="status_asc">Status (Ascending)</MenuItem>
            <MenuItem value="status_desc">Status (Descending)</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenNewProjectDialog}
        >
          New Project
        </Button>
      </Box>
      <Grid container spacing={3}>
        {projects.map((project) => (
          <Grid item xs={12} sm={6} md={6} key={project.id}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  {project.name}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {project.description}
                </Typography>
                <Typography variant="body2">
                  Status: {project.project_status}
                </Typography>
                <Typography variant="body2">
                  Created: {new Date(project.created_at || Date.now()).toLocaleDateString()}
                </Typography>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                  <Box>
                    <Button size="small" onClick={() => handleViewDetails(project)}>View Details</Button>
                    <Button size="small" onClick={() => handleEditClick(project)} aria-label={`Edit ${project.name}`}>Edit</Button>
                  </Box>
                  <Button
                    size="small"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDeleteClick(project)}
                    aria-label={`Delete ${project.name}`}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>{selectedProject?.name} - Tasks</DialogTitle>
        <DialogContent>
          {tasks.length === 0 ? (
            <Typography>No tasks found for this project.</Typography>
          ) : (
            <List>
              {tasks.map((task) => (
                <ListItem 
                  key={task.id} 
                  button 
                  onClick={() => handleTaskClick(task)}
                >
                  <ListItemText 
                    primary={task.name}
                    secondary={
                      <>
                        <Typography component="span" variant="body2" color="text.primary">
                          Status: {task.status}
                        </Typography>
                        <br />
                        {task.description}
                        <br />
                        Due: {task.due_date ? new Date(task.due_date).toLocaleDateString() : 'No due date'}
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit Project</DialogTitle>
        <DialogContent>
          {editingProject && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
              <TextField
                name="name"
                label="Project Name"
                value={editingProject.name}
                onChange={handleEditChange}
                fullWidth
              />
              <TextField
                name="description"
                label="Description"
                value={editingProject.description}
                onChange={handleEditChange}
                multiline
                rows={4}
                fullWidth
              />
              <FormControl fullWidth>
                <InputLabel id="edit-project-status-label">Project Status</InputLabel>
                <Select
                  labelId="edit-project-status-label"
                  name="project_status"
                  value={editingProject.project_status || 'Not Started'}
                  onChange={handleEditChange}
                  label="Project Status"
                >
                  {projectStatusOptions.map((status) => (
                    <MenuItem key={status} value={status}>{status}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Cancel</Button>
          <Button onClick={handleEditSubmit} variant="contained" color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openNewProjectDialog} onClose={handleCloseNewProjectDialog}>
        <DialogTitle>Create New Project</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
            <TextField
              name="name"
              label="Project Name"
              value={newProject.name}
              onChange={handleNewProjectChange}
              fullWidth
            />
            <TextField
              name="description"
              label="Description"
              value={newProject.description}
              onChange={handleNewProjectChange}
              multiline
              rows={4}
              fullWidth
            />
            <FormControl fullWidth>
              <InputLabel id="new-project-status-label">Project Status</InputLabel>
              <Select
                labelId="new-project-status-label"
                name="project_status"
                value={newProject.project_status || 'Not Started'}
                onChange={handleNewProjectChange}
                label="Project Status"
              >
                {projectStatusOptions.map((status) => (
                  <MenuItem key={status} value={status}>{status}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewProjectDialog}>Cancel</Button>
          <Button onClick={handleNewProjectSubmit} variant="contained" color="primary">
            Create Project
          </Button>
        </DialogActions>
      </Dialog>

      <TaskEditDialog
        open={openTaskEditDialog}
        onClose={handleCloseTaskEditDialog}
        task={editingTask}
        projects={projects}
        onTaskChange={handleTaskChange}
        onTaskSubmit={handleTaskSubmit}
      />

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Project</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Are you sure you want to delete "{projectToDelete?.name}"?
          </Typography>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="task-relocation-label">Move tasks to</InputLabel>
            <Select
              labelId="task-relocation-label"
              value={taskRelocationProject}
              onChange={(e) => setTaskRelocationProject(e.target.value)}
              label="Move tasks to"
            >
              <MenuItem value="">
                <em>Don't move tasks (delete them)</em>
              </MenuItem>
              {projects
                .filter((p) => p.id !== projectToDelete?.id)
                .map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={handleDeleteProject} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default Projects