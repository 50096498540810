import React, { useState } from 'react'
import { useSupabase } from '../../contexts/supabasedb'
import { TextField, Button, Box, Typography, Link as MuiLink } from '@mui/material'
import { useNavigate, Link } from 'react-router-dom'
import brainSteamLogo from '../../assets/img/brainsteam-logo-ver.png'

const Signup: React.FC = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { signUp } = useSupabase()
  const navigate = useNavigate()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      const { data, error } = await signUp(email, password)
      if (error) throw error
      console.log('Signed up successfully', data)
      navigate('/') // Redirect to home page after successful sign up
    } catch (error) {
      console.error('Error signing up:', error instanceof Error ? error.message : 'Unknown error')
      // Handle error (e.g., show error message to user)
    }
  }

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <img src={brainSteamLogo} alt="BrainSteam Logo" style={{ maxWidth: '400px' }} />
      <Typography variant="h5" component="h1" gutterBottom>
        Sign Up for Brainsteam
      </Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="new-password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Sign Up
      </Button>
      <Typography variant="body2">
        Already have an account?{' '}
        <MuiLink component={Link} to="/login" variant="body2">
          Log in
        </MuiLink>
      </Typography>
    </Box>
  )
}

export default Signup