import React, { useState } from 'react'
import { useSupabase } from '../../contexts/supabasedb'
import { TextField, Button, Box, Typography, Link as MuiLink } from '@mui/material'
import { useNavigate, Link } from 'react-router-dom'
import brainSteamLogo from '../../assets/img/brainsteam-logo-ver.png';

const Login: React.FC = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { signIn } = useSupabase()
  const navigate = useNavigate()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      await signIn(email, password)
      navigate('/') // Redirect to home page after successful login
    } catch (error) {
      console.error('Error signing in:', error)
    }
  }

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <img src={brainSteamLogo} alt="BrainSteam Logo" style={{ maxWidth: '400px' }} />
      <Typography variant="h5" component="h1" gutterBottom>
        {(() => {
          const currentHour = new Date().getHours()
          if (currentHour >= 5 && currentHour < 12) {
            return 'Good Morning! Welcome to Brainsteam.'
          } else if (currentHour >= 12 && currentHour < 15) {
            return 'Good Afternoon! Welcome to Brainsteam.'
          } else if (currentHour >= 15 && currentHour < 17) {
            return 'Good Day! Welcome to Brainsteam.'
          } else if (currentHour >= 17 && currentHour < 21) {
            return 'Good Evening! Welcome to Brainsteam.'
          } else {
            return 'Good Night! Welcome to Brainsteam.'
          }
        })()}
      </Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Sign In
      </Button>
      <Typography variant="body2">
        Don't have an account?{' '}
        <MuiLink component={Link} to="/signup" variant="body2">
          Sign up
        </MuiLink>
      </Typography>
    </Box>
  )
}

export default Login