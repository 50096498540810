import React from 'react'
import ReactECharts from 'echarts-for-react'

interface GraphData {
  nodes: Array<{ id: string; name: string; category: string; symbolSize?: number; label?: { show?: boolean } }>
  links: Array<{ source: string; target: string }>
  categories: Array<{ name: string }>
}

const GraphChart = ({ data }: { data: GraphData }) => {
  const { nodes, links, categories } = data

  // Update node properties based on symbolSize
  nodes.forEach(node => {
    node.label = { show: (node.symbolSize || 0) > 30 }
  })

  const option = {
    title: {
      text: 'Entities and Relationships',
      subtext: 'Default layout',
      top: 'bottom',
      left: 'right',
    },
    tooltip: {},
    legend: [
      {
        data: categories.map(category => category.name),
      },
    ],
    animationDuration: 1500,
    animationEasingUpdate: 'quinticInOut',
    series: [
      {
        name: 'Entities and Relationships',
        type: 'graph',
        layout: 'none',
        data: nodes,
        links: links,
        categories: categories,
        roam: true,
        label: {
          position: 'right',
          formatter: '{b}',
        },
        lineStyle: {
          color: 'source',
          curveness: 0.3,
        },
        emphasis: {
          focus: 'adjacency',
          lineStyle: { width: 10 },
        },
      },
    ],
  }
  // Logging the transformed nodes with their properties
  console.log({
    nodes: nodes.map(node => ({
      id: node.id,
      name: node.name,
      category: node.category,
      symbolSize: 50, // Adjust the symbolSize as needed
    })),
    links: links.map(link => ({
      source: link.source,
      target: link.target,
    })),
    categories: Array.from(new Set(nodes.map(node => node.category))).map(type => ({
      name: type,
    })),
  });

  return <ReactECharts option={option} style={{ height: 600 }} />
}

export default GraphChart