import { useState } from 'react';
import axios from 'axios';
import { useSupabase } from '../contexts/supabasedb';

interface VoyageEmbeddingResponse {
  data: Array<{
    embedding: string; // Base64 encoded string
    index: number;
  }>;
  model: string;
  usage: {
    prompt_tokens: number;
    total_tokens: number;
  };
}

export const useGenerateEmbedding = () => {
  const { supabase } = useSupabase();

  const generateEmbedding = async (input: string | string[]): Promise<number[] | null> => {
    try {
      const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
      
      if (sessionError) {
        console.error('Error getting session:', sessionError);
        return null;
      }

      const { data, error } = await supabase.functions.invoke<VoyageEmbeddingResponse>('voyage_embedding', {
        body: JSON.stringify({ input, encoding_format: 'base64' }),
        headers: {
          Authorization: `Bearer ${sessionData.session?.access_token}`
        }
      });

      if (error) {
        console.error('Error invoking voyage_embedding function:', error);
        return null;
      }

      if (!data || !data.data || data.data.length === 0) {
        console.error('Invalid response from voyage_embedding function');
        return null;
      }

      // Decode base64 string to Float32Array
      const binaryString = atob(data.data[0].embedding);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const floatArray = new Float32Array(bytes.buffer);

      // Convert Float32Array to regular array
      return Array.from(floatArray);
    } catch (error) {
      console.error('Error in generateEmbedding:', error);
      return null;
    }
  };

  return { generateEmbedding };
};