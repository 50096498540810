import React, { useEffect } from 'react'
import { useSupabase } from '../../contexts/supabasedb'
import { useNavigate } from 'react-router-dom'

const Logout: React.FC = () => {
  const { signOut } = useSupabase()
  const navigate = useNavigate()

  useEffect(() => {
    const performSignOut = async () => {
      try {
        await signOut()
        navigate('/login')
      } catch (error) {
        console.error('Error signing out:', error instanceof Error ? error.message : 'Unknown error')
      }
    }

    performSignOut()
  }, [signOut, navigate])

  return <div>Logging out...</div>
}

export default Logout