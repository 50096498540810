import * as pdfjsLib from 'pdfjs-dist';

// Set up the worker for pdf.js
if (typeof window !== 'undefined' && 'Worker' in window) {
  pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
} else {
  // Fallback for environments without Web Workers
  pdfjsLib.GlobalWorkerOptions.workerSrc = '';
}

async function extractTextFromPDF(file: File): Promise<string> {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
    let text = '';

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const content = await page.getTextContent();
      const pageText = content.items.map((item: any) => item.str).join(' ');
      text += pageText + '\n';
    }

    return text.trim();
  } catch (error) {
    console.error('Error extracting text from PDF:', error);
    throw new Error('Failed to extract text from PDF');
  }
}

export async function processDocument(file: File): Promise<string> {
  const fileType = file.type;

  try {
    switch (fileType) {
      case 'text/plain':
      case 'text/markdown':
        return await file.text();
      case 'application/pdf':
        return await extractTextFromPDF(file);
      default:
        throw new Error(`Unsupported file type: ${fileType}`);
    }
  } catch (error) {
    console.error('Error processing document:', error);
    if (error instanceof Error) {
      throw new Error(`Failed to process document: ${error.message}`);
    } else {
      throw new Error('Failed to process document: Unknown error');
    }
  }
}