import React from 'react';
import { Box, Typography, Link, Card, CardContent, Chip, CardMedia } from '@mui/material';

interface WebSearchResult {
  title: string;
  url: string;
  description: string;
  age?: string;
  extra_snippets?: string[];
}

interface NewsSearchResult {
  title: string;
  url: string;
  description: string;
  age: string;
  reading_time?: number;
  source: string;
  thumbnail?: {
    src: string;
    original: string;
  };
}

interface BraveSearchResultsProps {
  results: any;
  searchType: 'web' | 'news';
}

const BraveSearchResults: React.FC<BraveSearchResultsProps> = ({ results, searchType }) => {
  console.log('BraveSearchResults:', { results, searchType });

  if (!results) {
    return <Typography>No results data received.</Typography>;
  }

  let searchResults;
  if (searchType === 'web') {
    searchResults = results.web?.results;
  } else if (searchType === 'news') {
    searchResults = results.results; // News results are directly in the 'results' array
  }

  if (!searchResults || searchResults.length === 0) {
    return <Typography>No results found for {searchType} search.</Typography>;
  }

  const renderWebResult = (result: WebSearchResult, index: number) => (
    <Card key={index} sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" component="div">
          <Link href={result.url} target="_blank" rel="noopener noreferrer">
            {result.title}
          </Link>
        </Typography>
        <Typography color="text.secondary" gutterBottom>
          {result.url}
        </Typography>
        <Typography variant="body2" paragraph>
          {result.description}
        </Typography>
        {result.age && (
          <Chip label={`Published: ${result.age}`} size="small" sx={{ mr: 1, mb: 1 }} />
        )}
        {result.extra_snippets && result.extra_snippets.length > 0 && (
          <Box mt={1}>
            <Typography variant="subtitle2">Additional Information:</Typography>
            <ul>
              {result.extra_snippets.map((snippet, i) => (
                <li key={i}>{snippet}</li>
              ))}
            </ul>
          </Box>
        )}
      </CardContent>
    </Card>
  );

  const renderNewsResult = (result: NewsSearchResult, index: number) => (
    <Card key={index} sx={{ mb: 2, display: 'flex' }}>
      {result.thumbnail && (
        <CardMedia
          component="img"
          sx={{ width: 140, height: 140, objectFit: 'cover' }}
          image={result.thumbnail.src}
          alt={result.title}
        />
      )}
      <CardContent sx={{ flex: 1 }}>
        <Typography variant="h6" component="div">
          <Link href={result.url} target="_blank" rel="noopener noreferrer">
            {result.title}
          </Link>
        </Typography>
        <Typography color="text.secondary" gutterBottom>
          {result.source} - {result.age}
        </Typography>
        <Typography variant="body2" paragraph>
          {result.description}
        </Typography>
        {result.reading_time !== undefined && (
          <Chip label={`Reading time: ${result.reading_time} min`} size="small" sx={{ mr: 1, mb: 1 }} />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Search Results ({searchType}):</Typography>
      {searchResults.map((result: WebSearchResult | NewsSearchResult, index: number) =>
        searchType === 'web' ? renderWebResult(result as WebSearchResult, index) : renderNewsResult(result as NewsSearchResult, index)
      )}
    </Box>
  );
};

export default BraveSearchResults;