import React from 'react'
import { Box, Typography, Grid } from '@mui/material'
import UserSkills from '../components/UserSkills'

const Dashboard = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" gutterBottom>
            Welcome to your dashboard. You can add new content or widgets here.
          </Typography>
          {/* Add other dashboard content here */}
        </Grid>
      </Grid>
    </Box>
  )
}

export default Dashboard