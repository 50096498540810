import React, { useState, useEffect } from 'react';
import { useSupabase } from '../contexts/supabasedb';
import { Box, Button, TextField, Switch, FormControlLabel, Typography, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Link } from 'react-router-dom';
import { braveSearch } from '../utils/braveSearch';

interface Tool {
  id: string;
  name: string;
  description: string;
  input_schema: any;
  webhook_url: string;
  is_active: boolean;
  function_name: string;
}

const ToolManagement = () => {
  const { supabase } = useSupabase();
  const [tools, setTools] = useState<Tool[]>([]);
  const [newTool, setNewTool] = useState<Omit<Tool, 'id'>>({
    name: '',
    description: '',
    input_schema: '',
    webhook_url: '',
    is_active: true,
    function_name: '',
  });
  const [editingTool, setEditingTool] = useState<Tool | null>(null);
  const [isAddingTool, setIsAddingTool] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  useEffect(() => {
    fetchTools();
    addBraveSearchTool();
  }, []);

  const fetchTools = async () => {
    const { data, error } = await supabase.from('tools').select('*');
    if (error) console.error('Error fetching tools:', error);
    else setTools(data || []);
  };

  const addTool = async () => {
    try {
      const { data, error } = await supabase.from('tools').insert([
        {
          ...newTool,
          input_schema: JSON.parse(newTool.input_schema),
        },
      ]);
      if (error) throw error;
      fetchTools();
      setNewTool({ name: '', description: '', input_schema: '', webhook_url: '', is_active: true, function_name: '' });
      setIsAddingTool(false);
    } catch (error) {
      console.error('Error adding tool:', error);
    }
  };

  const startEditing = (tool: Tool) => {
    setEditingTool({
      ...tool,
      input_schema: JSON.stringify(tool.input_schema, null, 2),
    });
  };

  const cancelEditing = () => {
    setEditingTool(null);
  };

  const saveTool = async () => {
    if (!editingTool) return;

    try {
      const { data, error } = await supabase
        .from('tools')
        .update({
          ...editingTool,
          input_schema: JSON.parse(editingTool.input_schema),
        })
        .eq('id', editingTool.id);

      if (error) throw error;
      fetchTools();
      setEditingTool(null);
    } catch (error) {
      console.error('Error updating tool:', error);
    }
  };

  const handleCancelAddTool = () => {
    if (newTool.name || newTool.description || newTool.input_schema || newTool.webhook_url) {
      setOpenCancelDialog(true);
    } else {
      setIsAddingTool(false);
    }
  };

  const confirmCancelAddTool = () => {
    setNewTool({ name: '', description: '', input_schema: '', webhook_url: '', is_active: true, function_name: '' });
    setIsAddingTool(false);
    setOpenCancelDialog(false);
  };

  const addBraveSearchTool = async () => {
    const { data, error } = await supabase
      .from('tools')
      .select('*')
      .eq('name', 'Brave Search')
      .single();

    if (error || !data) {
      const { error: insertError } = await supabase.from('tools').insert([
        {
          name: 'Brave Search',
          description: 'Search the web using Brave Search API',
          function_name: 'brave_search',
          input_schema: JSON.stringify({
            type: 'object',
            properties: {
              query: { type: 'string' }
            },
            required: ['query']
          }),
          webhook_url: '/api/brave-search',
          is_active: true,
        },
      ]);

      if (insertError) console.error('Error adding Brave Search tool:', insertError);
      else {
        console.log('Brave Search tool added successfully');
        fetchTools();
      }
    } else {
      console.log('Brave Search tool already exists');
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>Tool Management</Typography>
      
      <Box sx={{ mb: 3 }}>
        <Button variant="contained" onClick={() => setIsAddingTool(true)} sx={{ mr: 2 }}>
          Add Tool
        </Button>
        <Button
          component={Link}
          to="/brave-search-settings"
          variant="outlined"
        >
          Brave Search Settings
        </Button>
      </Box>

      {isAddingTool && (
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>Add New Tool</Typography>
          <TextField
            label="Name"
            value={newTool.name}
            onChange={(e) => setNewTool({ ...newTool, name: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            value={newTool.description}
            onChange={(e) => setNewTool({ ...newTool, description: e.target.value })}
            fullWidth
            margin="normal"
            multiline
            rows={3}
          />
          <TextField
            label="Input Schema (JSON)"
            value={newTool.input_schema}
            onChange={(e) => setNewTool({ ...newTool, input_schema: e.target.value })}
            fullWidth
            margin="normal"
            multiline
            rows={5}
          />
          <TextField
            label="Webhook URL"
            value={newTool.webhook_url}
            onChange={(e) => setNewTool({ ...newTool, webhook_url: e.target.value })}
            fullWidth
            margin="normal"
          />
          <FormControlLabel
            control={
              <Switch
                checked={newTool.is_active}
                onChange={(e) => setNewTool({ ...newTool, is_active: e.target.checked })}
              />
            }
            label="Active"
          />
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" onClick={addTool} sx={{ mr: 1 }}>Save</Button>
            <Button variant="outlined" onClick={handleCancelAddTool}>Cancel</Button>
          </Box>
        </Paper>
      )}

      <Typography variant="h6" gutterBottom>Existing Tools</Typography>
      {tools.map((tool) => (
        <Paper key={tool.id} elevation={2} sx={{ p: 2, mb: 2 }}>
          {editingTool && editingTool.id === tool.id ? (
            <>
              <TextField
                label="Name"
                value={editingTool.name}
                onChange={(e) => setEditingTool({ ...editingTool, name: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Description"
                value={editingTool.description}
                onChange={(e) => setEditingTool({ ...editingTool, description: e.target.value })}
                fullWidth
                margin="normal"
                multiline
                rows={3}
              />
              <TextField
                label="Input Schema (JSON)"
                value={editingTool.input_schema}
                onChange={(e) => setEditingTool({ ...editingTool, input_schema: e.target.value })}
                fullWidth
                margin="normal"
                multiline
                rows={5}
              />
              <TextField
                label="Webhook URL"
                value={editingTool.webhook_url}
                onChange={(e) => setEditingTool({ ...editingTool, webhook_url: e.target.value })}
                fullWidth
                margin="normal"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={editingTool.is_active}
                    onChange={(e) => setEditingTool({ ...editingTool, is_active: e.target.checked })}
                  />
                }
                label="Active"
              />
              <Box sx={{ mt: 2 }}>
                <Button variant="contained" onClick={saveTool} sx={{ mr: 1 }}>Save</Button>
                <Button variant="outlined" onClick={cancelEditing}>Cancel</Button>
              </Box>
            </>
          ) : (
            <>
              <Typography variant="subtitle1">{tool.name}</Typography>
              <Typography variant="body2">{tool.description}</Typography>
              <Typography variant="body2">Active: {tool.is_active ? 'Yes' : 'No'}</Typography>
              <Button variant="outlined" onClick={() => startEditing(tool)} sx={{ mt: 1 }}>Edit</Button>
            </>
          )}
        </Paper>
      ))}

      <Dialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
      >
        <DialogTitle>Confirm Cancel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel? Your changes will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCancelDialog(false)}>No</Button>
          <Button onClick={confirmCancelAddTool} autoFocus>Yes</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ToolManagement;