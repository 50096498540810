import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import { useSupabase } from '../contexts/supabasedb';
import { useAuth } from '../contexts/AuthContext';

interface CreateProjectModalProps {
  open: boolean;
  onClose: () => void;
  structuredData: any;
}

// Define allowed project statuses
const ALLOWED_PROJECT_STATUSES = ['Not Started', 'In Progress', 'Completed'];

// Define allowed task statuses
const ALLOWED_TASK_STATUSES = ['Not Started', 'In Progress', 'Completed'];

const CreateProjectModal: React.FC<CreateProjectModalProps> = ({ open, onClose, structuredData }) => {
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const { supabase, getLatestSession } = useSupabase();
  const { user } = useAuth();
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const refreshSession = async () => {
      await getLatestSession();
    };
    refreshSession();
  }, [getLatestSession]);

  const handleProjectSelect = (projectName: string) => {
    setSelectedProjects(prev => 
      prev.includes(projectName) 
        ? prev.filter(name => name !== projectName)
        : [...prev, projectName]
    );
  };

  const handleCreateProjects = async () => {
    if (selectedProjects.length === 0 || !user) return;

    setIsCreating(true);
    setError(null);

    try {
      for (const projectName of selectedProjects) {
        const projectData = structuredData.projects.find((p: any) => p.name === projectName);
        if (!projectData) continue;

        // Ensure project status is valid
        const projectStatus = ALLOWED_PROJECT_STATUSES.includes(projectData.status)
          ? projectData.status
          : 'Not Started';

        // Create project
        const { data: projectResult, error: projectError } = await supabase
          .from('projects')
          .insert({
            name: projectData.name,
            description: projectData.description,
            project_status: projectStatus,
            user_id: user.id,
          })
          .select()
          .single();

        if (projectError) throw projectError;

        // Create tasks
        if (Array.isArray(projectData.tasks)) {
          for (const task of projectData.tasks) {
            const taskStatus = ALLOWED_TASK_STATUSES.includes(task.status) ? task.status : 'Not Started';
            const { error: taskError } = await supabase
              .from('tasks')
              .insert({
                name: task.name,
                description: task.description,
                status: taskStatus,
                due_date: task.due_date && task.due_date !== 'Not specified' ? task.due_date : null,
                recurring: task.recurring === 'true',
                project_id: projectResult.id,
                user_id: user.id,
              });

            if (taskError) throw taskError;
          }
        }
      }

      onClose();
    } catch (error) {
      console.error('Error creating project:', error);
      setError(`Failed to create project: ${error instanceof Error ? error.message : JSON.stringify(error)}`);
    } finally {
      setIsCreating(false);
    }
  };

  const getTotalTasks = (project: any) => {
    return Array.isArray(project.tasks) ? project.tasks.length : 0;
  };

  const projects = structuredData?.projects || [];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Create Projects from Structured Data</DialogTitle>
      <DialogContent>
        {projects.length > 0 ? (
          <>
            <Typography variant="body1" gutterBottom>
              Select projects to create:
            </Typography>
            <List>
              {projects.map((project: any) => (
                <ListItem
                  key={project.name}
                  button
                  onClick={() => handleProjectSelect(project.name)}
                >
                  <Checkbox
                    checked={selectedProjects.includes(project.name)}
                    onChange={() => handleProjectSelect(project.name)}
                  />
                  <ListItemText
                    primary={project.name}
                    secondary={
                      <Box>
                        <Typography variant="body2">{project.description}</Typography>
                        <Typography variant="body2">Status: {project.status}</Typography>
                        <Typography variant="body2">Tasks: {getTotalTasks(project)}</Typography>
                      </Box>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </>
        ) : (
          <Typography variant="body1">No projects available in the structured data.</Typography>
        )}
        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleCreateProjects}
          variant="contained"
          color="primary"
          disabled={selectedProjects.length === 0 || isCreating || projects.length === 0}
        >
          {isCreating ? <CircularProgress size={24} /> : 'Create Projects'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateProjectModal;