import React, { useState, useCallback, useRef } from 'react';
import { useSupabase } from '../contexts/supabasedb';
import { useSupabaseStorage } from '../hooks/useSupabaseStorage';
import { useAuth } from '../contexts/AuthContext';
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  Paper,
  Box,
  CircularProgress,
} from '@mui/material';
import ImageLibrary from '../components/ImageLibrary';
import { useFalAI } from '../hooks/useFalAI';

interface GeneratedImage {
  imageDataURI: string;
  width: number;
  height: number;
}

const ImageGen: React.FC = () => {
  const [prompt, setPrompt] = useState('');
  const [imageSize, setImageSize] = useState<string>('landscape_4_3');
  const [enableSafetyChecker, setEnableSafetyChecker] = useState<boolean>(true);
  const [strength, setStrength] = useState<number>(1);
  const [outputFormat, setOutputFormat] = useState<string>('jpeg');
  const [numImages, setNumImages] = useState(1);
  const [numInferenceSteps, setNumInferenceSteps] = useState(8);
  const [guidanceScale, setGuidanceScale] = useState(7);
  const [seed, setSeed] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { supabase } = useSupabase();
  const { user } = useAuth();
  const { uploadFile } = useSupabaseStorage('generated-images', '');
  const { generateImage: falGenerateImage, isLoading: isFalLoading, error: falError } = useFalAI();

  const imageSizeOptions = [
    'square_hd', 'square', 'portrait_4_3', 'portrait_16_9', 'landscape_4_3', 'landscape_16_9'
  ];

  const outputFormatOptions = ['jpeg', 'png'];

  const imageLibraryRef = useRef<{ fetchImages: () => void } | null>(null);

  const [model, setModel] = useState<string>('fal-ai/flux/schnell');

  const modelOptions = [
    'fal-ai/flux/schnell',
    'fal-ai/flux/dev',
    'fal-ai/flux-pro',
    'fal-ai/flux-realism',
  ];

  const handleGenerateImage = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const images = await falGenerateImage({
        prompt,
        model,
        image_size: imageSize,
        num_inference_steps: numInferenceSteps,
        seed: seed !== null ? seed : undefined,
        guidance_scale: guidanceScale,
        num_images: numImages,
        enable_safety_checker: enableSafetyChecker,
        strength,
        output_format: outputFormat,
      });

      // Process and save the generated images
      for (let i = 0; i < images.length; i++) {
        const imgUrl = images[i];
        const response = await fetch(imgUrl);
        const blob = await response.blob();
        const fileName = `${user?.id}/generated_image_${Date.now()}_${i}.${outputFormat}`;
        const file = new File([blob], fileName, { type: `image/${outputFormat}` });

        const uploadResult = await uploadFile(file);
        if (!uploadResult) throw new Error('Failed to upload file');

        const { error: metadataError } = await supabase
          .from('generated_images')
          .insert({
            user_id: user?.id,
            file_name: fileName,
            storage_path: uploadResult.path,
            prompt,
            model,
          });

        if (metadataError) throw metadataError;
      }

      // Refresh the ImageLibrary
      imageLibraryRef.current?.fetchImages();
    } catch (error) {
      console.error('Error generating images:', error);
      setError(error instanceof Error ? error.message : 'An unknown error occurred');
    } finally {
      setLoading(false);
    }
  }, [prompt, model, imageSize, numInferenceSteps, seed, guidanceScale, numImages, enableSafetyChecker, strength, outputFormat, falGenerateImage, supabase, uploadFile, user]);

  return (
    <>
      <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
        <Typography variant="h4" gutterBottom>
          AI Image Generation
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Prompt"
              variant="outlined"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Image Size</InputLabel>
              <Select
                value={imageSize}
                onChange={(e) => setImageSize(e.target.value as string)}
                label="Image Size"
              >
                {imageSizeOptions.map((option) => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="number"
              label="Number of Images"
              variant="outlined"
              value={numImages}
              onChange={(e) => setNumImages(Number(e.target.value))}
              inputProps={{ min: 1, max: 4 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="number"
              label="Number of Inference Steps"
              variant="outlined"
              value={numInferenceSteps}
              onChange={(e) => setNumInferenceSteps(Number(e.target.value))}
              inputProps={{ min: 1, max: 50 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="number"
              label="Guidance Scale"
              variant="outlined"
              value={guidanceScale}
              onChange={(e) => setGuidanceScale(Number(e.target.value))}
              inputProps={{ min: 1, max: 20, step: 0.1 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="number"
              label="Seed (optional)"
              variant="outlined"
              value={seed === null ? '' : seed}
              onChange={(e) => {
                const value = e.target.value;
                setSeed(value === '' ? null : Number(value));
              }}
              inputProps={{ min: 0 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="number"
              label="Strength"
              variant="outlined"
              value={strength}
              onChange={(e) => setStrength(Number(e.target.value))}
              inputProps={{ min: 0, max: 1, step: 0.1 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Output Format</InputLabel>
              <Select
                value={outputFormat}
                onChange={(e) => setOutputFormat(e.target.value as string)}
                label="Output Format"
              >
                {outputFormatOptions.map((option) => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Enable Safety Checker</InputLabel>
              <Select
                value={enableSafetyChecker ? 'true' : 'false'}
                onChange={(e) => setEnableSafetyChecker(e.target.value === 'true')}
                label="Enable Safety Checker"
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Model</InputLabel>
              <Select
                value={model}
                onChange={(e) => setModel(e.target.value as string)}
                label="Model"
              >
                {modelOptions.map((option) => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleGenerateImage}
              disabled={loading || isFalLoading}
              fullWidth
            >
              {(loading || isFalLoading) ? <CircularProgress size={24} /> : 'Generate Images'}
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          Image Library
        </Typography>
        <ImageLibrary ref={imageLibraryRef} />
      </Box>
    </>
  );
};

export default ImageGen;