import { useState, useCallback, useEffect } from 'react';
import { useSupabase } from '../contexts/supabasedb';
import { useGenerateEmbedding } from './useGenerateEmbedding';
import { ConductorLog, ConversationTopic, ConversationQuestion } from '../types';
import { v4 as uuidv4 } from 'uuid';

export function useConductor(conversationId: string) {
  const { supabase } = useSupabase();
  const { generateEmbedding } = useGenerateEmbedding();
  const [sharedContext, setSharedContext] = useState<string>('');
  const [conductorLogs, setConductorLogs] = useState<ConductorLog[]>([]);

  const fetchConductorLogs = useCallback(async () => {
    if (!conversationId) {
      console.warn('No conversation ID provided for fetching conductor logs');
      return;
    }

    try {
      const { data, error } = await supabase
        .from('conductor_logs')
        .select('*')
        .eq('conversation_id', conversationId)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setConductorLogs(data || []);
    } catch (error) {
      console.error('Error fetching conductor logs:', error);
    }
  }, [supabase, conversationId]);

  useEffect(() => {
    if (conversationId) {
      fetchConductorLogs();
    }
  }, [fetchConductorLogs, conversationId]);

  const updateSharedContext = useCallback((newContext: string | ((prevContext: string) => string)) => {
    setSharedContext(prev => {
      const updated = typeof newContext === 'function' ? newContext(prev) : newContext;
      console.log('Updated shared context:', updated);
      return updated;
    });
  }, []);

  const readMemory = useCallback(async (memoryTitle: string) => {
    const { data, error } = await supabase
      .from('memory')
      .select('*')
      .eq('memory_title', memoryTitle)
      .single();

    if (error) {
      console.error('Error reading memory:', error);
      return null;
    }

    return data;
  }, [supabase]);

  const writeMemory = useCallback(async (memoryTitle: string, memoryContent: string) => {
    const embedding = await generateEmbedding(memoryContent);

    const { data, error } = await supabase
      .from('memory')
      .insert({
        memory_title: memoryTitle,
        memory_content: memoryContent,
        embedding: embedding,
      });

    if (error) {
      console.error('Error writing memory:', error);
      return false;
    }

    return true;
  }, [supabase, generateEmbedding]);

  const logActivity = useCallback(async (content: string) => {
    try {
      const newLog = {
        id: uuidv4(),
        conversation_id: conversationId,
        content,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };

      const { data, error } = await supabase
        .from('conductor_logs')
        .insert(newLog)
        .select()
        .single();

      if (error) throw error;

      setConductorLogs(prev => [data, ...prev]);
      console.log('Conductor activity logged:', data);
    } catch (error) {
      console.error('Error logging conductor activity:', error);
    }
  }, [supabase, conversationId]);

  const updateQuestion = useCallback(async (questionId: string, answer: string, messageIds: string[]) => {
    if (!/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(questionId)) {
      console.error('Invalid question_id:', questionId);
      return;
    }

    const { error } = await supabase
      .from('gc_questions')
      .update({
        completed: true,
        answer: answer,
        messages: messageIds,
      })
      .eq('id', questionId);

    if (error) {
      console.error('Error updating question:', error);
    }
  }, [supabase]);

  const updateTopic = useCallback(async (topicIdOrTitle: string, updates: Partial<ConversationTopic>) => {
    let topicId = topicIdOrTitle;

    // Check if the input is not a valid UUID (likely a topic_title instead)
    if (!/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(topicIdOrTitle)) {
      console.log('Received topic_title instead of topic_id:', topicIdOrTitle);
      // Fetch the actual topic_id based on the topic_title
      const { data, error } = await supabase
        .from('gc_topics')
        .select('id')
        .eq('topic_title', topicIdOrTitle)
        .single();

      if (error) {
        console.error('Error finding topic by title:', error);
        return;
      }

      if (data) {
        topicId = data.id;
      } else {
        console.error('Could not find topic with title:', topicIdOrTitle);
        return;
      }
    }

    // Now we're sure topicId is a valid UUID
    const { error } = await supabase
      .from('gc_topics')
      .update(updates)
      .eq('id', topicId);

    if (error) {
      console.error('Error updating topic:', error);
    } else {
      console.log(`Successfully updated topic with ID: ${topicId}`);
    }
  }, [supabase]);

  const getTopics = useCallback(async () => {
    const { data, error } = await supabase
      .from('gc_topics')
      .select('*')
      .eq('conversation_id', conversationId)
      .order('created_at', { ascending: true });

    if (error) {
      console.error('Error fetching topics:', error);
      return [];
    }

    return data;
  }, [supabase, conversationId]);

  const getQuestions = useCallback(async (topicId: string) => {
    const { data, error } = await supabase
      .from('gc_questions')
      .select('*')
      .eq('topic_id', topicId)
      .order('created_at', { ascending: true });

    if (error) {
      console.error('Error fetching questions:', error);
      return [];
    }

    return data;
  }, [supabase]);

  const triggerFollowUpAction = useCallback(async (action: string) => {
    // Implement logic to trigger a follow-up action
    // This might involve creating a new message, updating the conversation state, etc.
    await logActivity(`Triggered follow-up action: ${action}`);
  }, [logActivity]);

  return {
    sharedContext,
    updateSharedContext,
    readMemory,
    writeMemory,
    logActivity,
    updateQuestion,
    updateTopic,
    getTopics,
    getQuestions,
    conductorLogs,
  };
};