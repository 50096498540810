import { useState, useEffect, KeyboardEvent } from 'react'
import { useSupabase } from '../contexts/supabasedb'
import { Box, Grid, Paper, Typography, TextField, InputAdornment, List, ListItem, ListItemText, Popper, IconButton, Tooltip, Button, Skeleton, Pagination } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import ConfirmationModal from '../components/ConfirmationModal'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import { debounce } from 'lodash'
import axios from 'axios'

import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'

const API_BASE_URL = process.env.REACT_APP_SUPABASE_URL


interface Conversation {
  id: string
  model_name: string
  created_at: string
  user_id: string
  messages: Message[]
}

interface Message {
  id: string
  conversation_id: string
  content: string
  created_at: string
  role: string
  embedding?: number[]
}

const Conversations = () => {
  const { supabase, getLatestSession } = useSupabase()
  const [conversations, setConversations] = useState<Conversation[]>([])
  const navigate = useNavigate()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedConversationId, setSelectedConversationId] = useState<string | null>(null)

  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState<Message[]>([])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [popperWidth, setPopperWidth] = useState(0)
  const [isLoading, setIsLoading] = useState(true)

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const itemsPerPage = 10

  useEffect(() => {
    fetchConversations()
  }, [page]) // Add page as a dependency

  const fetchConversations = async () => {
    setIsLoading(true)
    try {
      // First, get the total count of conversations
      const { count, error: countError } = await supabase
        .from('conversations')
        .select('id', { count: 'exact', head: true })

      if (countError) throw countError

      const totalCount = count || 0
      setTotalPages(Math.ceil(totalCount / itemsPerPage))

      // Then, fetch the conversations for the current page
      const { data: conversations, error } = await supabase
        .from('conversations')
        .select('*, messages(*)')
        .order('created_at', { ascending: false })
        .range((page - 1) * itemsPerPage, page * itemsPerPage - 1)
      
      if (error) throw error

      // Process conversations (renaming, embedding generation, etc.)
      for (const conversation of conversations) {
        if (conversation.model_name === 'New Conversation' && conversation.messages.length > 0) {
          const firstUserMessage = conversation.messages.find((m: Message) => m.role === 'user')
          if (firstUserMessage) {
            const newName = firstUserMessage.content.slice(0, 60) + (firstUserMessage.content.length > 60 ? '...' : '')
            await renameConversation(conversation.id, newName)
          }
        }

        for (const message of conversation.messages) {
          if (!message.embedding) {
            await generateAndSaveEmbedding(message)
          }
        }
      }

      setConversations(conversations)
    } catch (error) {
      console.error('Error fetching conversations: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteClick = (conversationId: string) => {
    setSelectedConversationId(conversationId)
    setIsModalOpen(true)
  }

  const deleteConversation = async () => {
    if (!selectedConversationId) return

    let deleteMessagesError = null;
    let deleteConversationError = null;

    // Delete messages first
    const { error: messagesError } = await supabase
      .from('messages')
      .delete()
      .match({ conversation_id: selectedConversationId });
    deleteMessagesError = messagesError;

    // If no error occurred when deleting messages, delete the conversation
    if (!deleteMessagesError) {
      const { error: conversationError } = await supabase
        .from('conversations')
        .delete()
        .match({ id: selectedConversationId });
      deleteConversationError = conversationError;
    }

    // Handle errors
    if (deleteMessagesError) {
      console.error('Error deleting messages: ', deleteMessagesError);
    } else if (deleteConversationError) {
      console.error('Error deleting conversation: ', deleteConversationError);
    } else {
      // Update the local state to reflect the changes
      setConversations(conversations.filter(conversation => conversation.id !== selectedConversationId));
      console.log('Deleted conversation and its messages');
      closeModal(); // Close the modal after successful deletion
    }
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setSelectedConversationId(null)
  }

  const createNewConversation = async () => {
    try {
      // Get the current user's ID
      const { data: { user }, error: userError } = await supabase.auth.getUser()

      if (userError) {
        console.error('Error getting user:', userError)
        return
      }

      if (!user) {
        console.error('No authenticated user found')
        return
      }

      // Insert a new conversation into the database
      const { data, error } = await supabase
        .from('conversations')
        .insert([{ 
          model_name: 'New Conversation', 
          created_at: new Date().toISOString(),
          user_id: user.id
        }])
        .select()

      if (error) {
        console.error('Error creating new conversation:', error)
      } else if (data && data.length > 0) {
        const newConversation = data[0] as Conversation
        console.log('Created new conversation:', newConversation)
        // Navigate to the new conversation's page
        navigate(`/conversation/${newConversation.id}`)
      } else {
        console.error('Insert operation did not return any data')
      }
    } catch (error) {
      console.error('Unexpected error in createNewConversation:', error)
    }
  }

  // Add this new function to rename the conversation
  const renameConversation = async (conversationId: string, newName: string) => {
    const { error } = await supabase
      .from('conversations')
      .update({ model_name: newName })
      .eq('id', conversationId)

    if (error) {
      console.error('Error renaming conversation:', error)
    } else {
      // Update the local state
      setConversations(prevConversations =>
        prevConversations.map(conv =>
          conv.id === conversationId ? { ...conv, model_name: newName } : conv
        )
      )
    }
  }

  const generateEmbedding = async (text: string): Promise<number[] | null> => {
    try {
      const session = await getLatestSession()
      const accessToken = session?.access_token

      if (!accessToken) {
        throw new Error('No valid session found')
      }

      const response = await axios.post(
        `${API_BASE_URL}/functions/v1/embed`,
        { text },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          }
        }
      )

      if (response.data && response.data.embedding) {
        return JSON.parse(response.data.embedding)
      } else {
        console.error('Unexpected response format from embed function:', response.data)
        return null
      }
    } catch (error) {
      console.error('Error generating embedding:', error)
      return null
    }
  }

  const performSimilaritySearch = async (embedding: number[]) => {
    try {
      const { data, error } = await supabase.rpc('match_messages', {
        query_embedding: embedding,
        match_threshold: 0.8, // Adjust this threshold as needed
        match_count: 4 // Adjust the number of results as needed
      })

      if (error) {
        console.error('Error in similarity search:', error)
        throw error
      }

      if (data) {
        setSearchResults(data)
      } else {
        console.warn('No results from similarity search')
        setSearchResults([])
      }
    } catch (error) {
      console.error('Error performing similarity search:', error)
      setSearchResults([])
    }
  }

  const handleSearch = debounce(async (query: string) => {
    if (query.trim() === '') {
      setSearchResults([])
      setAnchorEl(null)
      return
    }

    const embedding = await generateEmbedding(query)
    if (embedding) {
      await performSimilaritySearch(embedding)
    }
  }, 300)

  const clearSearch = () => {
    setSearchQuery('')
    setSearchResults([])
    setAnchorEl(null)
  }

  const performSearch = async (event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLDivElement>) => {
    if (searchQuery.trim() === '') {
      setSearchResults([])
      setAnchorEl(null)
      return
    }

    // Set the anchorEl to the current target of the event
    setAnchorEl(event.currentTarget)

    const embedding = await generateEmbedding(searchQuery)
    if (embedding) {
      await performSimilaritySearch(embedding)
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      performSearch(event)
    }
  }

  // Add this useEffect to update the popper width when the window resizes
  useEffect(() => {
    const updatePopperWidth = () => {
      const textField = document.querySelector('.MuiTextField-root')
      if (textField) {
        setPopperWidth(textField.clientWidth)
      }
    }

    updatePopperWidth()
    window.addEventListener('resize', updatePopperWidth)

    return () => {
      window.removeEventListener('resize', updatePopperWidth)
    }
  }, [])

  const generateAndSaveEmbedding = async (message: Message) => {
    const embedding = await generateEmbedding(message.content)
    if (embedding) {
      // Save the embedding to the database
      const { error } = await supabase
        .from('messages')
        .update({ embedding: embedding })
        .eq('id', message.id)

      if (error) {
        console.error('Error saving embedding:', error)
      } else {
        console.log('Embedding generated and saved for message:', message.id)
      }
    }
  }

  const ConversationSkeleton = () => (
    <Grid item xs={12}>
      <Paper elevation={3} sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ flex: 1 }}>
          <Skeleton variant="text" width="60%" height={32} /> {/* For title */}
          <Skeleton variant="text" width="40%" height={20} /> {/* For date */}
        </div>
        <div>
          <Skeleton variant="rectangular" width={64} height={36} sx={{ mr: 1, display: 'inline-block' }} /> {/* For Open button */}
          <Skeleton variant="circular" width={40} height={40} sx={{ display: 'inline-block' }} /> {/* For Delete icon */}
        </div>
      </Paper>
    </Grid>
  )

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Conversations
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search conversations..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyPress={handleKeyPress}
          sx={{ flexGrow: 1, mr: 2 }}
          className="search-text-field"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="perform search"
                  onClick={performSearch}
                  edge="end"
                >
                  <SearchIcon />
                </IconButton>
                {searchQuery && (
                  <IconButton
                    aria-label="clear search"
                    onClick={clearSearch}
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        <Button 
          variant="contained" 
          color="primary" 
          onClick={createNewConversation}
          startIcon={<AddIcon />}
          sx={{ minWidth: '120px' }}
        >
          New
        </Button>
      </Box>

      <Popper 
        open={searchResults.length > 0 && Boolean(anchorEl)} 
        anchorEl={anchorEl} 
        placement="bottom-start" 
        style={{ width: popperWidth, zIndex: 1300 }}
      >
        <Paper elevation={3} sx={{ mt: 1, maxHeight: '50vh', overflowY: 'auto', width: '100%' }}>
          <List>
            {searchResults.map((message) => (
              <ListItem 
                key={message.id} 
                button 
                component={Link} 
                to={`/conversation/${message.conversation_id}`}
                onClick={() => setAnchorEl(null)}  // Close Popper when an item is selected
              >
                <ListItemText
                  primary={`${message.role}: ${typeof message.content === 'string' ? message.content.substring(0, 100) + '...' : 'Complex content'}`}
                  secondary={`Conversation: ${message.conversation_id}`}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Popper>

      <Grid container spacing={3}>
        {isLoading ? (
          // Show skeletons while loading
          Array.from(new Array(itemsPerPage)).map((_, index) => (
            <ConversationSkeleton key={index} />
          ))
        ) : (
          // Show actual conversations when loaded
          conversations.map((conversation) => (
            <Grid item xs={12} key={conversation.id}>
              <Paper 
                elevation={3} 
                sx={{ 
                  p: 2, 
                  display: 'flex', 
                  justifyContent: 'space-between',
                  alignItems: 'center' 
                }}
              >
                <div style={{ flex: 1 }}>
                  <Link to={`/conversation/${conversation.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography variant="h6">{conversation.model_name}</Typography>
                    <Typography variant="body2">Created at: {conversation.created_at}</Typography>
                  </Link>
                </div>
                <div>
                  <Button 
                    variant="outlined" 
                    color="primary" 
                    onClick={() => navigate(`/conversation/${conversation.id}`)}
                    style={{ marginRight: '8px' }}
                  >
                    Open
                  </Button>
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteClick(conversation.id)}
                    aria-label="delete conversation"
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </Paper>
            </Grid>
          ))
        )}
      </Grid>

      {/* Pagination controls */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Pagination 
          count={totalPages} 
          page={page} 
          onChange={handlePageChange}
          color="primary"
        />
      </Box>

      {/* Confirmation Modal */}
      <ConfirmationModal
        open={isModalOpen}
        onClose={closeModal}
        onConfirm={deleteConversation}
        title="Confirm Deletion"
        description="Are you sure you want to delete this conversation and all its messages?"
      />
    </Box>
  )
}

export default Conversations