import React, { useState } from 'react'
import { Button, CircularProgress } from '@mui/material'
import { useSupabase } from '../contexts/supabasedb'

interface FileUploadProps {
  bucket: string
  folder: string
  onFileUploaded: (file: File) => void
}

const FileUpload: React.FC<FileUploadProps> = ({ bucket, folder, onFileUploaded }) => {
  const { supabase } = useSupabase()
  const [isUploading, setIsUploading] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [uploadError, setUploadError] = useState<string | null>(null)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
      console.log('File selected:', event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      console.log('No file selected');
      return;
    }

    try {
      setIsUploading(true)
      console.log('Uploading file:', selectedFile);
      onFileUploaded(selectedFile)
      setSelectedFile(null)
    } catch (error) {
      console.error('Error uploading file:', error)
      setUploadError('Failed to upload file')
    } finally {
      setIsUploading(false)
    }
  }

  return (
    <div>
      <input
        accept="*/*"
        style={{ display: 'none' }}
        id="raised-button-file"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="raised-button-file">
        <Button variant="contained" component="span" disabled={isUploading}>
          Select File
        </Button>
      </label>
      {selectedFile && (
        <Button 
          onClick={handleUpload} 
          disabled={isUploading} 
          variant="contained" 
          color="primary" 
          style={{ marginLeft: '10px' }}
        >
          {isUploading ? <CircularProgress size={24} /> : 'Upload'}
        </Button>
      )}
      {uploadError && <div style={{ color: 'red' }}>{uploadError}</div>}
    </div>
  )
}

export default FileUpload