import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { Task, Project } from '../types';
import { Database } from '../../database.types';

interface TaskEditDialogProps {
  open: boolean;
  onClose: () => void;
  task: Task | null;
  projects: Project[];
  onTaskChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => void;
  onTaskSubmit: () => Promise<void>;
}

const TaskEditDialog: React.FC<TaskEditDialogProps> = ({
  open,
  onClose,
  task,
  projects,
  onTaskChange,
  onTaskSubmit
}) => {
  if (!task) return null;

  const statusOptions: Database['public']['Enums']['task_status'][] = ['Not Started', 'In Progress', 'Completed'];

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Task</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          name="name"
          label="Task Name"
          type="text"
          fullWidth
          value={task?.name || ''}
          onChange={onTaskChange}
        />
        <TextField
          margin="dense"
          name="description"
          label="Description"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={task.description || ''}
          onChange={onTaskChange}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="edit-task-status-label">Status</InputLabel>
          <Select
            labelId="edit-task-status-label"
            name="status"
            value={task.status || ''}
            onChange={onTaskChange}
          >
            {statusOptions.map((status) => (
              <MenuItem key={status} value={status}>{status}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="dense">
          <InputLabel id="edit-task-project-label">Project</InputLabel>
          <Select
            labelId="edit-task-project-label"
            name="project_id"
            value={task.project_id || ''}
            onChange={onTaskChange}
          >
            {projects.map(project => (
              <MenuItem key={project.id} value={project.id}>{project.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          name="due_date"
          label="Due Date"
          type="date"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={task.due_date || ''}
          onChange={onTaskChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onTaskSubmit} variant="contained" color="primary">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TaskEditDialog;