import React, { useState, useEffect } from 'react'
import { Typography, Box, List, ListItem, ListItemText, CircularProgress, Alert, TextField, Button, Paper, Grid, IconButton } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import FileUpload from '../components/FileUpload'
import { useSupabase } from '../contexts/supabasedb'
import { useAuth } from '../contexts/AuthContext'
import { useDocumentProcessor } from '../hooks/useDocumentProcessor'
import { useVoyageAI } from '../hooks/useVoyage'
import { useSupabaseStorage } from '../hooks/useSupabaseStorage'
import { debounce } from 'lodash'

interface SearchResult {
  id: string;
  content: string;
  element_type: string;
  similarity?: number;
}

const FileUploadPage: React.FC = () => {
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([])
  const [uploadError, setUploadError] = useState<string | null>(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState<SearchResult[]>([])
  const [searching, setSearching] = useState(false)
  const [searchError, setSearchError] = useState<string | null>(null)
  const [textSearchResults, setTextSearchResults] = useState<SearchResult[]>([])
  const [embeddingSearchResults, setEmbeddingSearchResults] = useState<SearchResult[]>([])
  const [processing, setProcessing] = useState(false)

  const { supabase, getLatestSession } = useSupabase()
  const { user } = useAuth()
  const { processAndUploadDocument, processing: documentProcessing, error: processingError } = useDocumentProcessor()
  const { generateEmbedding, loading: embeddingLoading, error: embeddingError } = useVoyageAI()
  const { uploadFile } = useSupabaseStorage('documents', 'uploads')

  useEffect(() => {
    const checkAuth = async () => {
      const session = await getLatestSession()
      if (!session) {
        console.error('User not authenticated')
        // You might want to use a router to redirect:
        // navigate('/login')
      }
    }
    checkAuth()
  }, [getLatestSession])

  const handleFileUploaded = async (file: File) => {
    try {
      setProcessing(true);
      setUploadError(null);

      // Upload the file
      const uploadedFile = await uploadFile(file);
      
      if (!uploadedFile) {
        throw new Error('Failed to upload file');
      }

      // Process the uploaded document
      // Pass the original File object instead of the path
      await processAndUploadDocument(file, file.name);

      // Update the list of uploaded files
      setUploadedFiles(prevFiles => [...prevFiles, file.name]);

      setProcessing(false);
    } catch (error) {
      console.error('Error processing file:', error);
      setUploadError(error instanceof Error ? error.message : 'An error occurred while processing the file');
      setProcessing(false);
    }
  };

  const performHybridSearch = async () => {
    try {
      setSearching(true)
      setSearchError(null)

      const words = searchQuery.trim().split(/\s+/)
      const shouldPerformEmbeddingSearch = words.length >= 3

      // Always perform text-based search
      const { data: textData, error: textError } = await supabase
        .from('elements')
        .select('id, content, element_type')
        .ilike('content', `%${searchQuery}%`)
        .limit(10);

      if (textError) {
        console.error('Error in text search:', textError)
        setSearchError('Error performing text search')
      } else if (textData) {
        setTextSearchResults(textData)
      }

      // Perform embedding-based similarity search only if there are 3 or more words
      if (shouldPerformEmbeddingSearch) {
        const embeddingResult = await generateEmbedding(searchQuery)
        if (embeddingResult && embeddingResult.data && embeddingResult.data.length > 0) {
          const embedding = embeddingResult.data[0].embedding
          const { data: embeddingData, error: embeddingError } = await supabase.rpc('match_elements', {
            query_embedding: embedding,
            match_threshold: 0.05,
            match_count: 10
          })

          if (embeddingError) {
            console.error('Error in embedding search:', embeddingError)
            setSearchError('Error performing embedding search')
          } else if (embeddingData) {
            const formattedEmbeddingResults = embeddingData.map((result: { 
              id: string; 
              content: string; 
              element_type: string; 
              similarity: string | number;
            }) => ({
              ...result,
              similarity: typeof result.similarity === 'string' ? parseFloat(result.similarity) : result.similarity
            }))
            setEmbeddingSearchResults(formattedEmbeddingResults as SearchResult[])
          }
        } else {
          console.error('Failed to generate embedding for search query')
        }
      } else {
        // Clear embedding search results if not performing the search
        setEmbeddingSearchResults([])
      }

    } catch (error) {
      console.error('Error performing hybrid search:', error)
      setSearchError('Error performing search')
    } finally {
      setSearching(false)
    }
  }

  const handleSearch = async () => {
    console.log('Search query:', searchQuery);
    if (searchQuery.trim().length < 3) {
      setTextSearchResults([])
      setEmbeddingSearchResults([])
      setSearchError('Please enter at least 3 characters')
      return
    }

    await performHybridSearch()
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const clearSearch = () => {
    setSearchQuery('')
    setTextSearchResults([])
    setEmbeddingSearchResults([])
    setSearchError(null)
  }

  const debouncedSearch = debounce(handleSearch, 300)

  useEffect(() => {
    if (searchQuery.trim() !== '') {
      debouncedSearch()
    }
    return debouncedSearch.cancel
  }, [searchQuery])

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        File Upload and Search
      </Typography>
      <FileUpload 
        bucket="documents"
        folder="uploads"
        onFileUploaded={handleFileUploaded} 
      />
      {processing && <CircularProgress />}
      {(uploadError || processingError) && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {uploadError || processingError}
        </Alert>
      )}
      {uploadedFiles.length > 0 && (
        <Box mt={2}>
          <Typography variant="h6">Uploaded and Processed Files:</Typography>
          <List>
            {uploadedFiles.map((file, index) => (
              <ListItem key={index}>
                <ListItemText primary={file} />
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          Search Documents
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search documents... (Embedding search activates with 3+ words)"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={handleKeyPress}
            sx={{ mr: 1 }}
          />
          <Button
            variant="contained"
            onClick={handleSearch}
            disabled={searching}
            startIcon={<SearchIcon />}
          >
            Search
          </Button>
          <IconButton onClick={clearSearch} disabled={searching || searchQuery === ''}>
            <ClearIcon />
          </IconButton>
        </Box>

        {searching && <CircularProgress sx={{ mt: 2 }} />}
        {searchError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {searchError}
          </Alert>
        )}

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Text Search Results:
              </Typography>
              <List>
                {textSearchResults.map((result) => (
                  <ListItem key={result.id}>
                    <ListItemText
                      primary={result.content}
                      secondary={`Type: ${result.element_type}`}
                    />
                  </ListItem>
                ))}
              </List>
              {textSearchResults.length === 0 && (
                <Typography>No results found</Typography>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Embedding Search Results:
              </Typography>
              {searchQuery.trim().split(/\s+/).length < 3 ? (
                <Typography>Enter at least 3 words to activate embedding search</Typography>
              ) : (
                <>
                  <List>
                    {embeddingSearchResults.map((result) => (
                      <ListItem key={result.id}>
                        <ListItemText
                          primary={result.content}
                          secondary={`Type: ${result.element_type} | Similarity: ${result.similarity?.toFixed(2)}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                  {embeddingSearchResults.length === 0 && (
                    <Typography>No results found</Typography>
                  )}
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default FileUploadPage