import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import { Box, Button, Typography, Paper, IconButton, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem, FormControl, InputLabel, CircularProgress, LinearProgress, List, ListItem, ListItemText, Grid, Card, CardContent, Tooltip, DialogContentText } from '@mui/material'
import { useSupabase } from '../contexts/supabasedb'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import MicIcon from '@mui/icons-material/Mic'
import StopIcon from '@mui/icons-material/Stop'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import EditIcon from '@mui/icons-material/Edit'
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup, { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup';
import { SelectChangeEvent } from '@mui/material/Select'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { styled } from '@mui/system';
import { useSupabaseStorage } from '../hooks/useSupabaseStorage'
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import CreateProjectModal from '../components/CreateProjectModal';
import { Task, Project } from '../types'
import useSnackbar from '../hooks/useSnackbar'
import useChat from '../hooks/useChat';

import {
  BrainStormItem,
  BrainStormRecording,
} from '../types'

// Add these functions before the BrainStorm component definition

const extractContent = (content: string, tag: string): string => {
  const regex = new RegExp(`<${tag}[^>]*>\\s*([\\s\\S]*?)\\s*<\\/${tag}>`, 'i');
  const match = content.match(regex);
  if (match && match[1]) {
    return match[1].trim();
  }
  return '';
};

const extractProjects = (content: string): any[] => {
  const projectsRegex = /<project>([\s\S]*?)<\/project>/gi;
  const projectMatches = content.match(projectsRegex) || [];
  
  return projectMatches.map(projectString => {
    return {
      name: extractContent(projectString, 'name'),
      description: extractContent(projectString, 'description'),
      status: extractContent(projectString, 'status'),
      tasks: extractTasks(projectString),
    };
  });
};

const extractTasks = (content: string): any[] => {
  const tasksRegex = /<task>([\s\S]*?)<\/task>/gi;
  const taskMatches = content.match(tasksRegex) || [];

  return taskMatches.map(taskString => {
    return {
      name: extractContent(taskString, 'name'),
      description: extractContent(taskString, 'description'),
      status: extractContent(taskString, 'status'),
      due_date: extractContent(taskString, 'due_date'),
      recurring: extractContent(taskString, 'recurring'),
    };
  });
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)<ToggleButtonGroupProps>(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const BrainStorm: React.FC = () => {
  const { supabase, getLatestSession } = useSupabase()
  const [brainStorms, setBrainStorms] = useState<BrainStormItem[]>([])
  const [selectedBrainStorm, setSelectedBrainStorm] = useState<BrainStormItem | null>(null)
  const [selectedBrainStormId, setSelectedBrainStormId] = useState<string>('')
  const [newBrainStormTitle, setNewBrainStormTitle] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [recording, setRecording] = useState(false)
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null)
  const [loading, setLoading] = useState(false)
  const mediaRecorderRef = useRef<MediaRecorder | null>(null)
  const audioChunksRef = useRef<Blob[]>([])
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState('');
  const [processing, setProcessing] = useState(false);
  const [viewType, setViewType] = useState<'list' | 'grid'>('list');
  const [sortOrder, setSortOrder] = useState<string>('created_desc');
  const [analysisPrompt, setAnalysisPrompt] = useState<string>('')
  const [analyzingRecordingId, setAnalyzingRecordingId] = useState<string | null>(null);
  const [openTranscriptModal, setOpenTranscriptModal] = useState(false);
  const [editingTranscript, setEditingTranscript] = useState('');
  const [editingRecordingId, setEditingRecordingId] = useState<string | null>(null);
  const [openCreateProjectModal, setOpenCreateProjectModal] = useState(false);
  const [selectedRecording, setSelectedRecording] = useState<BrainStormRecording | null>(null);
  const [audioUrls, setAudioUrls] = useState<{ [key: string]: string }>({})
  const { uploadFile, deleteFile } = useSupabaseStorage('brainstorm-audio', selectedBrainStorm?.id || '');
  const { success, error, info } = useSnackbar();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [editingProject, setEditingProject] = useState<string | null>(null)
  const [editingTask, setEditingTask] = useState<string | null>(null)
  const [editedProjects, setEditedProjects] = useState<{ [key: string]: Project }>({})
  const [editedTasks, setEditedTasks] = useState<{ [key: string]: Partial<Task> }>({})
  const [deleteConfirmation, setDeleteConfirmation] = useState<{ recordingId: string; projectIndex: number; taskIndex: number } | null>(null);
  const { sendMessage, isLoading: isChatLoading, error: chatError } = useChat();

  const getAuthenticatedPublicUrl = useCallback(async (filePath: string) => {
    try {
      // Remove any URL prefix and extract the path after 'brainstorm-audio/'
      const cleanPath = filePath.replace(/^(https?:\/\/[^\/]+\/storage\/v1\/object\/public\/)?brainstorm-audio\//, '');
      
      // Remove any duplicate 'brainstorm-audio/' prefixes
      const finalPath = cleanPath.replace(/^brainstorm-audio\//, '');
      
      console.log('Attempting to get signed URL for:', finalPath);
      
      const { data, error } = await supabase.storage
        .from('brainstorm-audio')
        .createSignedUrl(finalPath, 3600) // URL valid for 1 hour

      if (error) throw error

      console.log('Successfully got signed URL:', data.signedUrl);
      return data.signedUrl
    } catch (err) {
      console.error('Error getting signed URL:', err)
      return null
    }
  }, [supabase])

  useEffect(() => {
    const checkAuth = async () => {
      const session = await getLatestSession()
      if (!session) {
        // Redirect to login page or show auth error
        console.error('User not authenticated')
        // You might want to use a router to redirect:
        // navigate('/login')
      }
    }
    checkAuth()
    fetchBrainStorms()
  }, [getLatestSession])

  useEffect(() => {
    const fetchAnalysisPrompt = async () => {
      const { data, error } = await supabase
        .from('prompts')
        .select('prompt')
        .eq('id', '9607e48c-64e9-41f3-ac74-1491bb2f18d2')
        .single()

      if (error) {
        console.error('Error fetching analysis prompt:', error)
      } else if (data) {
        setAnalysisPrompt(data.prompt)
      }
    }

    fetchAnalysisPrompt()
  }, [supabase])

  const fetchBrainStorms = async () => {
    const { data, error: fetchError } = await supabase
      .from('brainstorms')
      .select(`
        *,
        recordings:brainstorm_recordings(*)
      `)
      .order('created_at', { ascending: false })

    if (fetchError) {
      console.error('Error fetching brainstorms:', fetchError)
      error('Failed to fetch brainstorms')
    } else {
      setBrainStorms(data?.map(brainStorm => ({
        ...brainStorm,
        recordings: (brainStorm.recordings || []).filter((recording: BrainStormRecording | null): recording is BrainStormRecording => recording !== null)
      })) || [])
    }
  }

  const createNewBrainStorm = async () => {
    if (!newBrainStormTitle.trim()) {
      setErrorMessage('Title cannot be empty');
      return;
    }

    try {
      const { data, error: supabaseError } = await supabase
        .from('brainstorms')
        .insert({
          title: newBrainStormTitle,
        })
        .select()
        .single();

      if (supabaseError) throw supabaseError;

      await fetchBrainStorms();
      setNewBrainStormTitle('');
      setOpenDialog(false);
      setErrorMessage(null);
      
      if (data) {
        setSelectedBrainStormId(data.id);
        setSelectedBrainStorm({
          ...data,
          recordings: []
        });
      }
      success('New brainstorm created successfully');
    } catch (err) {
      console.error('Error creating new brainstorm:', err);
      setErrorMessage(`Failed to create new brainstorm: ${err instanceof Error ? err.message : String(err)}`);
      error(`Failed to create new brainstorm: ${err instanceof Error ? err.message : String(err)}`);
    }
  };

  const deleteBrainStorm = async (id: string) => {
    try {
      const { error } = await supabase
        .from('brainstorms')
        .delete()
        .eq('id', id)

      if (error) throw error

      fetchBrainStorms()
      if (selectedBrainStorm?.id === id) {
        setSelectedBrainStorm(null)
      }
    } catch (err) {
      console.error('Error deleting brainstorm:', err)
      error(`Failed to delete brainstorm: ${err instanceof Error ? err.message : String(err)}`);
    }
  }

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'audio/webm' })
      audioChunksRef.current = []

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data)
      }

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' })
        setAudioBlob(audioBlob)
      }

      mediaRecorderRef.current.start()
      setRecording(true)
    } catch (err) {
      console.error('Error starting recording:', err)
      error(`Failed to start recording: ${err instanceof Error ? err.message : String(err)}`);
    }
  }

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop()
      setRecording(false)
    }
  }

  const transcribeAudio = async (audioFile: File): Promise<string> => {
    try {
      console.log('Starting transcription process for file:', audioFile.name);
      const formData = new FormData();
      formData.append('audio', audioFile);

      console.log('Sending transcription request to backend');
      const response = await fetch('http://localhost:3001/api/transcribe', {
        method: 'POST',
        body: formData,
      });

      console.log('Received response from backend, status:', response.status);
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const result = await response.json();
      console.log('Transcription API Response:', result);

      if (!result.transcription) {
        console.error('No transcription in response');
        throw new Error('No transcription returned from API');
      }

      return result.transcription;
    } catch (error) {
      console.error('Error transcribing audio:', error);
      throw error;
    }
  };

  const uploadAudio = async (file: File | Blob) => {
    if (!selectedBrainStorm) {
      error('Please select a brainstorm first');
      return;
    }

    setLoading(true);
    setProcessing(true);
    info('Uploading audio...');

    try {
      console.log('Starting audio upload and transcription process');
      const wavFile = await blobToWavFile(file);
      console.log('Converted to WAV file:', wavFile.name);

      const fileName = `audio_${Date.now()}.wav`;

      // Create a new recording entry
      const { data: newRecording, error: insertError } = await supabase
        .from('brainstorm_recordings')
        .insert({
          brainstorm_id: selectedBrainStorm.id,
          audio_file: '',
          transcription: '',
        })
        .select()
        .single();

      if (insertError || !newRecording) throw insertError || new Error('Failed to create recording');

      // Upload the file
      const uploadedFile = await uploadFile(wavFile);
      
      if (!uploadedFile) {
        throw new Error('Failed to upload file');
      }

      // Update the audio_file field
      const audioFilePath = `${selectedBrainStorm.id}/${fileName}`;
      const { error: updateError } = await supabase
        .from('brainstorm_recordings')
        .update({ audio_file: audioFilePath })
        .eq('id', newRecording.id);

      if (updateError) throw updateError;

      const base64Audio = await fileToBase64(wavFile);

      console.log('File uploaded to Supabase, path:', audioFilePath);

      console.log('Starting transcription');
      const transcription = await transcribeAudio(wavFile);
      console.log('Transcription completed:', transcription.substring(0, 100) + '...');
      
      // Update the transcription in the database
      const { error: transcriptionUpdateError } = await supabase
        .from('brainstorm_recordings')
        .update({ transcription })
        .eq('id', newRecording.id);

      if (transcriptionUpdateError) throw transcriptionUpdateError;

      console.log('Transcription saved to database');

      // Fetch the updated brainstorm
      const { data: updatedBrainStorm, error: fetchError } = await supabase
        .from('brainstorms')
        .select(`*, recordings:brainstorm_recordings(*)`)
        .eq('id', selectedBrainStorm.id)
        .single();

      if (fetchError) throw fetchError;

      console.log('Updated brainstorm fetched');
      setSelectedBrainStorm(updatedBrainStorm);
      setAudioBlob(null);
      success('Audio uploaded and transcribed successfully');
    } catch (err) {
      console.error('Error in uploadAudio:', err);
      error(`Failed to upload and transcribe audio: ${err instanceof Error ? err.message : String(err)}`);
    } finally {
      setLoading(false);
      setProcessing(false);
    }
  };

  const blobToWavFile = async (blob: Blob): Promise<File> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        if (event.target && event.target.result) {
          const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
          const audioBuffer = await audioContext.decodeAudioData(event.target.result as ArrayBuffer);
          
          const wavBuffer = audioBufferToWav(audioBuffer);
          const wavBlob = new Blob([wavBuffer], { type: 'audio/wav' });
          const wavFile = new File([wavBlob], `audio_${Date.now()}.wav`, { type: 'audio/wav' });
          
          resolve(wavFile);
        } else {
          reject(new Error('Failed to read file'));
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });
  }

  const audioBufferToWav = (buffer: AudioBuffer): ArrayBuffer => {
    const numOfChan = buffer.numberOfChannels;
    const length = buffer.length * numOfChan * 2 + 44;
    const outBuffer = new ArrayBuffer(length);
    const view = new DataView(outBuffer);
    const channels = [];
    let sample = 0;
    let offset = 0;
    let pos = 0;

    setUint32(0x46464952);                         // "RIFF"
    setUint32(length - 8);                         // file length - 8
    setUint32(0x45564157);                         // "WAVE"

    setUint32(0x20746d66);                         // "fmt " chunk
    setUint32(16);                                 // length = 16
    setUint16(1);                                  // PCM (uncompressed)
    setUint16(numOfChan);
    setUint32(buffer.sampleRate);
    setUint32(buffer.sampleRate * 2 * numOfChan);  // avg. bytes/sec
    setUint16(numOfChan * 2);                      // block-align
    setUint16(16);                                 // 16-bit (hardcoded in this demo)

    setUint32(0x61746164);                         // "data" - chunk
    setUint32(length - pos - 4);                   // chunk length

    for(let i = 0; i < buffer.numberOfChannels; i++)
      channels.push(buffer.getChannelData(i));

    while(pos < length) {
      for(let i = 0; i < numOfChan; i++) {             // interleave channels
        sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
        sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767)|0; // scale to 16-bit signed int
        view.setInt16(pos, sample, true);          // write 16-bit sample
        pos += 2;
      }
      offset++                                     // next source sample
    }

    return outBuffer;

    function setUint16(data: number) {
      view.setUint16(pos, data, true);
      pos += 2;
    }

    function setUint32(data: number) {
      view.setUint32(pos, data, true);
      pos += 4;
    }
  }

  const fileToBase64 = (file: File | Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }

  const handleAnalyze = async (recordingId: string) => {
    setAnalyzingRecordingId(recordingId);
    info('Starting analysis...');

    try {
      console.log('Starting analysis for recording:', recordingId);

      const { data: recording, error: fetchError } = await supabase
        .from('brainstorm_recordings')
        .select('transcription')
        .eq('id', recordingId)
        .single();

      if (fetchError) {
        console.error('Error fetching recording:', fetchError);
        throw fetchError;
      }

      console.log('Fetched transcription:', );

      const { analysis, structuredData } = await analyzeTranscription(recording.transcription);

      console.log('Analysis result:', analysis);
      console.log('Structured data:', structuredData);

      const { data: updateData, error: updateError } = await supabase
        .from('brainstorm_recordings')
        .update({
          analysis: analysis,
          structured_data: structuredData
        })
        .eq('id', recordingId)
        .select();

      if (updateError) {
        console.error('Error updating recording:', updateError);
        throw updateError;
      }

      console.log('Update result:', updateData);

      setSelectedBrainStorm(prevState => {
        if (!prevState) return null;
        return {
          ...prevState,
          recordings: prevState.recordings.map(rec => 
            rec?.id === recordingId ? { ...rec, analysis: analysis, structured_data: structuredData } : rec
          )
        };
      });

      console.log('Local state updated');
      success('Analysis completed successfully');
    } catch (err) {
      console.error('Error analyzing recording:', err);
      console.error('Error details:', err instanceof Error ? err.stack : String(err));
      error(`Failed to analyze recording: ${err instanceof Error ? err.message : String(err)}`);
    } finally {
      setAnalyzingRecordingId(null);
    }
  };

  const analyzeTranscription = async (transcription: string): Promise<{ analysis: string; structuredData: any }> => {
    if (!analysisPrompt) {
      console.error('Analysis prompt not available');
      return { analysis: 'Analysis prompt not available', structuredData: {} };
    }
  
    try {
      const promptWithTranscript = analysisPrompt.replace('{{TRANSCRIPT}}', transcription);
  
      const response = await sendMessage('anthropic', [
        { role: 'user', content: promptWithTranscript }
      ], 'claude-3-5-sonnet-20240620', 8192);

      console.log('API Response:', response);
  
      let analysisText = response;
      console.log('Raw analysis response:', analysisText);

      const analysisMatch = analysisText.match(/<analysis>([\s\S]*)<\/analysis>/i);
      if (analysisMatch) {
        analysisText = analysisMatch[1];
        console.log('Extracted analysis text:', analysisText);
      } else {
        console.warn('Analysis tags not found in the response. Using full response.');
      }
      
      const structuredData = {
        summary: extractContent(analysisText, 'summary'),
        projects: extractProjects(analysisText),
        tasks: extractTasks(analysisText),
      };

      console.log('Extracted summary:', structuredData.summary);
      console.log('Extracted projects:', JSON.stringify(structuredData.projects, null, 2));
      console.log('Extracted tasks:', JSON.stringify(structuredData.tasks, null, 2));

      console.log('Final structured data:', JSON.stringify(structuredData, null, 2));

      return {
        analysis: analysisText,
        structuredData: structuredData 
      };
    } catch (error) {
      console.error('Error analyzing transcription:', error);
      return {
        analysis: `Error analyzing transcription: ${error instanceof Error ? error.message : String(error)}`,
        structuredData: {} 
      };
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file && file.type.startsWith('audio/')) {
      uploadAudio(file)
    } else {
      error('Please select an audio file');
    }
  }

  const updateBrainStormTitle = async () => {
    if (!selectedBrainStorm || !editedTitle.trim()) return;

    try {
      const { error } = await supabase
        .from('brainstorms')
        .update({ title: editedTitle })
        .eq('id', selectedBrainStorm.id);

      if (error) throw error;

      setSelectedBrainStorm(prev => prev ? { ...prev, title: editedTitle } : null);
      setIsEditing(false);
      fetchBrainStorms();
    } catch (err) {
      console.error('Error updating brainstorm title:', err);
      error(`Failed to update brainstorm title: ${err instanceof Error ? err.message : String(err)}`);
    }
  };

  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: 'list' | 'grid' | null,
  ) => {
    if (newView !== null) {
      setViewType(newView);
    }
  };

  const handleSortChange = (event: SelectChangeEvent) => {
    setSortOrder(event.target.value as string);
  };

  const sortedBrainStorms = useMemo(() => {
    return [...brainStorms].sort((a, b) => {
      switch (sortOrder) {
        case 'created_desc':
          return new Date(b.created_at || Date.now()).getTime() - new Date(a.created_at || Date.now()).getTime();
        case 'created_asc':
          return new Date(a.created_at || Date.now()).getTime() - new Date(b.created_at || Date.now()).getTime();
        case 'alpha_asc':
          return (a.title || '').localeCompare(b.title || '');
        case 'alpha_desc':
          return (b.title || '').localeCompare(a.title || '');
        default:
          return 0;
      }
    });
  }, [brainStorms, sortOrder]);

  const deleteRecording = async (recordingId: string) => {
    if (!selectedBrainStorm) return

    try {
      const { error } = await supabase
        .from('brainstorm_recordings')
        .delete()
        .eq('id', recordingId)

      if (error) throw error

      const recording = selectedBrainStorm.recordings.find(rec => rec?.id === recordingId)
      if (recording && recording.audio_file) {
        await deleteFile(recording.audio_file.split('/').pop() || '')
      }

      setSelectedBrainStorm(prevState => {
        if (!prevState) return null
        return {
          ...prevState,
          recordings: prevState.recordings.filter(rec => rec?.id !== recordingId)
        }
      })

      setBrainStorms(prevState => 
        prevState.map(bs => 
          bs.id === selectedBrainStorm.id 
            ? { ...bs, recordings: bs.recordings.filter(rec => rec?.id !== recordingId) }
            : bs
        )
      )

    } catch (err) {
      console.error('Error deleting recording:', err)
      error(`Failed to delete recording: ${err instanceof Error ? err.message : String(err)}`);
    }
  }

  const handleTranscriptUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !selectedBrainStorm) {
      error('Please select a file and ensure a brainstorm is selected');
      return;
    }

    setLoading(true);
    setProcessing(true); // Add this line to show processing state
    info('Uploading transcript...');

    console.log('Starting transcript upload for file:', file.name);

    try {
      const content = await file.text();
      console.log('File content read, length:', content.length);
      
      const { data, error } = await supabase
        .from('brainstorm_recordings')
        .insert({
          brainstorm_id: selectedBrainStorm.id,
          audio_file: '', // No audio file for text uploads
          transcription: content,
        })
        .select()
        .single();

      if (error) throw error;

      console.log('Transcript uploaded successfully, recording ID:', data.id);

      // Update the local state
      setSelectedBrainStorm(prevState => {
        if (!prevState) return null;
        return {
          ...prevState,
          recordings: [...prevState.recordings, data]
        };
      });

      // Optionally, you can also update the brainStorms state
      setBrainStorms(prevState => 
        prevState.map(bs => 
          bs.id === selectedBrainStorm.id 
            ? { ...bs, recordings: [...bs.recordings, data] }
            : bs
        )
      );

      // Show success message
      success('Transcript uploaded successfully!');

    } catch (err) {
      console.error('Error uploading transcript:', err);
      error(`Failed to upload transcript: ${err instanceof Error ? err.message : String(err)}`);
    } finally {
      setLoading(false);
      setProcessing(false); // Add this line to hide processing state
      // Clear the file input
      event.target.value = '';
    }
  };

  const handleOpenTranscriptModal = (recording: BrainStormRecording) => {
    setEditingTranscript(recording.transcription || '');
    setEditingRecordingId(recording.id);
    setOpenTranscriptModal(true);
  };

  const handleSaveTranscript = async () => {
    if (!editingRecordingId) return;

    try {
      const { error } = await supabase
        .from('brainstorm_recordings')
        .update({ transcription: editingTranscript })
        .eq('id', editingRecordingId);

      if (error) throw error;

      // Update local state
      setSelectedBrainStorm(prevState => {
        if (!prevState) return null;
        return {
          ...prevState,
          recordings: prevState.recordings.map(rec =>
            rec?.id === editingRecordingId ? { ...rec, transcription: editingTranscript } : rec
          )
        };
      });

      setOpenTranscriptModal(false);
      success('Transcript updated successfully!');
    } catch (err) {
      console.error('Error updating transcript:', err);
      error(`Failed to update transcript: ${err instanceof Error ? err.message : String(err)}`);
    }
  }

  const renderAnalysis = (recording: BrainStormRecording) => {
    console.log('Structured data:', JSON.stringify(recording.structured_data, null, 2));

    if (!recording.structured_data) {
      return <Typography>No structured data available.</Typography>
    }

    // Type guard function
    const isValidStructuredData = (data: any): data is { summary: string; projects: Project[]; tasks: Task[] } => {
      console.log('Validating structured data...');
      console.log('Has summary:', typeof data.summary === 'string');
      console.log('Has projects array:', Array.isArray(data.projects));
      console.log('Has tasks array:', Array.isArray(data.tasks));
      
      const isValid = typeof data === 'object' && data !== null &&
             typeof data.summary === 'string' &&
             Array.isArray(data.projects) &&
             Array.isArray(data.tasks) &&
             data.projects.every((project: any) => 
               typeof project === 'object' && project !== null &&
               typeof project.name === 'string' &&
               typeof project.description === 'string' &&
               typeof project.status === 'string' &&
               Array.isArray(project.tasks)
             ) &&
             data.tasks.every((task: any) =>
               typeof task === 'object' && task !== null &&
               typeof task.name === 'string' &&
               typeof task.description === 'string' &&
               typeof task.status === 'string'
             );
      
      console.log('Is valid:', isValid);
      return isValid;
    };

    // Use the type guard
    if (!isValidStructuredData(recording.structured_data)) {
      return <Typography>Invalid structured data format.</Typography>
    }

    const data = recording.structured_data;

    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" gutterBottom>Summary:</Typography>
        <Typography paragraph>{data.summary}</Typography>
        <Typography variant="h6" gutterBottom>Projects:</Typography>
        {data.projects.map((project, index) => (
          <Card key={index} sx={{ mb: 2, p: 2 }}>
            {editingProject === `${recording.id}-${index}` ? (
              <EditProjectForm
                project={editedProjects[`${recording.id}-${index}`] || project}
                onSave={(editedProject) => handleSaveProject(recording.id, index, editedProject)}
                onCancel={() => setEditingProject(null)}
              />
            ) : (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                  <Typography variant="h6">{project.name}</Typography>
                  <IconButton onClick={() => handleEditProject(recording.id, index, project)}>
                    <EditIcon />
                  </IconButton>
                </Box>
                <Typography paragraph>{project.description}</Typography>
                <Typography variant="subtitle1" gutterBottom>Tasks:</Typography>
                <List>
                  {project.tasks && project.tasks.map((task: Task, taskIndex: number) => (
                    <ListItem key={taskIndex}>
                      {editingTask === `${recording.id}-${index}-${taskIndex}` ? (
                        <EditTaskForm
                          task={editedTasks[`${recording.id}-${index}-${taskIndex}`] || task}
                          onSave={(editedTask) => handleSaveTask(recording.id, index, taskIndex, editedTask)}
                          onCancel={() => setEditingTask(null)}
                          onDelete={() => handleDeleteTask(recording.id, index, taskIndex)}
                        />
                      ) : (
                        <>
                          <ListItemText
                            primary={task.name}
                            secondary={`Status: ${task.status}, Due: ${task.due_date || 'Not set'}`}
                          />
                          <IconButton onClick={() => handleEditTask(recording.id, index, taskIndex, task)}>
                            <EditIcon />
                          </IconButton>
                        </>
                      )}
                    </ListItem>
                  ))}
                </List>
                <Button
                  startIcon={<AddIcon />}
                  onClick={() => handleAddTask(recording.id, index)}
                  sx={{ mt: 1 }}
                >
                  Add New Task
                </Button>
              </>
            )}
          </Card>
        ))}
      </Box>
    );
  };

  const handleEditProject = (recordingId: string, projectIndex: number, project: Project) => {
    setEditingProject(`${recordingId}-${projectIndex}`);
    setEditedProjects(prev => ({
      ...prev,
      [`${recordingId}-${projectIndex}`]: { ...project }
    }));
  };

  const handleSaveProject = (recordingId: string, projectIndex: number, editedProject: Project) => {
    setEditingProject(null);
    const updatedRecording = { ...selectedBrainStorm!.recordings.find(r => r.id === recordingId)! };
    const updatedStructuredData = { ...updatedRecording.structured_data as any };
    updatedStructuredData.projects[projectIndex] = editedProject;
    updatedRecording.structured_data = updatedStructuredData;

    setSelectedBrainStorm(prev => ({
      ...prev!,
      recordings: prev!.recordings.map(r => r.id === recordingId ? updatedRecording : r)
    }));
  };

  const handleEditTask = (recordingId: string, projectIndex: number, taskIndex: number, task: Partial<Task>) => {
    setEditingTask(`${recordingId}-${projectIndex}-${taskIndex}`);
    setEditedTasks(prev => ({
      ...prev,
      [`${recordingId}-${projectIndex}-${taskIndex}`]: { ...task }
    }));
  };

  const handleSaveTask = (recordingId: string, projectIndex: number, taskIndex: number, editedTask: Partial<Task>) => {
    setEditingTask(null);
    const updatedRecording = { ...selectedBrainStorm!.recordings.find(r => r.id === recordingId)! };
    const updatedStructuredData = { ...updatedRecording.structured_data as any };
    updatedStructuredData.projects[projectIndex].tasks[taskIndex] = {
      ...updatedStructuredData.projects[projectIndex].tasks[taskIndex],
      ...editedTask
    };
    updatedRecording.structured_data = updatedStructuredData;

    setSelectedBrainStorm(prev => ({
      ...prev!,
      recordings: prev!.recordings.map(r => r.id === recordingId ? updatedRecording : r)
    }));
  };

  const handleDeleteTask = (recordingId: string, projectIndex: number, taskIndex: number) => {
    setDeleteConfirmation({ recordingId, projectIndex, taskIndex });
  };

  const confirmDeleteTask = () => {
    if (deleteConfirmation) {
      const { recordingId, projectIndex, taskIndex } = deleteConfirmation;
      const updatedRecording = { ...selectedBrainStorm!.recordings.find(r => r.id === recordingId)! };
      const updatedStructuredData = { ...updatedRecording.structured_data as any };
      updatedStructuredData.projects[projectIndex].tasks.splice(taskIndex, 1);
      updatedRecording.structured_data = updatedStructuredData;

      setSelectedBrainStorm(prev => ({
        ...prev!,
        recordings: prev!.recordings.map(r => r.id === recordingId ? updatedRecording : r)
      }));

      setEditingTask(null);
      setDeleteConfirmation(null);
    }
  };

  const handleAddTask = (recordingId: string, projectIndex: number) => {
    const newTask: Partial<Task> = {
      name: 'New Task',
      description: '',
      status: 'Not Started',
      due_date: null,
    };

    const updatedRecording = { ...selectedBrainStorm!.recordings.find(r => r.id === recordingId)! };
    const updatedStructuredData = { ...updatedRecording.structured_data as any };
    updatedStructuredData.projects[projectIndex].tasks.push(newTask);
    updatedRecording.structured_data = updatedStructuredData;

    setSelectedBrainStorm(prev => ({
      ...prev!,
      recordings: prev!.recordings.map(r => r.id === recordingId ? updatedRecording : r)
    }));

    // Optionally, you can immediately open the edit form for the new task
    const newTaskIndex = updatedStructuredData.projects[projectIndex].tasks.length - 1;
    handleEditTask(recordingId, projectIndex, newTaskIndex, newTask as Task);
  };

  const handleOpenCreateProjectModal = (recording: BrainStormRecording) => {
    console.log('Structured data:', JSON.stringify(recording.structured_data, null, 2));
    setSelectedRecording(recording);
    setOpenCreateProjectModal(true);
  };

  const handleCloseCreateProjectModal = () => {
    setOpenCreateProjectModal(false);
    setSelectedRecording(null);
  }

  useEffect(() => {
    const fetchAudioUrls = async () => {
      if (selectedBrainStorm?.recordings) {
        const urls: { [key: string]: string } = {}
        for (const recording of selectedBrainStorm.recordings) {
          if (recording && recording.audio_file) {
            console.log('Fetching URL for:', recording.audio_file)
            const url = await getAuthenticatedPublicUrl(recording.audio_file)
            console.log('Received URL:', url)
            if (url) {
              urls[recording.id] = url
            }
          }
        }
        setAudioUrls(urls)
        console.log('Set audio URLs:', urls)
      }
    }

    fetchAudioUrls()
  }, [selectedBrainStorm, getAuthenticatedPublicUrl])

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" sx={{ flexShrink: 0, mr: 2 }}>
          BrainStorms
        </Typography>
        {!selectedBrainStorm && (
          <>
            <StyledToggleButtonGroup
              value={viewType}
              exclusive
              onChange={handleViewChange}
              aria-label="view type"
              size="small"
              sx={{ mr: 2 }}
            >
              <ToggleButton value="list" aria-label="list view">
                <Tooltip title="List View">
                  <ViewListIcon />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="grid" aria-label="grid view">
                <Tooltip title="Grid View">
                  <ViewModuleIcon />
                </Tooltip>
              </ToggleButton>
            </StyledToggleButtonGroup>
            <FormControl sx={{ minWidth: 120, mr: 2 }}>
              <InputLabel id="sort-select-label">Sort By</InputLabel>
              <Select
                labelId="sort-select-label"
                id="sort-select"
                value={sortOrder}
                label="Sort By"
                onChange={handleSortChange}
                sx={{ height: '42px' }}
              >
                <MenuItem value="created_desc">Newest First</MenuItem>
                <MenuItem value="created_asc">Oldest First</MenuItem>
                <MenuItem value="alpha_asc">A to Z</MenuItem>
                <MenuItem value="alpha_desc">Z to A</MenuItem>
              </Select>
            </FormControl>
          </>
        )}
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
          sx={{ ml: 'auto', mr: 2 }}
        >
          New BrainStorm
        </Button>
      </Box>

      {!selectedBrainStorm ? (
        <Box sx={{ mt: 2 }}>
          {viewType === 'list' ? (
            <List>
              {sortedBrainStorms.map((brainStorm) => (
                <ListItem
                  key={brainStorm.id}
                  button
                  onClick={() => {
                    setSelectedBrainStormId(brainStorm.id);
                    setSelectedBrainStorm(brainStorm);
                  }}
                >
                  <ListItemText
                    primary={brainStorm.title || 'Untitled Brainstorm'}
                    secondary={new Date(brainStorm.created_at || Date.now()).toLocaleString()}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Grid container spacing={2}>
              {sortedBrainStorms.map((brainStorm) => (
                <Grid item xs={12} sm={6} md={4} key={brainStorm.id}>
                  <Card
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSelectedBrainStormId(brainStorm.id);
                      setSelectedBrainStorm(brainStorm);
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" component="div">
                        {brainStorm.title || 'Untitled Brainstorm'}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Created: {new Date(brainStorm.created_at || Date.now()).toLocaleString()}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      ) : (
        <>
          <Paper elevation={0} sx={{ p: 3, mt: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                {isEditing ? (
                  <>
                    <TextField
                      value={editedTitle}
                      onChange={(e) => setEditedTitle(e.target.value)}
                      variant="outlined"
                      size="small"
                      sx={{ mr: 1, flexGrow: 1 }}
                    />
                    <Button onClick={updateBrainStormTitle} variant="contained" size="small" sx={{ mr: 1 }}>
                      Save
                    </Button>
                    <Button onClick={() => setIsEditing(false)} variant="outlined" size="small">
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    <IconButton onClick={() => {
                      setEditedTitle(selectedBrainStorm.title || '');
                      setIsEditing(true);
                    }} sx={{ mr: 1 }}>
                      <EditIcon />
                    </IconButton>
                    <Typography variant="h5" sx={{ flexGrow: 1 }}>
                      {selectedBrainStorm.title || 'Untitled Brainstorm'}
                    </Typography>
                  </>
                )}
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => setSelectedBrainStorm(null)}
                  sx={{ mr: 1 }}
                >
                  Back to List
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={() => deleteBrainStorm(selectedBrainStorm.id)}
                >
                  Delete
                </Button>
              </Box>
            </Box>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Created at: {new Date(selectedBrainStorm.created_at || Date.now()).toLocaleString()}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                  variant="contained"
                  color={recording ? 'secondary' : 'primary'}
                  startIcon={recording ? <StopIcon /> : <MicIcon />}
                  onClick={recording ? stopRecording : startRecording}
                  disabled={processing}
                  sx={{ width: 220 }}
                  size="small"
                >
                  {recording ? 'Stop Recording' : 'Start Recording'}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => audioBlob && uploadAudio(audioBlob)}
                  disabled={!audioBlob || loading || processing}
                  startIcon={processing ? <CircularProgress size={20} /> : null}
                  size="small"
                >
                  {processing ? 'Processing...' : 'Process Audio'}
                </Button>
              </Box>
              <Button
                variant="contained"
                component="label"
                startIcon={<CloudUploadIcon />}
                disabled={processing}
                sx={{ width: 220 }}
                size="small"
              >
                Upload Audio
                <input
                  type="file"
                  hidden
                  accept="audio/*"
                  onChange={handleFileUpload}
                  disabled={processing}
                />
              </Button>
              <Button
                variant="contained"
                component="label"
                startIcon={processing ? <CircularProgress size={20} /> : <NoteAddIcon />}
                disabled={processing}
                sx={{ width: 220 }}
                onClick={() => console.log('Upload Transcript button clicked')}
                size="small"
              >
                {processing ? 'Uploading...' : 'Upload Transcript'}
                <input
                  type="file"
                  hidden
                  accept=".txt,.md"
                  onChange={handleTranscriptUpload}
                  disabled={processing}
                />
              </Button>
            </Box>
            {processing && <LinearProgress sx={{ mt: 2 }} />}
          </Paper>
          <Paper id="recording-container" elevation={0} sx={{ p: 3, mt: 3 }}>
            {selectedBrainStorm.recordings && selectedBrainStorm.recordings.length > 0 ? (
              selectedBrainStorm.recordings
                .filter((recording): recording is BrainStormRecording => recording !== null)
                .map((recording, index) => (
                  <Box key={recording.id} mt={2} mb={2}>
                    <Box display="flex" justifyContent="flex-start" alignItems="center"  sx={{ py: 1, px: 3, backgroundColor: '#f0f0f0', borderRadius: 10 }}>
                      <Typography variant="h6" sx={{ mr: 2, minWidth: '120px' }}>Entry {index + 1}</Typography>
                      <Box sx={{ flexGrow: 1, px: 1 }}>
                        {audioUrls[recording.id] ? (
                          <audio 
                            controls
                            src={audioUrls[recording.id]}
                            onError={(e) => {
                              console.error("Audio playback error:", e);
                              console.log("Attempted URL:", audioUrls[recording.id]);
                            }}
                            style={{ maxWidth: '260px', height: '30px', border: 'none', padding: '2px', borderRadius: 10, backgroundColor: '#f0f0f0' }}
                          />
                        ) : (
                          <Typography variant="body2" color="text.secondary">No audio available</Typography>
                        )}
                      </Box>
                      <Box display="flex" justifyContent="flex-end" alignItems="center">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleOpenTranscriptModal(recording)}
                          sx={{ mr: 1 }}
                          size="small"
                        >
                          View/Edit Transcript
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleAnalyze(recording.id)}
                          disabled={analyzingRecordingId === recording.id}
                          startIcon={analyzingRecordingId === recording.id ? <CircularProgress size={20} /> : null}
                          sx={{ mr: 1 }}
                          size="small"
                        >
                          {analyzingRecordingId === recording.id 
                            ? 'Analyzing...' 
                            : recording.analysis 
                              ? 'Re-Analyze' 
                              : 'Analyze'}
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleOpenCreateProjectModal(recording)}
                          disabled={!recording.structured_data}
                          sx={{ mr: 1 }}
                          size="small"
                        >
                          Create Project
                        </Button>
                        <IconButton 
                          onClick={() => deleteRecording(recording.id)}
                          color="error"
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    {recording.analysis && (
                      <Box sx={{ mt: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
                        <Typography variant="h6" gutterBottom>Analysis:</Typography>
                        {renderAnalysis(recording)}
                      </Box>
                    )}
                  </Box>
                ))
            ) : (
              <Typography>No recordings available for this brainstorm.</Typography>
            )}
          </Paper>
        </>
      )}

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Create New BrainStorm</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="BrainStorm Title"
            fullWidth
            value={newBrainStormTitle}
            onChange={(e) => setNewBrainStormTitle(e.target.value)}
            error={!!errorMessage}
            helperText={errorMessage}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setOpenDialog(false);
            setErrorMessage(null);
            setNewBrainStormTitle('');
          }}>
            Cancel
          </Button>
          <Button 
            onClick={createNewBrainStorm} 
            color="primary" 
            variant="contained"
            disabled={!newBrainStormTitle.trim()}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openTranscriptModal}
        onClose={() => setOpenTranscriptModal(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Edit Transcript</DialogTitle>
        <DialogContent>
          <TextareaAutosize
            minRows={10}
            style={{ width: '100%', padding: '10px' }}
            value={editingTranscript}
            onChange={(e) => setEditingTranscript(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenTranscriptModal(false)}>Cancel</Button>
          <Button onClick={handleSaveTranscript} variant="contained" color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      <CreateProjectModal
        open={openCreateProjectModal}
        onClose={handleCloseCreateProjectModal}
        structuredData={selectedRecording?.structured_data || { projects: [] }}
      />

      <Dialog
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
      >
        <DialogTitle>Confirm Task Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this task? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmation(null)}>Cancel</Button>
          <Button onClick={confirmDeleteTask} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {chatError && <Typography color="error">{chatError}</Typography>}
      {isChatLoading && <CircularProgress />}
    </Box>
  )
}

interface EditProjectFormProps {
  project: Project;
  onSave: (editedProject: Project) => void;
  onCancel: () => void;
}

const EditProjectForm: React.FC<EditProjectFormProps> = ({ project, onSave, onCancel }) => {
  const [editedProject, setEditedProject] = useState(project);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setEditedProject(prev => ({ ...prev, [name]: value }));
  };

  return (
    <Box component="form" onSubmit={(e) => { e.preventDefault(); onSave(editedProject); }}>
      <TextField
        fullWidth
        margin="normal"
        name="name"
        label="Project Name"
        value={editedProject.name}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        margin="normal"
        name="description"
        label="Description"
        multiline
        rows={4}
        value={editedProject.description}
        onChange={handleChange}
      />
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onCancel} sx={{ mr: 1 }}>Cancel</Button>
        <Button type="submit" variant="contained">Save</Button>
      </Box>
    </Box>
  );
};

interface EditTaskFormProps {
  task: Partial<Task>;
  onSave: (editedTask: Partial<Task>) => void;
  onCancel: () => void;
  onDelete: (taskId: string) => void;
}

const EditTaskForm: React.FC<EditTaskFormProps> = ({ task, onSave, onCancel, onDelete }) => {
  const [editedTask, setEditedTask] = useState<Partial<Task>>({
    name: task.name || '',
    description: task.description || '',
    status: task.status || 'Not Started',
    due_date: task.due_date || '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setEditedTask(prev => ({ ...prev, [name]: value }));
  };

  return (
    <Box component="form" onSubmit={(e) => { e.preventDefault(); onSave(editedTask as Task); }}>
      <Paper sx={{ p: 3, mt: 3 }}>
        <TextField
          fullWidth
          margin="normal"
          name="name"
          label="Task Name"
          value={editedTask.name}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          name="description"
          label="Description"
          multiline
          rows={2}
          value={editedTask.description}
          onChange={handleChange}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="task-status-label">Status</InputLabel>
          <Select
            labelId="task-status-label"
            name="status"
            value={editedTask.status || ''}
            onChange={handleChange as (event: SelectChangeEvent<string>, child: React.ReactNode) => void}
            label="Status"
          >
            <MenuItem value="Not Started">Not Started</MenuItem>
            <MenuItem value="In Progress">In Progress</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          margin="normal"
          name="due_date"
          label="Due Date"
          type="date"
          value={editedTask.due_date || ''}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button 
            onClick={() => onDelete(task.id!)} 
            color="error" 
            startIcon={<DeleteIcon />}
          >
            Delete Task
          </Button>
          <Box>
            <Button onClick={onCancel} sx={{ mr: 1 }}>Cancel</Button>
            <Button type="submit" variant="contained">Save</Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default BrainStorm