import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react'
import { User, Session } from '@supabase/supabase-js'
import { useSupabase } from './supabasedb'
import { useNavigate } from 'react-router-dom'

interface AuthContextType {
  user: User | null
  loading: boolean
  error: Error | null
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { supabase } = useSupabase()
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    let refreshTimer: NodeJS.Timeout;

    const setupSession = async () => {
      try {
        const { data: { session }, error } = await supabase.auth.getSession()
        if (error) throw error
        setUser(session?.user ?? null)
        
        if (session) {
          const timeUntilRefresh = (session.expires_at ?? 0) - 300 - Math.floor(Date.now() / 1000)
          refreshTimer = setTimeout(() => setupSession(), Math.max(0, timeUntilRefresh * 1000))
        }
      } catch (err) {
        console.error('Error fetching session:', err)
        setError(err instanceof Error ? err : new Error('Unknown error occurred'))
        setUser(null)
      } finally {
        setLoading(false)
      }
    }

    setupSession()

    const { data: listener } = supabase.auth.onAuthStateChange(async (event, session) => {
      setUser(session?.user ?? null)
      if (event === 'SIGNED_IN') {
        navigate('/')
      } else if (event === 'SIGNED_OUT') {
        navigate('/login')
      }
      setLoading(false)
    })

    return () => {
      listener?.subscription.unsubscribe()
      clearTimeout(refreshTimer)
    }
  }, [supabase.auth, navigate])

  const value = {
    user,
    loading,
    error
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}