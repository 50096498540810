import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Typography, Grid, Paper, TextField, Button, List, ListItem, ListItemText, Divider, Accordion, AccordionSummary, AccordionDetails, LinearProgress, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSupabase } from '../contexts/supabasedb';
import { useParams, useNavigate } from 'react-router-dom';
import { useGenerateEmbedding } from '../hooks/useGenerateEmbedding';
import { useConductor } from '../hooks/useConductor';
import { ConversationMessage, ConversationTemplate, ConversationTopic, ConversationQuestion, Tool } from '../types';
import axios, { AxiosError } from 'axios';
import { v4 as uuidv4 } from 'uuid';

const DEFAULT_TEMPLATE_ID = 'f4580239-72f9-41a6-80ff-e498411e53bb';

const GuidedConversation: React.FC = () => {
  const { supabase, getLatestSession } = useSupabase();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { generateEmbedding } = useGenerateEmbedding();
  const [messages, setMessages] = useState<ConversationMessage[]>([]);
  const [template, setTemplate] = useState<ConversationTemplate | null>(null);
  const [userInput, setUserInput] = useState<string>('');
  const { 
    sharedContext, 
    updateSharedContext, 
    readMemory, 
    writeMemory, 
    logActivity, 
    updateQuestion, 
    updateTopic, 
    getTopics, 
    getQuestions, 
    conductorLogs 
  } = useConductor(id || '');

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [topics, setTopics] = useState<ConversationTopic[]>([]);
  const [questions, setQuestions] = useState<ConversationQuestion[]>([]);

  const [isTemplateLoading, setIsTemplateLoading] = useState(false);
  const [isTopicsQuestionsLoading, setIsTopicsQuestionsLoading] = useState(false);

  const [templates, setTemplates] = useState<ConversationTemplate[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>(DEFAULT_TEMPLATE_ID);

  const [currentModel, setCurrentModel] = useState<string>('claude-3-sonnet-20240229'); // Default model

  const tools: Tool[] = [
    {
      name: "update_question",
      description: "Update a question's status",
      input_schema: {
        type: "object",
        properties: {
          question_id: { type: "string", description: "The ID of the question to update" },
          completed: { type: "boolean", description: "Whether the question is completed" },
          answer: { type: "string", description: "The answer to the question" }
        },
        required: ["question_id", "completed"]
      }
    },
    {
      name: "update_topic",
      description: "Update a topic's status",
      input_schema: {
        type: "object",
        properties: {
          topic_id: { type: "string", description: "The ID of the topic to update" },
          completed: { type: "boolean", description: "Whether the topic is completed" }
        },
        required: ["topic_id", "completed"]
      }
    }
  ];

  const checkTemplateExists = async (templateId: string) => {
    console.log(`Checking existence of template with ID: ${templateId}`);
    try {
      const { data, error } = await supabase
        .from('gc_templates')
        .select('id')
        .eq('id', templateId);

      if (error) {
        console.error('Error checking template existence:', error);
        return false;
      }

      console.log('Template check result:', data);
      return data && data.length > 0;
    } catch (error) {
      console.error('Unexpected error in checkTemplateExists:', error);
      return false;
    }
  };

  const fetchAllTemplates = async () => {
    console.log('Fetching all templates');
    const { data, error } = await supabase
      .from('gc_templates')
      .select('*');

    if (error) {
      console.error('Error fetching all templates:', error);
    } else {
      console.log('All templates:', data);
      if (data && data.length > 0) {
        data.forEach(template => {
          console.log(`Template ID: ${template.id}, Title: ${template.template_title}`);
        });
      } else {
        console.log('No templates found in the database');
      }
    }
  };

  const checkDatabaseConnection = async () => {
    console.log('Checking database connection for gc_templates');
    try {
      // Check if we can fetch any templates
      const { data: templates, error: templatesError } = await supabase
        .from('gc_templates')
        .select('*')
        .limit(1);

      if (templatesError) {
        console.error('Error fetching templates:', templatesError);
      } else {
        console.log('Successfully fetched templates:', templates);
      }

      // Check if we can count the templates
      const { data: count, error: countError } = await supabase
        .from('gc_templates')
        .select('*', { count: 'exact', head: true });

      if (countError) {
        console.error('Error counting templates:', countError);
      } else {
        console.log('Total number of templates:', count);
      }

      // Try to fetch the specific template we're looking for
      const { data: specificTemplate, error: specificError } = await supabase
        .from('gc_templates')
        .select('*')
        .eq('id', DEFAULT_TEMPLATE_ID)
        .single();

      if (specificError) {
        console.error('Error fetching specific template:', specificError);
      } else {
        console.log('Specific template:', specificTemplate);
      }

    } catch (error) {
      console.error('Unexpected error in checkDatabaseConnection:', error);
    }
  };

  const createDefaultTemplate = async () => {
    const defaultTemplate = {
      id: DEFAULT_TEMPLATE_ID,
      template_title: 'Job Interview and Work History',
      template_description: 'A guided conversation template for conducting a job interview and exploring work history',
      llm_system_prompt: 'You are an AI assistant conducting a job interview. Your goal is to gather information about the candidate\'s work history, skills, and experiences. Be professional, courteous, and thorough in your questioning. Use the provided tools to update the conversation progress and store important information.',
      conductor_system_prompt: 'As the conductor, your role is to guide the conversation, ensure all key topics are covered, and extract structured information about the candidate\'s work history and skills. Use the provided tools to update topics and questions as they are addressed, and to store important information as memories.',
      llm_personality: 'Professional and attentive interviewer',
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };

    const { data, error } = await supabase
      .from('gc_templates')
      .upsert(defaultTemplate)
      .select()
      .single();

    if (error) {
      console.error('Error creating default template:', error);
      return null;
    }

    return data;
  };

  const fetchTemplate = async () => {
    if (!id || id.trim() === '') {
      console.warn('No valid conversation ID provided');
      setError('Invalid conversation ID');
      return;
    }
    setIsTemplateLoading(true);
    setError(null);

    try {
      console.log('Fetching conversation with ID:', id);
      const { data: conversationData, error: conversationError } = await supabase
        .from('guided_conversations')
        .select('template_id')
        .eq('id', id)
        .single();

      if (conversationError) {
        console.error('Error fetching conversation:', conversationError);
        throw conversationError;
      }

      console.log('Conversation data:', conversationData);

      if (conversationData && conversationData.template_id) {
        const templateId = conversationData.template_id;
        console.log('Fetching template with ID:', templateId);

        const { data: templateData, error: templateError } = await supabase
          .from('gc_templates')
          .select('*')
          .eq('id', templateId);

        if (templateError) {
          console.error('Error fetching template:', templateError);
          throw templateError;
        }

        console.log('Template query response:', templateData);

        if (templateData && templateData.length > 0) {
          console.log('Template found:', templateData[0]);
          setTemplate(templateData[0]);
          await fetchTopicsAndQuestions(templateData[0].id);
        } else {
          console.warn(`No template found with ID ${templateId}. Using default template.`);
          const defaultTemplate = await createDefaultTemplate();
          if (defaultTemplate) {
            setTemplate(defaultTemplate);
            await fetchTopicsAndQuestions(defaultTemplate.id);
          } else {
            setError('Failed to create default template. Please try again.');
          }
        }
      } else {
        console.warn('No template_id found for this conversation. Using default template.');
        const defaultTemplate = await createDefaultTemplate();
        if (defaultTemplate) {
          setTemplate(defaultTemplate);
          await fetchTopicsAndQuestions(defaultTemplate.id);
        } else {
          setError('Failed to create default template. Please try again.');
        }
      }
    } catch (error) {
      console.error('Error in fetchTemplate:', error);
      setError('Failed to fetch the conversation template. Please try again.');
    } finally {
      setIsTemplateLoading(false);
    }
  };

  const fetchTopicsAndQuestions = async (templateId: string) => {
    setIsTopicsQuestionsLoading(true);
    setError(null);

    try {
      const { data: topicsData, error: topicsError } = await supabase
        .from('gc_topics')
        .select('*')
        .eq('template_id', templateId)
        .order('created_at', { ascending: true });

      if (topicsError) {
        console.error('Error fetching topics:', topicsError);
        setTopics([]);
      } else {
        setTopics(topicsData || []);

        const allQuestions: ConversationQuestion[] = [];
        for (const topic of topicsData || []) {
          const { data: questionsData, error: questionsError } = await supabase
            .from('gc_questions')
            .select('*')
            .eq('topic_id', topic.id)
            .order('created_at', { ascending: true });

          if (questionsError) {
            console.error('Error fetching questions:', questionsError);
          } else {
            allQuestions.push(...questionsData || []);
          }
        }
        setQuestions(allQuestions);

        if (topicsData?.length === 0) {
          console.warn('No topics found for this template. You might want to add some.');
        }
        if (allQuestions.length === 0) {
          console.warn('No questions found for this template. You might want to add some.');
        }
      }
    } catch (error) {
      console.error('Error fetching topics and questions:', error);
      setError('Failed to fetch topics and questions. Please try again.');
    } finally {
      setIsTopicsQuestionsLoading(false);
    }
  };

  const fetchTemplates = async () => {
    const { data, error } = await supabase
      .from('gc_templates')
      .select('*');

    if (error) {
      console.error('Error fetching templates:', error);
    } else {
      setTemplates(data || []);
    }
  };

  const checkGuidedConversation = async () => {
    if (!id || id.trim() === '') {
      console.warn('No valid conversation ID provided for checking guided conversation');
      return;
    }

    const { data, error } = await supabase
      .from('guided_conversations')
      .select('*')
      .eq('id', id)
      .single();

    if (error) {
      console.error('Error fetching guided conversation:', error);
    } else {
      console.log('Guided conversation:', data);
    }
  };

  const createNewConversation = async (templateId: string) => {
    try {
      const session = await getLatestSession();
      if (!session?.user) {
        throw new Error('No authenticated user');
      }

      // Ensure a valid template ID is used
      if (!templateId || templateId.trim() === '') {
        console.warn('No valid template ID provided. Using default template.');
        templateId = DEFAULT_TEMPLATE_ID;
      }

      const { data, error } = await supabase
        .from('guided_conversations')
        .insert({
          title: 'New Guided Conversation',
          template_id: templateId,
          user_id: session.user.id,
        })
        .select()
        .single();

      if (error) throw error;

      if (data) {
        console.log('New guided conversation created:', data);
        setTemplate(null); // Reset the template state
        navigate(`/guided-conversation/${data.id}`);
      }
    } catch (error) {
      console.error('Error creating new conversation:', error);
      setError('Failed to create a new conversation. Please try again.');
    }
  };

  const conversationInitiatedRef = useRef(false);
  const initializationInProgressRef = useRef(false);

  const initializeComponent = useCallback(async () => {
    if (initializationInProgressRef.current || conversationInitiatedRef.current) return;
    initializationInProgressRef.current = true;

    console.log('Initializing component. Conversation initiated:', conversationInitiatedRef.current);

    try {
      await checkDatabaseConnection();
      if (id && id.trim() !== '') {
        await checkGuidedConversation();
        await fetchTemplate();
      } else {
        console.log('No valid conversation ID provided. Creating new conversation.');
        await createNewConversation(selectedTemplateId);
      }

      if (template && id && currentModel && !conversationInitiatedRef.current) {
        await initiateConversation();
        conversationInitiatedRef.current = true;
      }
    } finally {
      initializationInProgressRef.current = false;
    }
  }, [id, selectedTemplateId, template, currentModel]);

  useEffect(() => {
    initializeComponent();
  }, [initializeComponent]);

  const initiateConversation = useCallback(async () => {
    console.log('Attempting to initiate conversation. Already initiated:', conversationInitiatedRef.current);
    if (!template || !id || conversationInitiatedRef.current) {
      console.log('Conversation already initiated or missing template/id');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const systemMessage = `${template.llm_system_prompt}\n\nCurrent conversation state:\n${sharedContext}\n\nCurrent progress:\n${topics.map(topic => `Topic: ${topic.topic_title} - ${topic.completed ? 'Completed' : 'In Progress'}`).join('\n')}\n${questions.map(question => `Question: ${question.question_title} - ${question.completed ? 'Answered' : 'Unanswered'}`).join('\n')}`;

      const userPrompt = {
        role: 'user' as const,
        content: "Hello, I'm ready to start our guided conversation."
      };

      console.log('System message:', systemMessage);
      console.log('User prompt:', userPrompt);

      const session = await getLatestSession();
      const accessToken = session?.access_token;

      if (!accessToken) {
        throw new Error('No valid session found');
      }

      const requestBody = {
        action: "chat",
        model: currentModel,
        max_tokens: 1000,
        system: systemMessage,
        messages: [userPrompt],
        tools: tools,
      };

      console.log('Sending request to Anthropic API:', JSON.stringify(requestBody, null, 2));

      const response = await axios.post(`${process.env.REACT_APP_SUPABASE_URL}/functions/v1/anthropic`, requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });

      console.log('Received response from Anthropic API:', response.data);

      if (response.data && response.data.content && response.data.content.length > 0) {
        let assistantMessage = '';
        let toolCall = null;

        // Process the content array
        for (const item of response.data.content) {
          if (item.type === 'text') {
            assistantMessage += item.text;
          } else if (item.type === 'tool_use') {
            toolCall = item;
          }
        }

        if (assistantMessage.trim() === '') {
          console.error('Received empty assistant message');
          setError('Received an empty response from the assistant. Please try again.');
          return;
        }

        // Save the user prompt and LLM response to the database and state
        const savedUserPrompt = await saveMessage({
          id: uuidv4(),
          conversation_id: id,
          role: 'user',
          content: userPrompt.content,
          embedding: null,
          created_at: new Date().toISOString(),
          model_name: currentModel,
          model_provider: 'anthropic',
        });

        const savedAssistantMessage = await saveMessage({
          id: uuidv4(),
          conversation_id: id,
          role: 'assistant',
          content: assistantMessage,
          embedding: null,
          created_at: new Date().toISOString(),
          model_name: currentModel,
          model_provider: 'anthropic',
        });

        if (savedUserPrompt && savedAssistantMessage) {
          setMessages(prev => [...prev, savedUserPrompt, savedAssistantMessage]);
          console.log('Conversation initiated successfully');
          
          // Process any tool calls
          if (toolCall) {
            await processConductorActions(toolCall);
          }
        } else {
          console.error('Failed to save one or both messages');
          setError('Failed to save conversation messages. Please try again.');
        }
      } else {
        throw new Error('Unexpected response format from Anthropic API');
      }
    } catch (error) {
      console.error('Error initiating conversation:', error);
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        setError(`Failed to initiate the conversation: ${axiosError.message}. ${axiosError.response?.data || ''}`);
      } else {
        setError('Failed to initiate the conversation. Please try again.');
      }
    } finally {
      setIsLoading(false);
      conversationInitiatedRef.current = true;
    }
  }, [template, id, currentModel, sharedContext, topics, questions]);

  const saveMessage = async (message: ConversationMessage): Promise<ConversationMessage | null> => {
    try {
      if (!message.content || message.content.trim() === '') {
        console.error('Attempted to save message with null or empty content:', message);
        return null;
      }

      const messageToSave = {
        ...message,
        id: uuidv4(), // Always generate a new UUID
        content: message.content.trim(), // Ensure content is never null or empty
        created_at: new Date().toISOString(), // Ensure created_at is always set
      };

      console.log('Saving message:', messageToSave);

      const { data, error } = await supabase
        .from('gc_messages')
        .insert(messageToSave)
        .select()
        .single();

      if (error) {
        console.error('Error saving message:', error);
        return null;
      }
      console.log('Message saved successfully:', data);
      return data;
    } catch (error) {
      console.error('Unexpected error in saveMessage:', error);
      return null;
    }
  };

  const sendMessage = async () => {
    console.log('Sending message:', userInput);
    if (!userInput.trim() || !id) return;
    setIsLoading(true);
    setError(null);

    try {
      const newMessage: ConversationMessage = {
        id: uuidv4(),
        conversation_id: id,
        role: 'user',
        content: userInput,
        embedding: null,
        created_at: new Date().toISOString(),
        model_name: currentModel,
        model_provider: 'anthropic',
      };

      setMessages(prev => [...prev, newMessage]);
      setUserInput('');

      // Save user message to the database
      const { data: savedMessage, error: saveError } = await supabase
        .from('gc_messages')
        .insert(newMessage)
        .single();

      if (saveError) throw saveError;

      // Prepare the message history for the API call
      const messageHistory: { role: 'user' | 'assistant'; content: string }[] = messages
        .filter(msg => msg.role === 'user' || msg.role === 'assistant')
        .map(msg => ({
          role: msg.role as 'user' | 'assistant',
          content: msg.content,
        }));

      messageHistory.push({ role: 'user', content: userInput });

      // Prepare the system message
      let systemMessage = template?.llm_system_prompt || '';
      if (sharedContext) {
        systemMessage += `\n\nCurrent conversation state:\n${sharedContext}`;
      }
      const topicsStatus = topics.map(topic => `Topic: ${topic.topic_title} - ${topic.completed ? 'Completed' : 'In Progress'}`).join('\n');
      const questionsStatus = questions.map(question => `Question: ${question.question_title} - ${question.completed ? 'Answered' : 'Unanswered'}`).join('\n');
      systemMessage += `\n\nCurrent progress:\n${topicsStatus}\n${questionsStatus}`;

      if (template?.llm_personality) {
        systemMessage += `\n\nAdopt the following personality: ${template.llm_personality}.`;
      }

      const session = await getLatestSession();
      const accessToken = session?.access_token;

      if (!accessToken) {
        throw new Error('No valid session found');
      }

      // Call the Anthropic API via our Supabase Edge Function
      const response = await axios.post(`${process.env.REACT_APP_SUPABASE_URL}/functions/v1/anthropic`, {
        action: "chat",
        model: currentModel,
        max_tokens: 1000,
        system: systemMessage,
        messages: messageHistory,
        tools: tools,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        }
      });

      // Process the response
      const llmResponse = response.data;
      
      // Check if the response includes tool use
      if (llmResponse.tool_calls && llmResponse.tool_calls.length > 0) {
        for (const toolCall of llmResponse.tool_calls) {
          await processConductorActions(toolCall);
        }
      }

      // Extract the assistant's message content
      let assistantContent = '';
      if (llmResponse.content && Array.isArray(llmResponse.content) && llmResponse.content.length > 0) {
        for (const item of llmResponse.content) {
          if (item.type === 'text') {
            assistantContent += item.text;
          }
        }
      }

      // Check if we have a valid assistant response
      if (!assistantContent.trim()) {
        throw new Error('Received empty or invalid response from assistant');
      }

      const llmResponseMessage: ConversationMessage = {
        id: uuidv4(),
        conversation_id: id,
        role: 'assistant',
        content: assistantContent,
        embedding: null,
        created_at: new Date().toISOString(),
        model_name: currentModel,
        model_provider: 'anthropic',
      };

      // Save LLM response to the database
      const { data: savedLLMResponse, error: llmSaveError } = await supabase
        .from('gc_messages')
        .insert(llmResponseMessage)
        .single();

      if (llmSaveError) throw llmSaveError;

      setMessages(prev => [...prev, llmResponseMessage]);

    } catch (error) {
      console.error('Error in sendMessage:', error);
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        setError(`Error sending message: ${axiosError.message}. ${axiosError.response?.data || ''}`);
      } else {
        setError('An unexpected error occurred while sending the message.');
      }
    } finally {
      setIsLoading(false);
      setUserInput('');
    }
  };

  const processConductorActions = async (toolCall: any) => {
    if (!toolCall || !toolCall.name) {
      console.error('Invalid tool call:', toolCall);
      return;
    }

    await logActivity(`Processing tool call: ${toolCall.name}`);

    switch (toolCall.name) {
      case 'update_question':
        if (toolCall.input && toolCall.input.question_id) {
          const questionId = toolCall.input.question_id;
          // Check if the question_id is a valid UUID
          if (!/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(questionId)) {
            console.error('Invalid question_id:', questionId);
            return;
          }
          await updateQuestion(questionId, toolCall.input.answer || '', []);
          updateSharedContext(prevContext => 
            `${prevContext}\nQuestion Updated: ${questionId}\nAnswer: ${toolCall.input.answer || ''}`
          );
          // Update local state
          setQuestions(prevQuestions => 
            prevQuestions.map(q => 
              q.id === questionId 
                ? { ...q, completed: true, answer: toolCall.input.answer || '' } 
                : q
            )
          );
        }
        break;
      case 'update_topic':
        if (toolCall.input && toolCall.input.topic_id) {
          const topicIdOrTitle = toolCall.input.topic_id;
          await updateTopic(topicIdOrTitle, { completed: !!toolCall.input.completed });
          updateSharedContext(prevContext => 
            `${prevContext}\nTopic Updated: ${topicIdOrTitle}\nCompleted: ${!!toolCall.input.completed}`
          );
          // Update local state
          setTopics(prevTopics => 
            prevTopics.map(t => 
              (t.id === topicIdOrTitle || t.topic_title.toLowerCase() === topicIdOrTitle.toLowerCase())
                ? { ...t, completed: !!toolCall.input.completed } 
                : t
            )
          );
        }
        break;
      default:
        console.warn(`Unknown tool call: ${toolCall.name}`);
    }

    // After processing any tool call, update the shared context with the current state of topics and questions
    const topicsStatus = topics.map(topic => 
      `Topic: ${topic.topic_title} - ${topic.completed ? 'Completed' : 'In Progress'}`
    ).join('\n');
    const questionsStatus = questions.map(question => 
      `Question: ${question.question_title} - ${question.completed ? 'Answered' : 'Unanswered'}${question.answer ? `\nAnswer: ${question.answer}` : ''}`
    ).join('\n');
    updateSharedContext(prevContext => `${prevContext}\n\nCurrent Progress:\n${topicsStatus}\n${questionsStatus}`);

    // Check if all topics and questions are completed
    const allTopicsCompleted = topics.every(t => t.completed);
    const allQuestionsCompleted = questions.every(q => q.completed);

    if (allTopicsCompleted && allQuestionsCompleted) {
      await logActivity("All topics and questions have been completed.");
      updateSharedContext(prevContext => `${prevContext}\n\nAll topics and questions have been completed.`);
      await processInterviewMemories();
    }

    // After processing any tool call, update the topics and questions
    await fetchTopicsAndQuestions(template?.id || '');
  };

  const processInterviewMemories = async () => {
    try {
      // Iterate through completed questions and topics
      for (const question of questions.filter(q => q.completed)) {
        if (question.answer) {
          await writeMemory(`Question: ${question.question_title}`, question.answer);
        }
      }

      for (const topic of topics.filter(t => t.completed)) {
        await writeMemory(`Topic: ${topic.topic_title}`, `Completed: ${topic.completed}`);
      }

      // You might want to write a summary of the entire interview
      const interviewSummary = `Interview completed. Topics covered: ${topics.filter(t => t.completed).map(t => t.topic_title).join(', ')}`;
      await writeMemory('Interview Summary', interviewSummary);

      console.log('Interview memories processed successfully');
    } catch (error) {
      console.error('Error processing interview memories:', error);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>Guided Conversation</Typography>
      {isTemplateLoading && <LinearProgress />}
      {isTopicsQuestionsLoading && <LinearProgress />}
      {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
      <Grid container spacing={3}>
        {/* Chat Interface */}
        <Grid item xs={6}>
          <Paper elevation={3} sx={{ p: 2, height: '80vh', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom>Chat</Typography>
            <List sx={{ flexGrow: 1, overflowY: 'auto', mb: 2 }}>
              {messages.map((message, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`${message.role}: ${message.content}`}
                    secondary={message.created_at ? new Date(message.created_at).toLocaleString() : 'Date unknown'}
                  />
                </ListItem>
              ))}
            </List>
            <TextField
              label="Your message"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              fullWidth
              disabled={isLoading}
              sx={{ mb: 2 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={sendMessage}
              disabled={isLoading}
            >
              Send
            </Button>
          </Paper>
        </Grid>

        {/* Conductor UI */}
        <Grid item xs={6}>
          <Paper elevation={3} sx={{ p: 2, height: '80vh', overflowY: 'auto' }}>
            <Typography variant="h6" gutterBottom>Conductor</Typography>
            
            {/* Shared Context */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Shared Context</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
                  {sharedContext}
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* Topics and Questions Progress */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Topics and Questions Progress</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {topics.map((topic) => (
                    <ListItem key={topic.id}>
                      <ListItemText
                        primary={`${topic.completed ? '✓' : '○'} ${topic.topic_title}`}
                        secondary={
                          <List>
                            {questions
                              .filter(q => q.topic_id === topic.id)
                              .map((question) => (
                                <ListItem key={question.id}>
                                  <ListItemText
                                    primary={`${question.completed ? '✓' : '○'} ${question.question_title}`}
                                    secondary={question.answer ? `Answer: ${question.answer}` : 'Not answered yet'}
                                  />
                                </ListItem>
                              ))}
                          </List>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>

            {/* Conductor Activities */}
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>Conductor Activities</Typography>
            <List>
              {conductorLogs.map((log) => (
                <ListItem key={log.id}>
                  <ListItemText
                    primary={log.content}
                    secondary={log.created_at ? new Date(log.created_at).toLocaleString() : 'Date unknown'}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GuidedConversation;