import React, { useState, useEffect } from 'react'
import { Box, Card, CardContent, Typography, Button, Grid, Switch, FormControlLabel } from '@mui/material'

// Mock function to simulate fetching cron jobs
const fetchCronJobs = async () => {
  // This should be replaced with actual API call
  return [
    { id: '1', name: 'Daily Data Backup', schedule: '0 2 * * *', enabled: true },
    { id: '2', name: 'Weekly Report Generation', schedule: '0 10 * * 1', enabled: false },
  ]
}

interface CronJob {
  id: string
  name: string
  schedule: string
  enabled: boolean
}

const CronJobs = () => {
  const [cronJobs, setCronJobs] = useState<CronJob[]>([])

  useEffect(() => {
    const loadCronJobs = async () => {
      const jobs = await fetchCronJobs()
      setCronJobs(jobs)
    }
    loadCronJobs()
  }, [])

  const toggleCronJobStatus = (id: string) => {
    setCronJobs(cronJobs.map(job => job.id === id ? { ...job, enabled: !job.enabled } : job))
    // Here you would also call an API to update the cron job status
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Cron Jobs Scheduler
      </Typography>
      <Grid container spacing={3}>
        {cronJobs.map((job) => (
          <Grid item xs={12} sm={6} md={4} key={job.id}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  {job.name}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Schedule: {job.schedule}
                </Typography>
                <FormControlLabel
                  control={<Switch checked={job.enabled} onChange={() => toggleCronJobStatus(job.id)} />}
                  label={job.enabled ? 'Enabled' : 'Disabled'}
                />
                <Box sx={{ mt: 2 }}>
                  <Button size="small">View Details</Button>
                  <Button size="small">Edit</Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default CronJobs
