import { useState } from 'react';
import { useApi } from '../contexts/ApiContext';

interface EmbeddingResponse {
  data: Array<{
    embedding: number[] | string;
    index: number;
  }>;
  model: string;
  usage: {
    prompt_tokens: number;
    total_tokens: number;
  };
}

export const useVoyageAI = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const api = useApi();

  const generateEmbedding = async (input: string | string[], model?: string): Promise<EmbeddingResponse | null> => {
    setLoading(true);
    setError(null);

    try {
      const response = await api.post<EmbeddingResponse>('/api/embedding/generate', {
        input,
        model,
      });

      setLoading(false);
      return response;
    } catch (err) {
      setLoading(false);
      setError('Failed to generate embedding');
      console.error('Error generating embedding:', err);
      return null;
    }
  };

  return { generateEmbedding, loading, error };
};